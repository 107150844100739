import { extend } from 'lodash';

const querystring = function (query) {
    return querystring.decode(query);
};

querystring.decode = function (q) {
    q = q || window.location.search;
    if (q === '' || typeof q === 'undefined') {
        return {};
    }
    return (/^[?#]/.test(q) ? q.slice(1) : q).split('&').reduce((params, param) => {
        let [key, value] = param.split('=');
        params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
        return params;
    }, {});
};

querystring.encode = function (o, Qmark = false) {
    let a = [];
    for (let k in o) {
        let v = o[k].constructor === Array ? o[k].join(',') : o[k];
        a.push(`${encodeURIComponent(k)}${v ? '=' + encodeURIComponent(v) : ''}`);
    }
    return `${Qmark ? '?' : ''}${a.join('&')}`;
};

querystring.extend = function (o, Qmark = false) {
    const base = querystring.decode();
    const extended = extend(base || {}, o);
    return querystring.encode(extended, Qmark);
};

export { querystring };
