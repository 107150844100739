/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    pin: {
        width: 16,
        height: 16,
        viewBox: '0 0 24.08 24.08',
        data:
            '<path pid="0" class="cls-1" d="M12 24.08a12 12 0 1 1 12-12 12.05 12.05 0 0 1-12 12zm0-22.81A10.78 10.78 0 1 0 22.82 12 10.78 10.78 0 0 0 12 1.27z" _fill="currentColor"/><path pid="1" class="cls-1" d="M11.72 12.22A2.32 2.32 0 1 1 14 9.9a2.32 2.32 0 0 1-2.28 2.32zm0-3.36a1 1 0 1 0 1 1 1 1 0 0 0-1-1z" _fill="currentColor"/><path pid="2" class="cls-1" d="M11.72 19.54c-1.47 0-5-7.34-5-9.72a5.15 5.15 0 0 1 5-5.31 5.15 5.15 0 0 1 5 5.31c-.03 2.38-3.52 9.72-5 9.72zm0-13.81A3.94 3.94 0 0 0 8 9.82c0 2.2 2.8 7.36 3.74 8.39.95-1 3.75-6.19 3.75-8.39a3.94 3.94 0 0 0-3.77-4.09z" _fill="currentColor"/>',
    },
});
