/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    pencil: {
        width: 16,
        height: 16,
        viewBox: '0 0 59.53 86.87',
        data:
            '<path pid="0" d="M20.83 81.05l-8.38 5.83h35.17v-5.83H20.83zM8.59 53.91l15.48 8.94-17.24 12zM10.05 48L33.19 8l18.39 10.57-23.14 40.08zm21-48L3.23 48.2 0 86.66l31.7-22 27.83-48.2z"/>',
    },
});
