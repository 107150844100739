<template>
    <span></span>
</template>
<script>
import _ from 'lodash';
import { mapGetters, mapState } from 'vuex';

export default {
    computed: {
        ...mapGetters(['agent', 'office', 'phone', 'articles']),
        ...mapState(['meta']),
    },
    methods: {
        agentImage() {
            return this.agent.image.profile;
        },
        ogImage() {
            const ogImage = this.agent.og ? this.agent.og.image : null;
            return ogImage ? ogImage : this.agentImage();
        },
        ogURL() {
            return this.agent.og?.url ? this.agent.og.url : this.meta.baseuri;
        },
        pageTitle() {
            if (this.meta.page_title && this.meta.page_title !== '') {
                return this.meta.page_title;
            }
            return `${this.agent.names.PreferredName}, Desjardins Insurance Agent in ${this.office.physicalAddress.municipality}, ${this.office.physicalAddress.provinceCode} | Auto, Home, Life Insurance & more`;
        },
        getAddy() {
            return this.office.physicalAddress;
        },

        ld() {
            const addy = this.getAddy();
            return JSON.stringify({
                '@context': 'http://schema.org/',
                '@type': 'InsuranceAgency',
                name: this.pageTitle(),
                description: this.meta.description,
                // "openingHours" : "Mo,Tu,We,Th,Fr 09:00-17:00",
                address: {
                    '@type': 'PostalAddress',
                    addressLocality: addy.municipality,
                    addressRegion: addy.provinceCode,
                    postalCode: addy.postalCode,
                    streetaddress: [addy.street1, addy.street2].join(' '),
                },
                url: this.meta.baseuri,
                telephone: `+1${this.phone.pretty}`,
                logo: 'https://cdn.mydd.io/c1/app/dist/master/734e895/img/insurance-logo-small.png',
                image: this.agentImage(),
                hasMap: this.office.maps.url,
                geo: {
                    '@type': 'GeoCoordinates',
                    latitude: addy.latitude,
                    longitude: addy.longitude,
                },
                corporate: {
                    areaServed: 'CA',
                    availableLanguage: ['english', 'french'],
                },
                socialProfile: {
                    '@type': 'Person',
                    name: this.pageTitle(),
                    url: this.meta.baseuri,
                    sameAs: _.map(this.office.social, (v) => v.url),
                },
                jobListing: {
                    '@type': 'JobPosting',
                    jobs: this.office.jobs,
                },
                article: {
                    '@type': 'NewsArticle',
                    articles: this.articles,
                },
            });
        },
    },
    metaInfo() {
        const addy = this.getAddy();
        const publicPath = this.$store.state.publicPath || '/';
        return {
            title: this.pageTitle(),

            meta: [
                { charset: 'utf-8' },
                {
                    name: 'keywords',
                    content:
                        'auto insurance, car insurance, insurance quote, online insurance quote, home owners insurance, renters insurance, home insurance, life insurance, health insurance',
                },
                { name: 'description', content: this.meta.description },
                { name: 'viewport', content: 'width=device-width, initial-scale=1, minimal-ui' },
                { name: 'format-detection', content: 'telephone=no' },
                { name: 'referrer', content: 'origin' },
                { name: 'version', content: this.meta.version },
                { name: 'branch', content: this.meta.branch },
                { name: 'peachy', content: this.meta.peachy },

                { property: 'og:title', content: this.pageTitle() },
                { property: 'og:type', content: 'website' },
                { property: 'og:url', content: this.ogURL() },
                { property: 'og:site_name', content: this.pageTitle() },
                { property: 'og:description', content: this.meta.description },
                { property: 'og:image', content: this.ogImage() },

                { name: 'geo.position', content: `${addy.latitude};${addy.longitude}` },
                { name: 'geo.placename', content: `${addy.municipality},${addy.provinceName}` },
                { name: 'geo.region', content: `CA-${addy.provinceCode}` },

                { name: 'twitter:card', content: 'summary_large_image' },
                { name: 'twitter:title', content: this.pageTitle() },
                { name: 'twitter:description', content: this.meta.description },
                { name: 'twitter:image', content: this.ogImage() },
                { name: 'twitter:domain', content: this.meta.baseuri },
                { name: 'twitter:site', content: '@agenthandle' },

                { name: 'mobile-web-app-capable', content: 'yes' },
                { name: 'theme-color', content: '#4DBA87' },
                { name: 'apple-mobile-web-app-capable', content: 'no' },
                { name: 'apple-mobile-web-app-status-bar-style', content: 'default' },
                { name: 'apple-mobile-web-app-title', content: 'c1-ui' },
                {
                    name: 'msapplication-TileImage',
                    content: `${publicPath}img/icons/msapplication-icon-144x144.png`,
                },
                { name: 'msapplication-TileColor', content: '#000000' },
            ],
            link: [
                // { itemprop:'url', rel: 'canonical', href : _.get(this.ctx,'meta.baseuri'), },
                {
                    rel: 'mask-icon',
                    href: `${publicPath}img/icons/safari-pinned-tab.svg`,
                    color: '#4DBA87',
                },
                {
                    rel: 'apple-touch-icon',
                    type: 'image/png',
                    href: `${publicPath}img/icons/apple-touch-icon.png`,
                },
                {
                    rel: 'shortcut icon',
                    type: 'image/png',
                    sizes: '32x32',
                    href: `${publicPath}img/icons/favicon-32x32.png`,
                },
                {
                    rel: 'shortcut icon',
                    type: 'image/png',
                    sizes: '16x16',
                    href: `${publicPath}img/icons/favicon-16x16.png`,
                },
            ],

            __dangerouslyDisableSanitizers: ['script'],
            script: [{ innerHTML: this.ld(), type: 'application/ld+json' }],
        };
    },
};
</script>
