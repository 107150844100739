<template>
    <div class="moretext">
        <p
            v-if="expanded"
            ref="expanded-text"
            class="alltext"
            role="region"
            tabindex="-1"
            v-html="formatMore()"
        />
        <span v-else role="region" tabindex="-1" v-html="unformatLess()" />
        <button
            v-if="parts().more && parts().less"
            class="actions link link--inline"
            @click="expandHandler"
        >
            <span ref="show-more" role="button" :aria-expanded="expanded ? 'true' : 'false'">
                {{ expanded ? 'Show Less' : '...Read More' }}
            </span>
        </button>
    </div>
</template>

<script>
export default {
    name: 'MoreText',
    props: {
        text: { type: String, default: '' },
        threshold: { type: Number, default: null },
    },
    data() {
        return {
            expanded: false,
        };
    },

    methods: {
        parts() {
            return this.$options.filters.textbreak(this.text, this.threshold || 150);
        },
        unformatLess() {
            return this.parts().less ? this.parts().less.replace(/^\s*\*|/gim, '') : '';
        },
        formatMore() {
            return this.$options.filters.paraFormat(this.parts().more);
        },
        expandHandler() {
            this.expanded = !this.expanded;
            setTimeout(() => {
                this.$refs[this.expanded ? 'expanded-text' : 'show-more'].focus();
            }, 500);
        },
    },
};
</script>

<style lang="scss">
.moretext {
    ul {
        margin-left: 30px;
        margin-bottom: 20px;
    }
    p {
        line-height: 1.32;
        font-weight: normal;
        @include media('<md') {
            font-size: 14px;
            line-height: 1.15;
        }
        a {
            font-weight: 900;
            @include media('<md') {
                font-size: 13px;
            }
        }
    }
    .alltext {
        p {
            margin-bottom: 15px !important;
        }
    }
    .actions {
        // text-align: right;
        white-space: nowrap;
        color: $dark-green;
        margin-left: 5px;
        a,
        a:visited,
        a:hover {
            color: $black;
        }
    }
}
</style>
