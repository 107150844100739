<script>
import { mapGetters } from 'vuex';

import { extend } from 'vee-validate';
import axios from 'axios';
import { oneOf, min } from 'vee-validate/dist/rules';

const phoneRegex = /^\(?([2-9][0-8][0-9])\)?[-. ]?([2-9][0-9]{2})[-. ]?([0-9]{4})$/;
const emailRegex = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
export default {
    data() {
        return {
            contactForm: {
                name: null,
                phone: null,
                email: null,
                pref: 'email',
                message: null,
                more: [],
            },
            submit_attempted: false,

            sending: false,
            send_disabled: false,
            send_response: null,
            response_error: false,
        };
    },
    computed: {
        ...mapGetters(['office', 'agent', 'meta', 'phone', 'c1Url']),
    },
    mounted() {
        extend('phone', {
            validate(value) {
                return phoneRegex.test(value);
            },
            message: `Your <strong>Phone Number</strong> isn't filled in correctly.`,
        });
        extend('email', {
            validate(value) {
                return emailRegex.test(value);
            },
            message: `Your <strong>Email Address</strong> isn't filled in correctly.`,
        });
        extend('contact', {
            validate(value) {
                return emailRegex.test(value) || phoneRegex.test(value);
            },
            message: `You must provide a valid phone or email address`,
        });
        extend('oneOf', oneOf);
        extend('min', min);
        // fake dev-only data
        if (process.env.NODE_ENV === 'development') {
            this.contactForm = {
                name: 'Justin Testing',
                phone: '3093101463',
                email: 'blah@blah',
                pref: 'email',
                message: "I'm a fake test message, you should ignore me!",
                more: ['Home & Property Insurance', 'Auto Insurance'],
            };
        }
    },
    methods: {
        reset() {
            this.contactForm = {
                name: null,
                phone: null,
                email: null,
                pref: 'email',
                message: null,
                more: [],
            };
            this.$refs.contactMe?.reset();
        },
        responseMessage(msg, reset) {
            this.send_response = msg;
            this.sending = false;

            if (reset) {
                this.submit_attempted = false;

                setTimeout(() => {
                    this.reset();
                }, 6000);
            }
        },
        async submitContactForm() {
            this.submit_attempted = true;
            this.response_error = false;

            const data = { ...this.contactForm };

            this.sending = true;
            this.send_response = 'Sending... please wait...';
            this.$keen('contactform', data);

            try {
                const response = await axios.post('/contact', { ...data });

                if (response.data.success) {
                    this.responseMessage('Thank you! Your message was sent successfully.', true);
                } else {
                    this.response_error = true;
                    this.responseMessage(
                        "We're sorry, there was an error sending your message.  Please try again at a later time.",
                    );
                }
            } catch (err) {
                console.error(err);
                this.response_error = true;
                this.responseMessage(
                    "We're sorry, there was an error sending your message.  Please try again at a later time.",
                );
            }
        },
    },
};
</script>
