/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'upload-cloud': {
        width: 16,
        height: 16,
        viewBox: '0 -256 1950 1950',
        data:
            '<path pid="0" d="M1302.78 756.237q0-14-9-23l-352-352q-9-9-23-9t-23 9l-351 351q-10 12-10 24 0 14 9 23t23 9h224v352q0 13 9.5 22.5t22.5 9.5h192q13 0 22.5-9.5t9.5-22.5v-352h224q13 0 22.5-9.5t9.5-22.5zm640 288q0 159-112.5 271.5t-271.5 112.5h-1088q-185 0-316.5-131.5t-131.5-316.5q0-130 70-240t188-165q-2-30-2-43 0-212 150-362t362-150q156 0 285.5 87t188.5 231q71-62 166-62 106 0 181 75t75 181q0 76-41 138 130 31 213.5 135.5t83.5 238.5z" _fill="#0a70bc" xmlns="http://www.w3.org/2000/svg"/>',
    },
});
