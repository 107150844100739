const EPHEMERA_HOST = 'https://ephemera.mirus.io';

function getResized(bucket_url, width) {
    return `${EPHEMERA_HOST}/imgr/${width}x0/${bucket_url}`;
}

function getCached(bucket_url) {
    const uri = new URL(bucket_url);
    return `${EPHEMERA_HOST}/cache/${uri.hostname}${uri.pathname}`;
}

export { getResized, getCached };
