/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    lock: {
        width: 16,
        height: 16,
        viewBox: '0 0 11.96 14.14',
        data:
            '<path pid="0" d="M10.25 4.83V4a4 4 0 0 0-8.07 0v.78H2a2 2 0 0 0-2 2v5.42a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V6.76a2 2 0 0 0-1.75-1.93zM3.54 4a2.68 2.68 0 0 1 5.35 0v.78H3.54zm7.06 8.15a.6.6 0 0 1-.6.6H2a.6.6 0 0 1-.6-.6V6.76a.6.6 0 0 1 .6-.6h8a.6.6 0 0 1 .6.6z" _fill="currentColor"/>',
    },
});
