import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

const SentryPlugin = {
    install: function (Vue, options) {
        if (typeof window === 'undefined') {
            return;
        }

        const params = Object.assign(
            {
                dsn: undefined, // required
                debug: process.env.NODE_ENV !== 'production',
                release: process.env.VUE_APP_RELEASE,
                environment: process.env.NODE_ENV,
                integrations: [new Integrations.Vue({ Vue, attachProps: true })],
                beforeSend: (event, hint) => {
                    const error = hint.originalException;
                    if (error && error.message && error.message.match(/Unexpected token '<'/i)) {
                        const cookieManagementId = document.querySelector(
                            'meta[name="cookie_management_id"]',
                        );
                        if (cookieManagementId) {
                            return event;
                        }
                    }
                    return null;
                },
            },
            options || {},
        );
        Sentry.init(params);
        Vue.prototype.$sentry = Sentry;
    },
};

if (process.env.NODE_ENV !== 'development') {
    Vue.use(SentryPlugin, {
        dsn: 'https://04531af1e3174d1c8423d90bbed189ff@sentry.io/1308404',
    });
}

import 'vue-svgicon/dist/polyfill';
import VueSVGIcon from 'vue-svgicon';
Vue.use(VueSVGIcon);

import Meta from 'vue-meta';
Vue.use(Meta, {
    keyName: 'metaInfo', // the component option name that vue-meta looks for meta info on.
    attribute: 'data-vmeta', // the attribute name vue-meta adds to the tags it observes
    ssrAttribute: 'data-vue-meta-server-rendered', // the attribute name that lets vue-meta know that meta info has already been server-rendered
    tagIDKeyName: 'vmid', // the property name that vue-meta uses to determine whether to overwrite or append a tag
});

import EditorDetection from '@/components/Editor/EditorDetection.js';
Vue.use(EditorDetection);

import VTooltip from 'v-tooltip';
Vue.use(VTooltip);

const FacebookSDKPlugin = {
    install: function (Vue, options = {}) {
        if (typeof window === 'undefined') {
            return; // ssr escape
        }
        const defaultOptions = { xfbml: 1, version: '3.2' };
        options = Object.assign({}, defaultOptions, options || {});

        function FBinit(d, s, id) {
            if (d.getElementById(id)) {
                return;
            }
            const firstjs = d.getElementsByTagName(s)[0];
            if (!firstjs) {
                return;
            }

            const facebookjs = d.createElement(s);
            facebookjs.id = id;
            facebookjs.addEventListener('load', () => {
                setTimeout(() => {
                    // eslint-disable-next-line
                    if (!FB) {
                        return;
                    }
                    // eslint-disable-next-line
                    Vue.FB = FB;
                    window.dispatchEvent(new CustomEvent('fb-sdk-ready'));
                }, 200);
                // Dispatch the event
            });
            facebookjs.addEventListener('error', function () {
                window.dispatchEvent(new CustomEvent('fb-sdk-failed'));
            });

            const { xfbml, version } = options;
            facebookjs.src = `//connect.facebook.net/en_GB/sdk.js#xfbml=${xfbml}&version=v${version}`;
            facebookjs.async = !0;

            firstjs.parentNode.insertBefore(facebookjs, firstjs);
        }
        Vue.FBinit = function () {
            FBinit(document, 'script', 'facebook-jssdk');
        };
        Vue.FB = undefined;
    },
};

Vue.use(FacebookSDKPlugin, { xfbml: 1, version: '14.0' });
