<template>
    <div>
        <PageHeader />
        <Banner />
        <div class="error-page">
            <h1>Unauthorized!</h1>
            <p>We're having trouble verifying your identity.</p>

            <footer>
                Please contact
                <a href="https://www.mirusresearch.com">https://www.mirusresearch.com</a>
                for further assistance.
            </footer>
        </div>
    </div>
</template>
<script>
import PageHeader from '@/components/Layout/PageHeader.vue';
import Banner from '@/components/Layout/Banner.vue';
export default {
    components: {
        PageHeader,
        Banner,
    },
};
</script>
<style lang="scss" scoped>
.error-page {
    padding-bottom: 50px;
    text-align: center;
    h1 {
        font-size: 3rem;
    }
}
</style>
