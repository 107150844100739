/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    condo: {
        width: 16,
        height: 16,
        viewBox: '0 0 63 68',
        data:
            '<path pid="0" d="M61.964 14.327h-2.613v-5.2h2.613v5.2zM59.1 39.674H32.679v-8.856H59.1v8.856zm-.099 12.23H46.985v-8.902h12.016v8.901zm-14.962 0h-11.36v-8.902h11.36v8.901zM32.516 3.041h9.885V13.07h-9.885V3.041zm-2.957 48.861H18.553v-8.901H29.56v8.901zm-14.067 0H3.655v-8.901h11.837v8.901zM.892 9.127h2.525v5.2H.892v-5.2zm5.947 5.2H4.313v-5.2H6.84v5.2zm3.429 0h-2.52v-5.2h2.52v5.2zm3.432 0h-2.536v-5.2H13.7v5.2zm3.859 0H14.59v-5.2h2.968v5.2zM3.652 18.495h25.803v9.05H3.652v-9.05zm0 12.323h25.803v8.96H3.652v-8.96zM20.276 3.042h9.24V13.07h-9.24V3.041zm24.781 6.085h2.766v5.2h-2.766v-5.2zm6.304 5.2h-2.61v-5.2h2.61v5.2zm3.536 0h-2.613v-5.2h2.613v5.2zm3.963 13.01H32.679v-8.842H58.86v8.843zm-.421-13.01H55.82v-5.2h2.619v5.2zm3.525-6.272H45.057V.002H17.56v8.053H0v59.333h24.011v-10.36h13.76v10.36h25.125V8.055h-.933z" _fill="#fff" fill-rule="nonzero"/>',
    },
});
