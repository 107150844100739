/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    facebook: {
        width: 16,
        height: 16,
        viewBox: '0 0 26.71 26.71',
        data:
            '<path pid="0" d="M26.71 13.35A13.36 13.36 0 1 1 13.36 0a13.36 13.36 0 0 1 13.35 13.35z" _fill="#3b5998"/><path pid="1" d="M16.71 13.88h-2.38v8.73h-3.61v-8.73H9v-3.07h1.72v-2a3.38 3.38 0 0 1 3.64-3.64H17v3h-1.9a.74.74 0 0 0-.77.84v1.8H17z" _fill="#fff"/>',
    },
});
