/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'figures-magnifier': {
        width: 16,
        height: 16,
        viewBox: '0 0 37.89 37.78',
        data:
            '<path pid="0" d="M37.66 32.8l-7.58-7.58h5a.78.78 0 0 0 .78-.78V21A3.75 3.75 0 0 0 34 17.71a2.47 2.47 0 0 0 .43-1.4v-2.2a2.47 2.47 0 0 0-2.46-2.47h-.86a2.7 2.7 0 0 0-1.55.5 12.84 12.84 0 0 0-23.16-.07 2.43 2.43 0 0 0-1.4-.43h-.88a2.68 2.68 0 0 0-2.67 2.67v1.8A2.65 2.65 0 0 0 2 17.67 3.73 3.73 0 0 0 0 21v3.49a.78.78 0 0 0 .78.78h6.76a12.85 12.85 0 0 0 16.68 3.63l8.69 8.7a.8.8 0 0 0 .55.23.78.78 0 0 0 .55-.23l3.65-3.65a.78.78 0 0 0 0-1.1zm-6.6-19.6h.86a.9.9 0 0 1 .9.91v2.2a.9.9 0 0 1-.9.91h-.86a1.06 1.06 0 0 1-.31 0 12.52 12.52 0 0 0-.62-3.48 1.13 1.13 0 0 1 .93-.5zm-.35 5.58h1.39A2.17 2.17 0 0 1 34.27 21v2.71h-5a12.82 12.82 0 0 0 1.44-4.88zM3 14.31a1.11 1.11 0 0 1 1.12-1.11H5a.92.92 0 0 1 .76.4 12.8 12.8 0 0 0-.65 3.62h-1A1.11 1.11 0 0 1 3 16.11zm-1.44 9.35V21a2.17 2.17 0 0 1 2.17-2.17h1.39a12.8 12.8 0 0 0 1.44 4.88zm31.9 12.24l-8.57-8.58a.77.77 0 0 0-.55-.22.74.74 0 0 0-.41.11 11.26 11.26 0 1 1 3.44-3.38.77.77 0 0 0 .1 1L36 33.35z" _fill="currentColor"/><path pid="1" d="M17.91 8.32a9.34 9.34 0 1 0 9.35 9.34 9.35 9.35 0 0 0-9.35-9.34zm0 17.12a7.78 7.78 0 0 1-5.91-2.7c.18-1.48 1.52-2.26 3-2.46l2.22 3.49a.78.78 0 0 0 1.31 0l2.23-3.49c1.49.2 2.83 1 3 2.46a7.79 7.79 0 0 1-5.9 2.7zm.26-6.77h-.51a1.89 1.89 0 0 1-1.81-2.12v-1.36a1.89 1.89 0 0 1 1.81-2.12h.51A1.89 1.89 0 0 1 20 15.19v1.36a1.89 1.89 0 0 1-1.81 2.12zm.83 1.56l-1.07 1.68-1.06-1.68zm2-1.5a4.06 4.06 0 0 0 .6-2.18v-1.36a3.42 3.42 0 0 0-3.37-3.68h-.51a3.41 3.41 0 0 0-3.36 3.68v1.36a4.06 4.06 0 0 0 .59 2.18 5 5 0 0 0-4 2.38 7.69 7.69 0 0 1-.81-3.45 7.79 7.79 0 1 1 15.57 0 7.69 7.69 0 0 1-.81 3.45 5 5 0 0 0-3.95-2.38zM17.91 3a.78.78 0 0 0 .78-.78V.78a.78.78 0 0 0-.78-.78.77.77 0 0 0-.77.78v1.48a.77.77 0 0 0 .77.78zm6.97 1.9a.76.76 0 0 0 .38.11.78.78 0 0 0 .68-.39l.74-1.29a.78.78 0 1 0-1.35-.77l-.74 1.28a.77.77 0 0 0 .29 1.06zM9.89 4.62a.79.79 0 0 0 .68.39.82.82 0 0 0 .43-.11.78.78 0 0 0 .28-1.06l-.78-1.28a.78.78 0 0 0-1.35.77z" _fill="currentColor"/>',
    },
});
