import Vue from 'vue';
import Router from 'vue-router';
import axios from 'axios';
Vue.use(Router);

import Home from '@/views/Home.vue';
import Unauthorized from '@/views/Unauthorized.vue';
import Quote from '@/views/Quote.vue';

export function createRouter(store) {
    let router = new Router({
        mode: 'history',
        routes: [
            {
                path: '/',
                name: 'home',
                component: Home,
            },
            {
                path: '/unauthorized',
                name: 'unauthorized',
                component: Unauthorized,
            },
            { path: '/quote/homeowners', redirect: '/quote/home' }, // this needs to be before the generic route
            {
                path: '/quote/:product?',
                name: 'quote',
                component: Quote,
            },
            { path: '/auto', redirect: '/quote/auto' },
            { path: '/home', redirect: '/quote/home' },
            { path: '/homeowners', redirect: '/quote/home' },
            { path: '/condo', redirect: '/quote/condo' },
            { path: '/tenants', redirect: '/quote/tenants' },
            { path: '/renters', redirect: '/quote/tenants' },
        ],
    });

    router.beforeEach((to, from, next) => {
        if (Object.keys(to.query).includes('backend')) {
            store.commit('BACKEND_OVERRIDE', to.query.backend);
        }

        if (Object.keys(to.query).includes('editing')) {
            checkAuth(store);
        }
        next();
    });

    return router;
}

function inBrowser() {
    return typeof window !== 'undefined';
}

function redirectToUnauthorizedPage() {
    if (inBrowser()) {
        window.location = '/unauthorized' + window.location.search.replace('editing', '');
    }
}

function redirectToLoginPage(host) {
    if (inBrowser()) {
        window.location.href = `${host}/redirect?destination=${encodeURIComponent(
            window.location,
        )}`;
    }
}

async function checkAuth(store) {
    try {
        var allowedDomainsResponse = await axios.get(
            `${store.getters.apiUrl}/users/authorized_c1_list/`,
            {
                xsrfHeaderName: 'X-CSRFToken',
                xsrfCookieName: 'csrftoken',
                withCredentials: true,
            },
        );
        const allowedDomains = allowedDomainsResponse.data;
        if (!allowedDomains.includes(store.getters.meta.domain)) {
            return redirectToUnauthorizedPage();
        }
    } catch (error) {
        if (error.response.status === 401) {
            console.error('Not logged in to backend, logging in...');
            store.commit('LOGOUT');
            return redirectToLoginPage(store.getters.apiHost);
        }
    }
    let user = null;
    try {
        const activeUserResponse = await axios.get(`${store.getters.apiUrl}/users/active/`, {
            xsrfHeaderName: 'X-CSRFToken',
            xsrfCookieName: 'csrftoken',
            withCredentials: true,
        });
        if (activeUserResponse.status === 200) {
            user = activeUserResponse.data.user;
        }
    } catch (error) {
        console.error(`Active User request returned an error: ${error}`);
    }
    if (user === null) {
        console.error('No active user details!');
        return redirectToUnauthorizedPage();
    }
    store.commit('LOGIN', user);
}
