<template>
    <article class="article">
        <div
            v-if="item.image"
            role="img"
            :aria-label="`picture of ${item.title}`"
            :style="{ 'background-image': `url(${item.image})` }"
            class="image"
        ></div>
        <div v-else class="no-image">
            <svgicon :icon="defaultImage" width="120" height="90" :original="true" />
        </div>
        <div class="content">
            <h3>
                <a
                    :href="item.url"
                    class="consentRedirect"
                    target="_blank"
                    aria-describedby="new-window-warning"
                    rel="noopener noreferrer"
                >
                    {{ item.title }}
                </a>
            </h3>
            <p>{{ item.summary }}</p>
        </div>
    </article>
</template>

<script>
import { get as _get } from 'lodash';
export default {
    props: {
        item: { type: Object, required: true },
    },
    computed: {
        defaultImage() {
            const category = _get(this.item, 'category', 'general_tips');
            return `blog_bg_${category}`;
        },
    },
};
</script>

<style lang="scss">
.article {
    display: flex;
    align-items: flex-start;
    font-size: 14px;
    line-height: 1.21;

    & + .article {
        border-top: 1px solid $gray;
    }

    .image {
        flex-shrink: 0;
        width: 125px;
        min-height: 95px;
        margin: 12px;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        border-radius: 6px;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    }

    .no-image {
        background-color: #007944;
        margin: 10px 20px;
        padding: 5px;
        border-radius: 6px;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    }

    .content {
        padding: 10px 20px 10px 0;
    }

    h3 {
        margin-bottom: 5px;
        margin-top: 10px;
        color: $green;
        font-size: 1.5rem;
        a {
            text-decoration: none;
            transition: color 0.3s;
        }

        a:hover {
            color: $black;
        }
    }

    @include media('<lg') {
        & {
            font-size: 13px;
        }

        .image {
            min-height: 105px;
        }

        .content {
            padding: 8px 10px;
        }
    }

    @include media('<md') {
        & {
            margin-top: 10px;
            flex-direction: column;
        }

        .image {
            margin: 0;
            width: 100%;
            min-height: 150px;
            border-radius: 0;
        }
        .content {
            padding: 8px 10px 30px 10px;
        }
    }
}
</style>
