/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    click: {
        width: 16,
        height: 16,
        viewBox: '0 0 59 67',
        data:
            '<path pid="0" d="M43.952 29.539l-28.41-15.24c-.98-.523-1.62-.059-1.425 1.029l5.579 31.757c.2 1.091.99 1.232 1.763.312l5.453-6.472 7.885 10.899c.638.883 1.963 1.019 2.947.307l5.36-3.875c.981-.712 1.267-2.01.627-2.893L35.84 34.459l7.856-3.15c1.117-.448 1.235-1.245.256-1.77z" _fill="none" _stroke="#00874e" stroke-width="2.8000035"/>',
    },
});
