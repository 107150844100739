import _ from 'lodash';
import axios from 'axios';
import Vue from 'vue';

export default {
    SEND_CONTENT_OVERRIDE: ({ commit, state, getters }, payload) => {
        return contentOverrideCall('put', payload, state, null, commit, 'INIT_OVERRIDES', getters);
    },
};

const contentOverrideCall = function (method, data, state, path, commit, commitTo, getters) {
    const branch = _.get(state, 'meta.branch', 'master');
    const user = state.editor.user;
    const identity = {
        id: user.email_address,
        organization: user.user_type,
    };

    const payload = { ...data };
    // force coercion to a string for the value
    // so Peachy doesn't turn us out
    if ('value' in payload) {
        payload.value = String(payload.value);
    }

    Vue.prototype.$keen('contentoverride', { method, path, identity, payload });

    // Backend requires overrides to be wrapped in an 'override' object.
    let overrideWrapper = { override: {} };
    _.set(overrideWrapper, 'override', data);
    const config = {
        method,
        data: _.set(overrideWrapper, 'override.branch', branch),
        url: `${getters.apiUrl}/overrides/${state.meta.domain}/`,
        xsrfHeaderName: 'X-CSRFToken',
        xsrfCookieName: 'csrftoken',
        withCredentials: true,
    };
    const ax = axios(config).catch((err) => {
        if (err.response) {
            if (err.response.status === 401 || err.response.status === 403) {
                commit('UPDATE_EDITOR', {
                    showHelp: false,
                    editing: 'SessionExpired',
                    showCancel: false,
                });
            }
        }
    });
    if (commit && commitTo) {
        return ax.then((response) => {
            commit(commitTo, response.data);
        });
    }

    return ax;
};
