/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    file: {
        width: 16,
        height: 16,
        viewBox: '0 0 16.04 20.38',
        data:
            '<path pid="0" d="M13.63 20.38H2.41A2.41 2.41 0 0 1 0 18V2.41A2.41 2.41 0 0 1 2.41 0h11.22A2.41 2.41 0 0 1 16 2.41V18a2.41 2.41 0 0 1-2.37 2.38zM2.41 1A1.45 1.45 0 0 0 1 2.41V18a1.45 1.45 0 0 0 1.45 1.45h11.18A1.45 1.45 0 0 0 15.07 18V2.41A1.45 1.45 0 0 0 13.63 1z" _fill="currentColor"/><path pid="1" d="M12.51 4.79h-9a.48.48 0 1 1 0-1h9a.48.48 0 0 1 0 1zm0 2.94h-9a.48.48 0 1 1 0-1h9a.48.48 0 0 1 0 1zm0 2.94h-9a.48.48 0 0 1 0-1h9a.48.48 0 0 1 0 1zm0 2.94h-9a.48.48 0 0 1 0-1h9a.48.48 0 0 1 0 1zm0 2.94h-9a.48.48 0 1 1 0-1h9a.48.48 0 0 1 0 1z" _fill="currentColor"/>',
    },
});
