import 'core-js/stable';
import { createApp } from './app';
import axios from 'axios';
import pa11y_data from '../tests/pa11y/data';

const appInit = function (initialState) {
    const { app, router, store } = createApp(initialState);

    // prime the store with server-initialized state.
    // the state is determined during SSR and inlined in the page markup.
    if (window.__INITIAL_STATE__) {
        store.replaceState(window.__INITIAL_STATE__);
    }

    router.onReady(() => {
        // Add router hook for handling asyncData.
        // Doing it after initial route is resolved so that we don't double-fetch
        // the data that we already have. Using router.beforeResolve() so that all
        // async components are resolved.
        router.beforeEach((to, from, next) => {
            const matched = router.getMatchedComponents(to);
            const prevMatched = router.getMatchedComponents(from);
            let diffed = false;
            const activated = matched.filter((c, i) => diffed || (diffed = prevMatched[i] !== c));
            const asyncDataHooks = activated.map((c) => c.asyncData).filter((_) => _);

            if (!asyncDataHooks.length) {
                return next();
            }

            return Promise.all(asyncDataHooks.map((hook) => hook({ store, route: to })))
                .then(() => {
                    next();
                })
                .catch(next);
        });

        app.$mount('#app');
    });
};

if (process.env.NODE_ENV === 'development') {
    if (process.env.VUE_APP_PA11Y === 'true') {
        appInit(pa11y_data);
    } else {
        axios({ url: `/devconfig${window.location.search}` }).then((params) => {
            axios(params.data).then((response) => {
                response.data.meta.branch =
                    process.env.VUE_APP_GIT_BRANCH || response.data.meta.branch;
                response.data.meta.version =
                    process.env.VUE_APP_GIT_VERSION || response.data.meta.version;

                appInit(response.data);
            });
        });
    }
} else {
    appInit();
}
