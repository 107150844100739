<template>
    <div class="bar">
        <div class="container">
            <div
                v-if="$store.getters.office.phones.position === 'black-bar'"
                class="hidden-xs phone-number"
            >
                <a :href="'tel: ' + $store.getters.phone.stripped">
                    <svgicon :original="true" icon="phone" height="17" width="17" />
                    {{ $store.getters.phone.pretty }}
                </a>
                <ChangeBtn :has-editor="true" type="PhoneNumberPlacement" />
            </div>
            <h2 class="label">File a Claim:</h2>
            <ul class="list-links">
                <li>
                    <a
                        :href="`https://www.desjardins.com/on/en/insurance/online-services.html${$store.getters.desjardinsQueryParams}`"
                        class="file-a-claim-link-auto consentRedirect"
                        target="_blank"
                        aria-describedby="new-window-warning"
                        rel="noopener noreferrer"
                    >
                        <svgicon
                            class="file-a-claim-link-auto"
                            icon="car"
                            width="18"
                            :original="true"
                        />
                        <strong class="file-a-claim-link-auto">Auto</strong>
                    </a>
                </li>
                <li>
                    <a
                        :href="`https://www.desjardins.com/on/en/insurance/online-services.html${$store.getters.desjardinsQueryParams}`"
                        class="file-a-claim-link-home consentRedirect"
                        target="_blank"
                        aria-describedby="new-window-warning"
                        rel="noopener noreferrer"
                    >
                        <svgicon
                            class="file-a-claim-link-home"
                            icon="house"
                            width="16"
                            height="20"
                            :original="true"
                        />
                        <strong class="file-a-claim-link-home">Home</strong>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Bar',
};
</script>

<style lang="scss" scoped>
.bar {
    padding: 12px 0;
    background: $bar-color;
    text-align: right;
    color: #fff;
    display: flex;
    flex-direction: row;

    .container {
        .phone-number {
            font-weight: 400;
            color: $gray;
            display: inline-block;
            font-size: 24px;

            svg {
                max-height: 45px;
            }
        }

        .label {
            display: inline-block;
            margin: 0 20px;
            color: $gray;
            font-size: 18px;
            font-weight: bold;
        }

        .list-links {
            li,
            svg {
                max-height: 45px;
            }

            display: inline-block;
        }
    }
}
</style>
