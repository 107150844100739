<template>
    <section class="profile">
        <div class="outer">
            <div class="group">
                <div class="inner">
                    <div class="image-wrapper">
                        <img
                            v-if="useAgentAvatar"
                            :alt="agent.names.PreferredName"
                            class="lazyload"
                            :data-src="resizedImage"
                            @error="useAgentAvatar = false"
                        />
                        <img
                            v-else
                            :alt="agent.names.PreferredName"
                            class="lazyload"
                            :src="noImage"
                        />
                        <ChangeBtn
                            v-if="!editorView"
                            :has-editor="true"
                            class="img-change-btn"
                            type="AgentProfileImage"
                        />
                    </div>

                    <div class="content">
                        <div class="entry">
                            <h2 class="sr-only">Office Info</h2>
                            <p class="agent-name">
                                {{ agent.names.PreferredName }}
                                <ChangeBtn v-if="!editorView" type="Name" />
                            </p>

                            <p>
                                {{ office.name ? office.name : 'Desjardins Insurance Agent' }},
                                <span class="desktop-only">
                                    {{ office.physicalAddress.municipality }},
                                    {{ office.physicalAddress.provinceCode }}
                                </span>
                                <span class="mobile-only">
                                    {{ office.physicalAddress.street1 }}
                                    <br />
                                    <span v-if="office.physicalAddress.street2">
                                        {{ office.physicalAddress.street2 }}
                                        <br />
                                    </span>
                                    {{ office.physicalAddress.municipality }}
                                    {{ office.physicalAddress.province }},
                                    {{ office.physicalAddress.postalCode }}
                                    <small class="mt-10">
                                        {{ office.physicalAddress.landmark.long }}
                                    </small>
                                    <ChangeBtn
                                        v-if="!editorView"
                                        type="Landmark"
                                        :has-editor="true"
                                    />
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="list-buttons visible-xs-block">
                    <ul>
                        <li>
                            <a :href="`tel:${preferredPhone.stripped}`" class="btn-green">
                                <span>
                                    <svgicon :original="true" icon="phone" width="22" />
                                    <strong>Call</strong>
                                </span>
                            </a>
                        </li>

                        <li v-if="false">
                            <!-- This is a post launch feature. -->
                            <a href="#" class="btn-green">
                                <svgicon
                                    :original="true"
                                    height="15"
                                    icon="text-bubble"
                                    width="18"
                                />
                                <strong>Text</strong>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="group">
                <p
                    v-if="office.phones.position === 'below-profile' || editorView"
                    class="hidden-xs telephone"
                >
                    <a :href="'tel: ' + preferredPhone.stripped">{{ preferredPhone.pretty }}</a>
                    <ChangeBtn v-if="!editorView" :has-editor="true" type="PhoneNumberPlacement" />
                </p>
                <div v-if="Object.keys(additionalPhones).length > 0">
                    <table>
                        <tr v-for="(phone, type) in additionalPhones" :key="type">
                            <td class="type">
                                <div>
                                    <strong>{{ type }}:</strong>
                                </div>
                            </td>
                            <td class="number">
                                <a
                                    v-for="number in phone.numbers"
                                    :key="number"
                                    :href="`tel:${phone.number}`"
                                    class="green-link"
                                >
                                    {{ number }}
                                    <br />
                                </a>
                            </td>
                        </tr>
                    </table>
                </div>
                <br />

                <p class="desktop-only">
                    {{ office.physicalAddress.street1 }}
                    <br />
                    <span v-if="office.physicalAddress.street2">
                        {{ office.physicalAddress.street2 }}
                        <br />
                    </span>

                    {{ office.physicalAddress.municipality }}
                    {{ office.physicalAddress.province }},
                    {{ office.physicalAddress.postalCode }}
                    <small class="mt-10" v-html="landmark"></small>
                    <ChangeBtn v-if="!editorView" type="Landmark" :has-editor="true" />
                </p>
            </div>

            <div class="group hidden-xs">
                <h3 aria-label="Hours">
                    Office Hours
                    <ChangeBtn v-if="!editorView" :has-editor="true" type="OfficeHours" />
                </h3>
                <work-time />
            </div>

            <div class="group hidden-xs">
                <h3>Languages</h3>
                <Languages />
            </div>

            <div class="group">
                <ul class="list-actions">
                    <li v-for="(link, index) in links" :key="index">
                        <a
                            v-if="!link.isHidden"
                            :href="link.src"
                            :target="link.target"
                            :class="link.peachyTag"
                        >
                            <span :class="link.peachyTag">
                                <svgicon
                                    :icon="link.icon.name"
                                    :width="link.icon.width"
                                    :height="link.icon.height"
                                    :original="true"
                                    :class="['ico-' + link.icon.name, link.peachyTag]"
                                />
                                <strong :class="link.peachyTag">{{ link.title }}</strong>
                            </span>
                        </a>
                        <i v-else-if="editor.allowed">{{ link.enableText }}</i>
                        <span v-if="link.help">
                            <ChangeBtn
                                v-if="!editorView"
                                :has-editor="link.hasEditor"
                                :type="link.help"
                            />
                        </span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="foot hidden-xs">
            <Socials :editor-view="editorView" />
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import {
    cloneDeep,
    get as _get,
    hasIn,
    reduce,
    set as _set,
    startCase,
    omit,
    isNil,
    pickBy,
} from 'lodash';

import Socials from '@/components/SectionAbout/AgentProfile/Socials.vue';
import WorkTime from '@/components/SectionAbout/AgentProfile/WorkTime.vue';
import Languages from '@/components/SectionAbout/AgentProfile/Languages.vue';
import ChangeBtn from '@/components/Editor/ChangeBtn.vue';
import noImage from '@/assets/img/no-profile-avatar.jpg';
import { getResized } from '@/utils/ephemera.js';

export default {
    name: 'Profile',
    components: {
        Socials,
        WorkTime,
        Languages,
        ChangeBtn,
    },
    props: {
        editorView: {
            type: Boolean,
            default: false,
        },
        phoneVisibility: { type: Object, default: null },
        overridePreferredPhone: { type: Object, default: null },
    },
    data() {
        return {
            useAgentAvatar: true,
            noImage,
            phoneMetaKeys: [
                'additionalNumbersPreference',
                'phoneVisibility',
                'position',
                'preferred',
            ],
        };
    },
    computed: {
        ...mapGetters(['agent', 'office', 'phone', 'ui', 'editor']),
        preferredPhone() {
            return this.overridePreferredPhone || this.phone;
        },
        landmark() {
            const { long, short, selected } = this.office.physicalAddress.landmark;
            switch (selected) {
                case 'both':
                    return `${short} <br/> ${long}`;
                case 'short':
                    return short;
                case 'long':
                default:
                    return long;
            }
        },
        additionalPhones() {
            const officePhones = omit(this.office.phones, this.phoneMetaKeys);
            const additionalPreference = this.office.phones.additionalNumbersPreference;
            let phoneVisibility;

            if (this.editorView) {
                phoneVisibility = this.phoneVisibility;
            } else {
                phoneVisibility = pickBy(officePhones, (phone, key) => {
                    if (additionalPreference === 'matchDefaultMicrosite') {
                        return phone.onMicrosite || phone.onDefaultMicrosite;
                    } else if (additionalPreference === 'custom') {
                        return (
                            phone.number &&
                            _get(this.office, `phones.phoneVisibility.${key}.visible`, false)
                        );
                    }
                });
            }

            if (isNil(phoneVisibility)) {
                return [];
            }

            // TODO: the logic for this var can probably be wrapped up in the the following reduce
            const phones = reduce(
                Object.keys(phoneVisibility).filter((p) => phoneVisibility[p]),
                (acc, key) => _set(acc, key, this.office.phones[key]),
                {},
            );

            // now we need to merge numbers that are have similar keys
            return reduce(
                cloneDeep(phones),
                (acc, val, key) => {
                    const cleanedKey = startCase(
                        key.replace(/[0-9]/g, '').replace('additional', ''),
                    );

                    if (hasIn(acc, cleanedKey)) {
                        acc[cleanedKey].numbers.push(val.number);
                    } else {
                        _set(acc, cleanedKey, { ...val, numbers: [val.number] });
                    }

                    return acc;
                },
                {},
            );
        },
        links() {
            return [
                {
                    title: 'Map & Directions',
                    src: this.office.maps.url,
                    target: '_blank',
                    icon: {
                        name: 'pin',
                        width: '27',
                        height: '27',
                    },
                    help: 'MapDirections',
                    peachyTag: 'map-link',
                },
                {
                    title: 'Contact Us',
                    src: '#contact-us',
                    icon: {
                        name: 'envelope',
                        width: '27',
                        height: '27',
                    },
                    help: 'ContactMe',
                },
                {
                    title: 'Review us on Google',
                    src: '/reviews',
                    icon: {
                        name: 'google-review',
                        width: '27',
                        height: '27',
                    },
                    help: 'GoogleReview',
                    hasEditor: true,
                    peachyTag: 'google-review-link',
                    isHidden: !this.ui.review.visible,
                    enableText: 'Enable Google Review',
                },
            ];
        },
        resizedImage() {
            return getResized(this.agent.image.profile, 200);
        },
    },
};
</script>

<style lang="scss" scoped>
.profile {
    padding: 15px 12px 15px 15px;
    position: relative;
    border: 1px solid $gray;
    background: #fff;
    border-radius: 16px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);

    table {
        tr {
            td {
                vertical-align: top;
                border-bottom: 1px solid $gray;

                &:nth-child(1) {
                    width: 1px;
                }

                &.type {
                    width: 1px;
                    padding-right: 10px;

                    div {
                        width: max-content;
                    }
                }

                &.number {
                    text-align: right;
                    padding-left: 10px;

                    a {
                        cursor: pointer;
                    }
                }
            }
        }
    }

    h1 {
        font-size: 32px;
        line-height: 1;
        margin: 0 0 10px;

        a {
            display: inline-block;
        }
    }

    .agent-name {
        font-size: 2.5rem;
        font-weight: 700;
        margin-bottom: 7px;
    }

    h3 {
        font-size: 1.7rem;
        margin-bottom: 2px;
        color: $green;
    }

    small {
        display: block;
        font-size: 13px;

        &.mt-10 {
            margin-top: 10px;
        }
    }

    .inner {
        display: flex;
        align-items: center;
    }

    .image-wrapper {
        min-width: 78px;
        margin-right: 11px;
        position: relative;
        flex-shrink: 1;
        .img-change-btn {
            bottom: 0;
            position: absolute;
            right: 0;
        }

        img {
            border-radius: 8px;
        }
    }

    .content {
        width: 100%;
        padding-bottom: 13px;
        font-size: 14px;
        flex-shrink: 1;
    }

    .group {
        font-size: 15px;
        line-height: 1.39;
    }

    .group + .group {
        margin-top: 8px;
    }

    .foot {
        margin-top: 13px;
    }

    .list-actions {
        li {
            display: flex;
            flex-wrap: wrap;
        }
        span {
            display: flex;
            align-items: center;

            strong {
                font-size: 18px;
                margin-top: 5px;
            }
        }
        i {
            margin: 3px 8px 0 0;
        }
        a {
            justify-content: center;
        }
        @include media('<md') {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
            column-gap: 10px;
            row-gap: 10px;
            a {
                border: 2px solid $green;
                color: $green;
                border-radius: 8px;
                display: flex;
                padding: 8px;
                width: 100%;

                &:hover,
                &:focus {
                    background: $green;
                    color: #fff;
                }
            }
            li + li {
                margin: 0;
            }
        }
    }
    .list-buttons {
        ul {
            // align-items: center;
            // display: flex;
            // justify-content: space-around;
            list-style: none;
            margin-top: 10px;
            width: 100%;
        }
        a {
            display: flex;
            justify-content: space-around;
            width: 100%;
            cursor: pointer;
        }
        span {
            align-items: center;
            display: flex;
            font-size: 26px;
        }
    }
    @include media('<lg') {
        & {
            padding: 15px 10px;
        }

        h1 {
            font-size: 26px;
        }

        small {
            font-size: 12px;
        }

        .group {
            font-size: 14px;
        }

        .image-wrapper {
            width: 78px;
            margin-right: 8px;
        }

        .content {
            padding-bottom: 0;
            font-size: 13px;
        }
    }

    @include media('<md') {
        & {
            position: relative;
            padding: 15px 0;
            border: 0;
            box-shadow: none;
        }

        small {
            font-size: 13px;
        }

        .image-wrapper {
            margin-right: 12px;
        }

        .agent-name {
            margin-bottom: 4px;
        }

        .inner {
            align-items: flex-start;
        }

        .content {
            display: flex;
            font-size: 14px;
        }

        .entry {
            width: 100%;
        }

        .group {
            font-size: 15px;
            line-height: 1.35;
        }

        .group + .group {
            margin-top: 11px;
        }
    }

    @include media('<sm') {
        small {
            font-size: 12px;
        }

        .image-wrapper {
            margin-right: 8px;
        }

        .content {
            font-size: 13px;
        }

        .group {
            font-size: 13px;
        }
    }
    .telephone {
        font-size: 2.6rem;
        color: $green;
        margin-bottom: 0;
        overflow-x: auto;
        overflow-y: hidden;
        max-width: 100%;
    }
}
</style>
