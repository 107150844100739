/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    house: {
        width: 16,
        height: 16,
        viewBox: '0 0 83 68',
        data:
            '<path pid="0" d="M41.077.002l-13.68 8.394V6.058c0-.68-.552-1.232-1.227-1.232H14.898a1.23 1.23 0 0 0-1.234 1.232V16.82L0 25.21l5.104 7.917 5.898-3.317v32.197c0 2.216 1.832 5.384 4.046 5.384h18.416V48.335h16.258v19.056h17.926c2.216 0 3.24-3.168 3.24-5.384V29.812l5.666 3.318 5.47-7.918L41.077.002z" _fill="currentColor"/>',
    },
});
