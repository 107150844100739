<template>
    <div class="nav-utilities">
        <a
            v-if="showLogOnBtn"
            class="btn-green consentRedirect"
            :href="`https://www.desjardins.com/on/en/insurance/online-services.html${$store.getters.desjardinsQueryParams}`"
        >
            <svgicon icon="lock" width="12" height="14" :original="true" />
            <span>Online services</span>
        </a>
    </div>
</template>

<script>
export default {
    name: 'NavUtilities',
    props: {
        showLogOnBtn: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.nav-utilities {
    list-style-type: none;
    font-size: 16px;

    align-items: center;
    display: flex;

    li {
        margin-left: 15px;
    }

    a {
        display: flex;
        align-items: center;
        text-decoration: none;
        font-weight: bold;
    }

    .svg-icon {
        margin: 0 6px;
    }
}
</style>
