/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    info: {
        width: 16,
        height: 16,
        viewBox: '0 0 18.82 18.82',
        data:
            '<path pid="0" class="cls-1" d="M9.41 0a9.41 9.41 0 1 0 9.41 9.41A9.42 9.42 0 0 0 9.41 0zm0 17.66a8.25 8.25 0 1 1 8.25-8.25 8.26 8.26 0 0 1-8.25 8.25z" _fill="currentColor"/><path pid="1" class="cls-1" d="M10.53 5.66a1 1 0 0 1-1.94 0 1 1 0 1 1 1.94 0zM9.3 8.2a.58.58 0 0 0-.58.58v4.93a.58.58 0 0 0 1.16 0V8.78a.58.58 0 0 0-.58-.58z" _fill="currentColor"/>',
    },
});
