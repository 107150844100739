<template>
    <div class="map">
        <a
            :href="mapUrl()"
            target="_blank"
            aria-describedby="new-window-warning"
            rel="noopener noreferrer"
        >
            <img
                class="lazyload"
                :data-src="src"
                alt="Map of office location"
                @error="imageLoadError"
            />
            <span class="sr-only">Link opens in a new window</span>
        </a>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: { position: { type: String, default: null } },
    data() {
        return {
            src: null,
            failures: 0,
        };
    },
    computed: mapGetters(['office']),
    mounted() {
        this.src = this.office.maps?.image?.[this.position] ?? '';
    },
    methods: {
        mapUrl() {
            return this.office.maps?.url ?? '';
        },
        imageLoadError(event) {
            if (this.failures > 1) {
                return;
            }
            event.target.src = `/map/${this.position}/`;
            this.failures++;
        },
    },
};
</script>

<style lang="scss" scoped>
.map {
    align-items: center;
    display: flex;
    min-height: 200px;
    width: 100%;

    img {
        width: 100%;
        border-radius: 8px;
    }

    @include media('<md') {
        & {
            min-height: 173px;
        }
    }
}
</style>
