<template>
    <div class="section-light-gray section-panels">
        <div class="container">
            <div class="inner flex">
                <section ref="leftPanels" class="content flex flex-column">
                    <Panel class="visible-xs-block flex-grow">
                        <h2 slot="head">
                            <span>
                                <svgicon
                                    icon="building-light"
                                    width="20"
                                    height="20"
                                    :original="true"
                                    class="mobile-only"
                                />
                            </span>
                            <strong>Languages</strong>
                        </h2>

                        <div slot="content">
                            <Languages />
                        </div>
                    </Panel>

                    <Panel class="visible-xs-block">
                        <h2 slot="head">
                            <span>
                                <svgicon icon="clock" width="20" height="20" :original="true" />
                            </span>
                            <strong>Office Hours</strong>
                        </h2>

                        <div slot="content">
                            <WorkTime />
                        </div>
                    </Panel>

                    <Panel class="shadow">
                        <h2 slot="head">
                            <span>
                                <svgicon
                                    :original="true"
                                    class="mobile-only"
                                    icon="pin"
                                    width="20"
                                    height="20"
                                />
                            </span>
                            <strong>
                                Location
                                <ChangeBtn type="MapDirections" />
                            </strong>
                        </h2>

                        <div slot="content">
                            <MapBox />
                        </div>
                    </Panel>

                    <Panel class="visible-xs-block">
                        <h2 slot="head">
                            <span>
                                <svgicon icon="info" width="20" height="20" :original="true" />
                            </span>
                            <strong>About Me</strong>
                        </h2>
                        <div
                            slot="content"
                            v-html="$options.filters.paraFormat($store.getters.agent.about)"
                        ></div>
                    </Panel>
                    <Panel class="visible-xs-block">
                        <h2 slot="head">
                            <span>
                                <svgicon
                                    icon="dollar-sign"
                                    width="20"
                                    height="20"
                                    :original="true"
                                />
                            </span>
                            <strong>Products and Services Offered</strong>
                        </h2>
                        <div slot="content" v-html="products"></div>
                    </Panel>
                    <Panel class="mobile-panel visible-xs-block">
                        <h2 slot="head">
                            <span>
                                <svgicon
                                    icon="mobile-alt"
                                    width="20"
                                    height="20"
                                    :original="true"
                                />
                            </span>
                            <strong>Mobile App</strong>
                        </h2>
                        <div slot="content"><MobileLinks :in-section-panel="true"/></div>
                    </Panel>
                    <Panel v-if="showAdjusto" class="mobile-panel visible-xs-block">
                        <h2 slot="head">
                            <span>
                                <svgicon
                                    icon="mobile-alt"
                                    width="20"
                                    height="20"
                                    :original="true"
                                />
                            </span>
                            <strong>Ajusto</strong>
                        </h2>
                        <div slot="content">
                            <a
                                href="https://www.desjardins.com/on/en/insurance/auto/ajusto.html"
                                class="consentRedirect"
                            >
                                <img
                                    class="ajusto-img"
                                    src="@/assets/svg/ajusto-promo.svg"
                                    href="https://www.desjardins.com/on/en/insurance/auto/ajusto.html"
                                    alt="Ajusto Promo"
                                />
                            </a>
                        </div>
                    </Panel>
                    <Panel class="visible-xs-block">
                        <h2 slot="head">
                            <span>
                                <svgicon icon="file" width="18" height="22" :original="true" />
                            </span>
                            <strong>File a Claim</strong>
                        </h2>

                        <div slot="content">
                            <ul>
                                <li>
                                    <a
                                        :href="`https://www.desjardins.com/on/en/insurance/online-services.html${$store.getters.desjardinsQueryParams}`"
                                        class="mobile-file-a-claim-link-auto consentRedirect"
                                        target="_blank"
                                        aria-describedby="new-window-warning"
                                        rel="noopener noreferrer"
                                    >
                                        <svgicon
                                            class="mobile-file-a-claim-link-auto consentRedirect"
                                            :original="true"
                                            icon="car"
                                            width="30"
                                        />
                                        <strong class="mobile-file-a-claim-link-auto">Auto</strong>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        :href="`https://www.desjardins.com/on/en/insurance/online-services.html${$store.getters.desjardinsQueryParams}`"
                                        class="mobile-file-a-claim-link-home consentRedirect"
                                        target="_blank"
                                        aria-describedby="new-window-warning"
                                        rel="noopener noreferrer"
                                    >
                                        <svgicon
                                            class="mobile-file-a-claim-link-home"
                                            :original="true"
                                            height="32"
                                            icon="house"
                                            width="30"
                                        />
                                        <strong class="mobile-file-a-claim-link-home">Home</strong>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </Panel>

                    <Panel class="flex-grow shadow">
                        <h2 slot="head">
                            <span>
                                <svgicon
                                    icon="list"
                                    width="16"
                                    height="20"
                                    :original="true"
                                    class="mobile-only"
                                />
                            </span>
                            <strong>
                                Articles
                                <ChangeBtn :has-editor="true" type="PageArticles" />
                            </strong>
                        </h2>

                        <div slot="content">
                            <div class="articles">
                                <BaseArticle
                                    v-for="article in $store.getters.articles"
                                    :key="article.title"
                                    :item="article"
                                />
                            </div>
                        </div>
                    </Panel>
                </section>
                <aside
                    v-show="advertisingCookiesEnabled"
                    v-if="(hasSocial && !feedFailed) || editingAllowed"
                >
                    <Panel class="shadow">
                        <h2 ref="leftHeader" slot="head" aria-label="Social media feed">
                            <span>
                                <svgicon
                                    icon="share"
                                    width="20"
                                    height="21"
                                    :original="true"
                                    class="mobile-only"
                                />
                            </span>
                            <strong>
                                Social Media
                                <ChangeBtn :has-editor="true" type="Social" />
                            </strong>
                        </h2>

                        <div slot="content">
                            <Socials hide-heading class="visible-xs-block" />
                            <SocialFeed />
                        </div>
                    </Panel>
                </aside>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import Panel from '@/components/Shared/Panel.vue';
import MapBox from '@/components/SectionPanels/MapBox.vue';
import BaseArticle from '@/components/SectionPanels/BaseArticle.vue';
import SocialFeed from '@/components/SectionPanels/SocialFeed.vue';
import WorkTime from '@/components/SectionAbout/AgentProfile/WorkTime.vue';
import Languages from '@/components/SectionAbout/AgentProfile/Languages.vue';
import Socials from '@/components/SectionAbout/AgentProfile/Socials';
import MobileLinks from '@/components/SectionAbout/MobileLinks.vue';

export default {
    name: 'SectionPanels',
    components: {
        Socials,
        Panel,
        MapBox,
        BaseArticle,
        SocialFeed,
        WorkTime,
        Languages,
        MobileLinks,
    },
    data() {
        return {
            advertisingCookiesEnabled: false,
        };
    },
    computed: {
        ...mapGetters(['office']),
        showAdjusto() {
            return this.office.provinceSettings?.showAdjusto;
        },
        feedFailed() {
            return _.get(this.$store.getters.ui, 'socialFeedFailed', false);
        },
        hasSocial() {
            return this.$store.getters.social.activeSocialFeed !== 'None';
        },
        products() {
            return this.$store.getters.agent.products.join(',&nbsp;&nbsp;');
        },
    },
    mounted() {
        this.$store.commit('UPDATE_UI', {
            leftPanels: this.$refs.leftPanels,
            leftHeader: this.$refs.leftHeader,
        });

        window.addEventListener('advertising-cookies-enabled', () => {
            this.advertisingCookiesEnabled = true;
        });
    },
};
</script>

<style lang="scss" scoped>
.section-light-gray {
    background: $light-gray;

    @include media('<md') {
        & {
            background: #fff;
        }
    }
}

.mobile-only {
    @include media('>=md') {
        display: none;
    }
}

.shadow {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    @include media('<md') {
        box-shadow: none;
    }
}

.ajusto-img {
    border-radius: 8px;
}
.section-panels {
    padding: 28px 0 40px;

    .content {
        width: 100%;
    }

    aside {
        flex-shrink: 0;
        width: 275px;
        margin-left: 30px;
    }

    @include media('<lg') {
        padding: 30px 0;
    }

    aside {
        width: 235px;
        margin-left: 15px;
    }

    @include media('<md') {
        & {
            padding: 20px 0 0;
        }

        .inner {
            flex-direction: column;
        }

        aside {
            flex-shrink: 0;
            width: 275px;
            margin-left: 30px;
        }

        @include media('<lg') {
            padding: 30px 0;
        }

        aside {
            width: 235px;
            margin-left: 15px;
        }

        @include media('<md') {
            & {
                padding: 20px 0 0;
            }

            .inner {
                flex-direction: column;
            }

            aside {
                width: 100%;
                margin-left: 0;
                margin-top: 1px;
            }
            ul {
                display: flex;
                justify-content: space-around;
                list-style: none;

                a {
                    align-items: center;
                    display: flex;
                    font-size: 26px;
                    text-decoration: none;
                }
            }
        }
    }
}
</style>
