<template>
    <div v-if="visible" class="has-text-centered" link="Social Media">
        <div
            ref="fb"
            :data-href="url"
            :data-height="height + 'px'"
            :data-width="width + 'px'"
            data-tabs="timeline"
            data-small-header="true"
            data-hide-cover="true"
            data-show-facepile="false"
            class="fb-page pa11y-ignore"
            tabindex="-1"
        ></div>
    </div>
</template>

<script>
/* eslint-env browser */
/* global FB */
import _ from 'lodash';
import Vue from 'vue';

export default {
    data() {
        return {
            office: this.$store.getters.office,
            subscribed: false,
            height: 0,
            width: 0,
            timer: null,
        };
    },
    computed: {
        visible() {
            if (this.office.social) {
                return _.has(this.office.social, 'Facebook');
            }
            return false;
        },
        url() {
            const fb = _.get(this.office, 'social.Facebook', null);
            return fb ? fb.url : null;
        },
    },
    method: {
        fbAsyncInit() {},
    },
    mounted: function () {
        window.addEventListener('resize', this.throttle);
        window.addEventListener('advertising-cookies-enabled', this.enableSDK);
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.throttle);
    },
    methods: {
        enableSDK() {
            window.fbAsyncInit = this.fbAsyncInit;
            this.isFBReady = Vue.FB != undefined;
            if (this.isFBReady) {
                this.resize();
            } else {
                window.addEventListener('fb-sdk-ready', () => {
                    this.onFBReady(this.resize);
                });
                if (Vue.FBinit) {
                    Vue.FBinit();
                }
                //todo: add error handling maybe?
                // This should only ever be an issue during the ssr build
            }
        },
        throttle() {
            // resize when our width changes or on initial page load
            clearTimeout(this.timer);
            this.timer = setTimeout(this.resize, 650);
        },
        resize() {
            const globalFB = window.FB;
            if (_.isNil(globalFB) || _.isNil(FB)) {
                setTimeout(this.resize, 100);
                return;
            }

            if (!this.subscribed) {
                globalFB.Event.subscribe('xfbml.render', this.loaded);
                this.subscribed = true;
            }

            const headerHeight = _.get(this.$store.getters.ui, 'leftHeader.clientHeight', 33);
            const h = _.get(this.$store.getters.ui, 'leftPanels.clientHeight', 0) - headerHeight;
            this.height = h > 100 ? h : 635;
            this.width = (this.$refs.fb && this.$refs.fb.clientWidth) || this.windowWidth();
            Vue.nextTick(FB.XFBML.parse);
        },
        windowWidth() {
            return (
                window.innerWidth ||
                document.documentElement.clientWidth ||
                document.body.clientWidth
            );
        },
        loaded(response) {
            if (response > 1 && this.$refs.fb) {
                setTimeout(() => {
                    const failed =
                        this.$refs.fb &&
                        this.$refs.fb.querySelector('iframe').style.width === '0px';
                    this.$refs.fb.querySelector('iframe').setAttribute('tabindex', '-1');
                    if (failed) {
                        console.error('FB feed failed');
                    }
                    this.$store.commit('UPDATE_UI', { socialFeedFailed: failed });
                }, 4000);
            }
        },
        onFBReady: function (callback) {
            this.isFBReady = true;
            this.loaded();
            callback();
        },
    },
};
</script>

<style lang="scss" scoped>
h5 {
    margin: 12px 14px 12px 0;
    font-size: 32px;
}

.fb-page {
    width: 100%;
    height: 100%;
}
</style>
