<template>
    <div
        :style="{ 'background-image': `url(&quot;${bannerImg || defaultBannerImg}&quot;)` }"
        aria-hidden="true"
        class="banner"
    >
        <ChangeBtn :has-editor="true" class="banner-img-change-btn" type="BannerImage" />
    </div>
</template>

<script>
import { getResized } from '@/utils/ephemera.js';

export default {
    name: 'Banner',
    props: {
        bannerImg: { default: null, type: String, required: false },
    },
    computed: {
        defaultBannerImg() {
            let bguri = this.$store.getters.office.banner_bg_url;

            if (bguri) {
                return getResized(bguri, 1400);
            }

            return '/map/header/';
        },
    },
};
</script>

<style lang="scss" scoped>
.banner {
    position: relative;
    aspect-ratio: 4/1;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    h1 {
        background-color: #fff;
        display: inline-block;
        position: absolute;
        padding: 20px;
    }

    @include media('>xl') {
        & {
            aspect-ratio: 5/1;
        }
    }
    .banner-img-change-btn {
        bottom: 10px;
        position: absolute;
        right: 5px;
    }
}
</style>
