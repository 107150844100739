<template>
    <div class="border">
        <a
            ref="fb"
            :href="$store.getters.social.Twitter.url"
            class="twitter-timeline tw-page"
            data-chrome="nofooter noborders transparent"
            :data-height="height + 'px'"
            data-width="100%"
            tabindex="-1"
        />
    </div>
</template>

<script>
import { get } from 'lodash';

export default {
    data() {
        return {
            height: 0,
            width: 0,
            timer: null,
        };
    },
    mounted: function () {
        window.addEventListener('resize', this.throttle);

        window.addEventListener('advertising-cookies-enabled', () => {
            this.launch(() => {
                this.resize();
            })
        })
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.throttle);
    },
    methods: {
        throttle() {
            // resize when our width changes or on initial page load
            clearTimeout(this.timer);
            this.timer = setTimeout(this.resize, 650);
        },
        resize() {
            const headerHeight = get(this.$store.getters.ui, 'leftHeader.clientHeight', 33);
            const h = get(this.$store.getters.ui, 'leftPanels.clientHeight', 0) - headerHeight;
            this.height = h > 100 ? h : 635;
        },
        launch(callback) {
            // set the twitter content behavior
            const d = window.document;
            const id = 'twitter-widgets';
            if (d.getElementById(id)) {
                // bail out if the element exists
                callback();
                return;
            }
            const s = 'script';
            const js = d.createElement(s);
            js.id = id;
            js.src = 'https://platform.twitter.com/widgets.js';
            const fjs = d.getElementsByTagName(s)[0];
            fjs.parentNode.insertBefore(js, fjs);
            callback();
        },
    },
};
</script>

<style lang="scss">
.tw-page {
    width: 100%;
    height: 100%;
}
.border {
    background-color: #fff;
    height: 100%;
}
</style>
