<template>
    <Tab>
        <Panel :rounded-corners="false">
            <h2 slot="head" class="visible-xs-block">
                <span>
                    <svgicon icon="figures-magnifier" width="26" height="23" :original="true" />
                </span>
                <strong>We're Hiring</strong>
            </h2>

            <div slot="content">
                <div class="">
                    <h3>Here's our current openings</h3>
                    <ul class="jobs">
                        <li v-for="(job, index) in office.jobs" :key="index" class="job-listing">
                            <h4>
                                <a :href="job.url">{{ job.title }}</a>
                            </h4>
                            <div>{{ job.location }} - {{ job.type }}</div>
                        </li>
                    </ul>
                </div>
            </div>
        </Panel>
    </Tab>
</template>
<script>
import Tab from '@/components/SectionTabs/Tab.vue';
import Panel from '@/components/Shared/Panel.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'Hiring',
    components: { Tab, Panel },
    computed: { ...mapGetters(['office']) },
};
</script>
<style lang="scss">
.jobs {
    list-style: none;
}
.job-listing {
    margin-bottom: 20px;
    div {
        color: $green;
    }
    h4 {
        margin-bottom: 3px;
        a {
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}
</style>
