import _ from 'lodash';

export function readMetaTags() {
    const mt = {};
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
        const metas = document.getElementsByTagName('meta');
        for (var i = 0; i < metas.length; i++) {
            const name = metas[i].getAttribute('name');
            if (name) {
                mt[name] = metas[i].getAttribute('content');
            }
        }
    }
    return mt;
}

export function paraFormat(text) {
    text = text || ''; // fallback to empty string to avoid render failure
    const tokens = {
        list: /^\s*\*|-/,
        // 'header' : /^\s*#/,
    };

    let parts = _.compact(text.split(/\r?\n\r?\n\r?/g));
    parts = _.flatten(
        _.map(parts, (p) => {
            const lines = p.split(/\r?\n/g);
            // console.log('lines:',lines);
            let blocks = [];
            let cblock = { type: null, lines: [] };
            _.each(lines, (line) => {
                const char = line.trim()[0];
                let ctype;
                _.each(tokens, (v, k) => {
                    if (v.test(char)) {
                        ctype = k;
                        line = line.replace(v, '').trim();
                        return false;
                    }
                });
                if (!ctype && line.length > 0) {
                    ctype = 'para';
                }
                // new block
                if (ctype !== cblock.type) {
                    if (cblock.lines.length === 0) {
                        cblock.type = ctype;
                    } else {
                        blocks.push(cblock);
                        cblock = { type: ctype, lines: [] };
                    }
                }
                cblock.lines.push(line);
            });
            blocks.push(cblock);
            return blocks;
        }),
    );
    const rebuilt = _.map(parts, (p) => {
        if (p.type === 'para') {
            let t = p.lines.join('<br>');
            // if (!/[.!?]$/.test(t)){ // add punctuation at the end
            // console.log("This doesn't end well");
            // t = t + '.';
            // }
            return `<p>${t}</p>`;
        } else if (p.type === 'list') {
            return `<ul>${_.map(p.lines, (l) => `<li>${l}</li>`).join('')}</ul>`;
        } else if (p.type === 'header') {
            return `<h4>${p.lines.join(' ')}</h4>`;
        }
    }).join(' ');
    // console.log('rebuilt:',rebuilt);

    return rebuilt;
    // replace multiple linebreaks with paragraphs
}

export function textbreak(rawtext, threshold) {
    if (!rawtext) {
        return {};
    }
    threshold = threshold || 150;
    const excessive = rawtext.length > threshold * 1.05;
    const breakon = ['.', ',', ' ', '?', '!'];
    if (!excessive) {
        return { less: rawtext };
    }
    // console.log('rawtext:',rawtext);
    const lefty = rawtext.substring(0, threshold - 1);
    const righty = rawtext.substring(threshold);
    const lmax = _.max(
        _.compact(
            _.map(breakon, (c) => {
                let idx = lefty.lastIndexOf(c);
                return idx < 0 ? null : idx;
            }),
        ),
    );
    const rmin =
        threshold +
            _.min(
                _.compact(
                    _.map(breakon, (c) => {
                        let idx = righty.indexOf(c);
                        return idx < 0 ? null : idx;
                    }),
                ),
            ) || rawtext.length;
    // console.log('constraints:',lmax,rmin);
    const spliton = function (idx) {
        return {
            less: rawtext.substring(0, idx),
            more: rawtext,
        };
    };
    if (lmax && !rmin) {
        return spliton(lmax);
    }
    if (rmin && !lmax) {
        return spliton(rmin);
    }

    return spliton(threshold - lmax < rmin - threshold ? lmax : rmin);
}

const querystring = function (query) {
    return querystring.decode(query);
};

querystring.decode = function (q) {
    q = q || window.location.search;
    if (q === '' || typeof q === 'undefined') {
        return {};
    }
    return (/^[?#]/.test(q) ? q.slice(1) : q).split('&').reduce((params, param) => {
        let [key, value] = param.split('=');
        params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
        return params;
    }, {});
};

querystring.encode = function (o, Qmark = false) {
    let a = [];
    for (let k in o) {
        let v = o[k].constructor === Array ? o[k].join(',') : o[k];
        a.push(`${encodeURIComponent(k)}${v ? '=' + encodeURIComponent(v) : ''}`);
    }
    return `${Qmark ? '?' : ''}${a.join('&')}`;
};

querystring.extend = function (o, Qmark = false) {
    const base = querystring.decode();
    const extended = _.extend(base || {}, o);
    return querystring.encode(extended, Qmark);
};

export { querystring };
