<template>
    <Tab class="team">
        <Panel :rounded-corners="false">
            <h2 slot="head" class="visible-xs-block">
                <span>
                    <svgicon icon="figures" width="29" height="23" :original="true" />
                </span>
                <strong>Our Team</strong>
            </h2>
            <div v-if="tabVisible" slot="content">
                <div v-if="hasDescription" class="team-description">
                    <p>
                        <MoreText :text="team.description" :threshold="350" />
                    </p>
                </div>
                <div class="team-members">
                    <TeamMember v-for="tm in teamMembers" :key="tm.aid" :item="tm" />
                </div>
            </div>
            <div v-else-if="editor.allowed" slot="content">
                <div class="tab-hidden-warning">
                    This tab is currently
                    <strong>Hidden</strong>
                    . Use the
                    <strong>Edit</strong>
                    button on the
                    <strong>Our Team</strong>
                    tab to change it to
                    <strong>Visible</strong>
                </div>
            </div>
        </Panel>
    </Tab>
</template>

<script>
import { mapGetters } from 'vuex';
import Tab from '@/components/SectionTabs/Tab.vue';
import Panel from '@/components/Shared/Panel.vue';
import TeamMember from '@/components/SectionTabs/TeamMember.vue';
import MoreText from '@/components/Shared/MoreText.vue';
import { filter, get as _get, has, isNil, orderBy } from 'lodash';

export default {
    name: 'Team',
    components: { Tab, Panel, TeamMember, MoreText },
    computed: {
        ...mapGetters(['team', 'editor', 'ui']),
        hasDescription() {
            return !isNil(this.team.description);
        },
        tabVisible() {
            return _get(this.ui, 'tabs.team.visible', true);
        },
        teamMembers() {
            // if displayOrder doesnt exist, the order will be left unchanged
            return orderBy(
                filter(this.team.members, (m) => (has(m, 'visible') ? m.visible : true)),
                'displayOrder',
            );
        },
    },
};
</script>

<style lang="scss">
.team {
    .panel {
        background: none;
    }
}

.team-description {
    font-size: 16px;
    line-height: 1.39;
    margin-bottom: 20px;
}

.team-members {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -16px -40px;

    .tm {
        width: 50%;
        padding: 0 16px;
        margin-bottom: 40px;
    }

    @include media('<md') {
        & {
            flex-direction: column;
            margin: 0;
        }

        .tm {
            width: 100%;
            padding: 0;
            margin-bottom: 20px;
        }

        .tm:last-child {
            margin-bottom: 0;
        }
    }
}

.tab-hidden-warning {
    text-align: center;
    font-size: 16px;
    background-color: $light-yellow;
    padding: 20px;
}
</style>
