<template>
    <div
        id="app"
        :class="{ outline: showOutline }"
        @click="showOutline = false"
        @keyup="showOutline = true"
    >
        <div class="sr-only">
            <span id="new-window-warning">link opens in a new window</span>
        </div>
        <h1 class="sr-only">
            {{ $store.getters.agent.names ? $store.getters.agent.names.PreferredName : '' }}
            Insurance Agency
        </h1>
        <MetaTags />
        <div class="wrapper">
            <main class="main">
                <router-view />
            </main>
            <PageFooter :show-app-links="showAppLinks" />
        </div>
    </div>
</template>

<script>
import { polyfills } from './utils/polyfills.js';
import MetaTags from '@/components/Layout/MetaTags.vue';
import { mapState } from 'vuex';
import PageFooter from '@/components/Layout/PageFooter.vue';

export default {
    name: 'MainApp',
    components: { MetaTags, PageFooter },
    data() {
        return {
            showOutline: true,
            showAppLinks: false,
        };
    },
    computed: {
        ...mapState(['meta']),
    },
    mounted() {
        polyfills();
        const route = this.$store.getters.route;
        if (Object.keys(route.query).includes('source')) {
            window.location.href = `https://www.desjardinsagents.com/agent/_/_/_/${this.$store.getters.agent.code}`;
        }
        if (route.path.startsWith('/quote')) {
            this.showAppLinks = true;
        }

        // insert Desjardins OneTrust cookie management script
        if (this.meta.cookie_management_id) {
            const cookieScript = document.createElement('script');
            cookieScript.id = 'consentementCookiesScript';  // required for Desjardins script to grab data-domain-script attribute value
            cookieScript.setAttribute('data-domain-script', this.meta.cookie_management_id);
            cookieScript.src = 'https://static.desjardins.com/fw/cookie/scriptCookie.js';
            const firstChild = document.head.firstChild;
            document.head.insertBefore(cookieScript, firstChild);
        }
    },
};
</script>

<style lang="scss">
@import '@/assets/scss/app.scss';

.outline {
    :focus {
        outline: #51cbee solid 3px !important;
    }

    input[type='radio'],
    input[type='checkbox'] {
        &:focus {
            & + label {
                outline: #51cbee solid 3px !important;
            }
        }
    }
}
</style>
