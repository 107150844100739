/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'dollar-sign': {
        width: 16,
        height: 16,
        viewBox: '0 0 19.38 19.38',
        data:
            '<path pid="0" d="M9.69 19.38a9.69 9.69 0 1 1 9.69-9.69 9.7 9.7 0 0 1-9.69 9.69zM9.69 1a8.69 8.69 0 1 0 8.69 8.69A8.7 8.7 0 0 0 9.69 1z" _fill="currentColor"/><path pid="1" d="M12.66 13.11a2.32 2.32 0 0 1-.92.89 3.63 3.63 0 0 1-1.43.4v1.67h-.93v-1.69A4.74 4.74 0 0 1 6.56 13l.72-.86a4.1 4.1 0 0 0 1.27.86 3.69 3.69 0 0 0 1.39.29 2.07 2.07 0 0 0 1.28-.37 1.18 1.18 0 0 0 .5-1 1.6 1.6 0 0 0-.05-.39 1.55 1.55 0 0 0-.14-.32 1.1 1.1 0 0 0-.23-.21l-.3-.22a3.6 3.6 0 0 0-.39-.19l-.44-.17-.52-.17a9.66 9.66 0 0 1-1.77-.71 2 2 0 0 1-1.1-1.64 2.11 2.11 0 0 1 0-.25 2.3 2.3 0 0 1 .7-1.71 2.83 2.83 0 0 1 1.92-.73V3.43h.93v1.81a6 6 0 0 1 1.26.38 5.37 5.37 0 0 1 1.09.62L12 7.1a4.12 4.12 0 0 0-1.16-.6 3.74 3.74 0 0 0-1.16-.22 2 2 0 0 0-1.19.34 1.08 1.08 0 0 0-.49.92.9.9 0 0 0 .06.34 1 1 0 0 0 .36.52 2.61 2.61 0 0 0 .3.2l.38.18.42.16.48.16a9.93 9.93 0 0 1 1.83.72 2.16 2.16 0 0 1 1.1 1.44 2.92 2.92 0 0 1 .05.6 2.44 2.44 0 0 1-.32 1.25z" _fill="currentColor"/>',
    },
});
