<!-- READ THE NOTES AT THE TOP OF THE OFFICE HOURS EDITOR -->

<template>
    <div class="work-time">
        <div v-if="hours && todayHours">
            <div
                class="rightnow"
                role="button"
                tabindex="0"
                :aria-expanded="expandHours ? expandHours : 'false'"
                @click="expandHours = !expandHours"
                @keyup.enter="expandHours = !expandHours"
            >
                <strong class="message">
                    <svgicon
                        :icon="expandHours ? 'angle-up' : 'angle-down'"
                        width="20"
                        height="20"
                        :class="expandHours ? 'angle-up' : 'angle-down'"
                        :original="true"
                    />
                    {{ todayHours.message }}
                </strong>
                <div v-if="todayHours.times" class="times">
                    <table v-if="isArray(todayHours.times)">
                        <tr v-for="(time, i) in todayHours.times" :key="i">
                            <td class="time">{{ timeAsString(time.start) }}</td>
                            <td class="dash">-</td>
                            <td class="time">{{ timeAsString(time.end) }}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div v-if="expandHours" class="expanded-hours">
                <div class="day">
                    <strong class="name">Timezone</strong>
                    <div>{{ mappedTimezone }}</div>
                </div>

                <div v-for="day in mergedStructuredHours" :key="day.name" class="day" tabindex="0">
                    <strong class="name">{{ day.name }}</strong>
                    <div>
                        <div v-if="day.openForBusiness === false" class="full-cell">Closed</div>
                        <table v-else-if="isArray(day.times)">
                            <tr v-for="(time, i) in day.times" :key="i">
                                <td class="time">{{ timeAsString(time.start) }}</td>
                                <td class="dash">-</td>
                                <td class="time">{{ timeAsString(time.end) }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <ul v-else class="list-hours">
            <li v-for="(hour, index) in office.hours" :key="index">{{ hour }}</li>
        </ul>
    </div>
</template>

<script>
import axios from 'axios';
import { getDay } from 'date-fns';
import { isBoolean, isArray, isNil, every, defaults, find, cloneDeep, padEnd } from 'lodash';
import { mapGetters } from 'vuex';
import { simplify, isComplicated, mapTimezone } from './../../../utils/hours.js';

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export default {
    name: 'WorkTime',
    data() {
        return {
            expandHours: false,
            todayHours: null,
            timeoutHandler: null,
        };
    },
    computed: {
        ...mapGetters(['office']),
        mappedTimezone() {
            return mapTimezone(this.office.physicalAddress.timezone);
        },
        hours() {
            if (isNil(this.office.structuredHours)) {
                return undefined;
            } else if (isComplicated(this.office.structuredHours)) {
                return simplify(this.office.structuredHours);
            } else {
                return this.office.structuredHours;
            }
        },
        mergedStructuredHours() {
            if (isNil(this.hours)) return [];

            let mergedDays = [];

            // if an entry has `byAppointment`, it should _only_ be included in mergedStructuredHours if it is `true`
            let hours = cloneDeep(this.hours).filter(
                (hour) => hour.byAppointment === undefined || hour.byAppointment === true,
            );

            for (let i = 0; i < hours.length; i++) {
                let dayA = hours[i];
                let name = dayA.name; // this will change

                for (let j = i + 1; j < hours.length; j++) {
                    const dayB = hours[j];
                    let equal = false;

                    if (!isBoolean(dayA.openForBusiness)) {
                        break;
                    } else if (dayA.openForBusiness !== dayB.openForBusiness) {
                        break;
                    } else if (dayA.openForBusiness === false) {
                        equal = true;
                    } else if (isArray(dayA.times) && isArray(dayB.times)) {
                        if (dayA.times.length === dayB.times.length) {
                            equal = every(dayA.times, (time, i) => {
                                if (isNil(time)) {
                                    return false;
                                }

                                return (
                                    this.compareTime(time.start, dayB.times[i].start) === 0 &&
                                    this.compareTime(time.end, dayB.times[i].end) === 0
                                );
                            });
                        }
                    }

                    if (equal) {
                        i = j; // NOTICE: setting i here!
                        name = `${dayA.name}-${dayB.name}`;
                    } else {
                        break;
                    }
                }

                mergedDays.push(defaults({ name }, dayA));
            }

            return mergedDays;
        },
    },
    mounted() {
        if (this.hours) {
            this.updateTodayHours();
        }
    },

    destroyed() {
        if (this.timeoutHandler) {
            clearTimeout(this.timeoutHandler);
        }
    },

    methods: {
        isArray: isArray,
        isNotNil: (a) => !isNil(a),
        timeAsString: (time) => {
            let hour = time.hours;
            let minutes = padEnd(time.minutes, 2, '0');

            if (hour === 0) {
                return `${12}:${minutes} am`;
            } else if (hour === 12) {
                return `${12}:${minutes} pm`;
            } else if (hour > 12) {
                return `${hour - 12}:${minutes} pm`;
            }
            return `${hour}:${minutes} am`;
        },
        compareTime(a, b) {
            return Number(`${a.hours}${a.minutes}`) - Number(`${b.hours}${b.minutes}`);
        },
        updateTodayHours() {
            const today = find(this.hours, { name: days[getDay(new Date())] });

            if (today.openForBusiness) {
                if (today.times) {
                    axios
                        .post('/hours', {
                            timezone: this.office.physicalAddress.timezone,
                            timestamp: Date.now(),
                            times: today.times,
                        })
                        .then((resp) => {
                            const data = resp.data;
                            this.todayHours = {
                                times: today.times,
                                message: data.open ? 'Open Now' : 'Closed Now',
                            };

                            if (this.timeoutHandler === null) {
                                this.timeoutHandler = setTimeout(
                                    this.updateTodayHours,
                                    data.change_in,
                                );
                            }
                        })
                        .catch((err) => {
                            console.error(err);
                            this.todayHours = { times: today.times, message: '' };
                        });
                } else {
                    this.todayHours = { message: 'Open Today by Appointment' };
                }
            } else {
                this.todayHours = { message: 'Closed Today' };
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.list-hours {
    list-style-type: none;
    font-size: 15px;
    line-height: 1.39;

    @include media('<lg') {
        & {
            font-size: 14px;
        }
    }

    @include media('<md') {
        & {
            font-size: 15px;
            line-height: 1;
        }

        li + li {
            margin-top: 5px;
        }
    }
}

.rightnow {
    display: flex;
    justify-content: space-between;
    color: $green;
    font-size: 14px;
    cursor: pointer;
    transition: color 0.3s;
    -webkit-transition: color 0.3s;
    align-items: center;

    &:hover {
        color: $black;
    }

    .message {
        font-size: 1.2em;
        white-space: nowrap;
        overflow: visible;
        display: flex;

        .svg-icon {
            margin-right: 5px;
        }
    }

    .times {
        text-align: right;
    }
}

.expanded-hours {
    font-size: 14px;
    width: 100%;

    .day {
        padding: 2px 2px 2px 2px;
        border-top: 1px solid #e0e0e0;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
    }
}

table {
    .time {
        text-align: right;
    }

    .dash {
        padding: 0px 5px;
    }
}
</style>
