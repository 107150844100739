<template>
    <div class="socials">
        <h3 v-if="!hideHeading" aria-label="Social media links">Social Media</h3>
        <ul>
            <li v-for="badge in badges" :key="badge.socialMediaType">
                <a
                    :aria-label="badge.socialMediaType"
                    :href="getUrl(badge)"
                    target="_blank"
                    aria-describedby="new-window-warning"
                    rel="noopener noreferrer"
                >
                    <svgicon :icon="badge.icon" :original="true" height="27" width="27" />
                </a>
            </li>
            <li aria-hidden="true">
                <ChangeBtn v-if="!editorView" :has-editor="true" type="Social" />
            </li>
        </ul>
    </div>
</template>

<script>
import _ from 'lodash';
export default {
    name: 'Socials',
    props: {
        editorView: {
            type: Boolean,
            default: false,
        },
        hideHeading: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        badges() {
            return this.$store.getters.social.badges.map((badge) => {
                return {
                    ...badge,
                    icon: badge.socialMediaType.toLowerCase(),
                };
            });
        },
    },
    methods: {
        getUrl(badge) {
            if (_.startsWith(badge.url, 'https://') || _.startsWith(badge.url, 'http://')) {
                return badge.url;
            } else {
                return `https://${badge.url}`;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.socials {
    font-size: 0;
    line-height: 1;

    ul {
        list-style-type: none;
    }

    li {
        display: inline-block;
        vertical-align: middle;
    }

    li + li {
        margin-left: 7px;
    }

    a {
        transition: opacity 0.3s;
    }

    a:hover {
        opacity: 0.7;
    }

    /*copied from  Profile.vue .profile { h3 }*/
    h3 {
        font-size: 1.7rem;
        margin-bottom: 2px;
        color: $green;
    }
}
</style>
