/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    share: {
        width: 16,
        height: 16,
        viewBox: '0 0 18.72 19.86',
        data:
            '<path pid="0" d="M15.16 12.76a3.55 3.55 0 0 0-2.81 1.38L6.9 11.09a3.46 3.46 0 0 0 .2-1.17 3.28 3.28 0 0 0-.21-1.17l5.45-3a3.55 3.55 0 1 0-.73-2.16 3.46 3.46 0 0 0 .2 1.17L6.37 7.77a3.55 3.55 0 1 0 0 4.31l5.46 3a3.35 3.35 0 0 0-.21 1.19 3.55 3.55 0 1 0 3.54-3.55zm0-11.64a2.44 2.44 0 1 1-2.43 2.43 2.43 2.43 0 0 1 2.43-2.43zM3.55 12.36A2.44 2.44 0 1 1 6 9.92a2.43 2.43 0 0 1-2.44 2.44zm11.61 6.38a2.44 2.44 0 1 1 2.44-2.44 2.43 2.43 0 0 1-2.44 2.44z" _fill="currentColor"/>',
    },
});
