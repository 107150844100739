/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    blog_bg_finances_and_life: {
        width: 400,
        height: 399.999,
        viewBox: '0 0 400 399.999',
        data:
            '<g _fill="#03884F"><path pid="0" d="M154.447 210.946l21.068-46.467 29.133 62.985 25.494-44.848 15.277 28.33h24.65c8.963-14.427 14.443-28.845 14.42-43.254-.043-22.072-18.975-39.949-42.268-39.904-20.957.04-31.49 9.932-41.949 29.701-10.459-19.769-20.99-29.661-41.961-29.701-23.289-.045-42.221 17.832-42.256 39.904-.018 14.409 5.457 28.828 14.42 43.254h23.972z"/><path pid="1" d="M238.375 222.754l-8.506-15.774-26.301 46.276-27.945-60.421-13.566 29.919h-23.504c16.117 21.67 38.91 43.355 61.721 65.033 22.811-21.678 45.594-43.363 61.717-65.033h-23.616z"/><path pid="2" d="M200 396.86C91.45 396.86 3.139 308.549 3.139 200 3.139 91.45 91.45 3.139 200 3.139S396.861 91.45 396.861 200c0 108.549-88.311 196.86-196.861 196.86zm0-343.721c-80.979 0-146.861 65.882-146.861 146.861S119.021 346.86 200 346.86 346.861 280.979 346.861 200 280.979 53.139 200 53.139z"/></g>',
    },
});
