/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    linkedin: {
        width: 16,
        height: 16,
        viewBox: '0 0 26.71 26.71',
        data:
            '<path pid="0" d="M26.71 13.35A13.36 13.36 0 1 1 13.35 0a13.36 13.36 0 0 1 13.36 13.35z" _fill="#007ab9"/><path pid="1" d="M21.33 14.43v5.5h-3.19V14.8c0-1.29-.46-2.17-1.62-2.17a1.74 1.74 0 0 0-1.63 1.16 2.07 2.07 0 0 0-.11.78v5.36h-3.19v-9.6h3.19v1.36a3.17 3.17 0 0 1 2.88-1.58c2.1 0 3.67 1.37 3.67 4.32zM8.25 5.71A1.66 1.66 0 1 0 8.21 9a1.66 1.66 0 1 0 0-3.31zM6.63 19.93h3.19v-9.6H6.63z" _fill="#f1f2f2"/>',
    },
});
