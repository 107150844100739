<template>
    <div v-if="languages !== ''">
        <span v-for="(lang, i) in languages" :key="lang" class="language">
            {{ i === languages.length - 1 ? lang : `${lang},` }}
        </span>
    </div>
</template>

<script>
import { get, concat, uniq, compact, capitalize } from 'lodash';
import { mapGetters } from 'vuex';
import { getLanguageNativeName } from '@/utils/languages.js';

export default {
    name: 'Languages',
    computed: {
        ...mapGetters(['agent', 'team']),
        languages() {
            let langs = get(this.agent, 'languages', []).map((lang) => lang.languageCode);
            get(this.team, 'members', []).forEach((member) => {
                langs = concat(
                    langs,
                    get(member, 'languages', []).map((lang) => lang.languageCode),
                );
            });
            langs = uniq(langs);
            langs = compact(langs);
            langs = langs.map(getLanguageNativeName);
            return langs.map(capitalize);
        },
    },
};
</script>

<style lang="scss" scoped></style>
