<template>
    <div class="tab">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'Tab',
};
</script>

<style lang="scss" scoped>
.tab {
    // display: none;

    &.active {
        display: block;
    }

    @include media('<md') {
        & {
            display: block;
        }

        & + .tab {
            margin-top: 1px;
        }
    }
}
</style>
