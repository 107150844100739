/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    blog_bg_business: {
        width: 400,
        height: 399.999,
        viewBox: '0 0 400 399.999',
        data:
            '<g _fill="#03884F"><path pid="0" d="M240.988 220.527c-6.367 0-77.984.027-78.223.027-7.979 0-11.518-4.443-12.834-5.186-1.059-.691-26.537-26.159-29.416-29.112v60.711c0 9.785 7.904 17.707 17.653 17.707h124.697c9.754 0 17.649-7.922 17.649-17.707V190.87c-8.274 7.82-21.761 20.53-24.348 22.849-3.305 3.094-7.624 6.97-15.178 6.808z"/><path pid="1" d="M265.854 155.458H135.18c-8.096.011-14.562 6.447-14.568 14.279v5.332c2.24 2.315 33.479 33.63 33.479 33.63 4.284 2.721 2.912 3.688 9.415 3.971H185.2c2.378-6.205 9.15-11.472 15.901-11.472 6.73 0 11.74 4.802 14.74 11.472h25.146c5.169-.152 5.992-1.424 9.939-4.828 1.635-1.457 8.484-7.896 15.311-14.316 5.869-5.517 11.709-11.044 14.19-13.38v-10.409c-.022-7.833-6.484-14.269-14.573-14.279zM220.783 120.994h-40.539c-7.168 0-15.678 6.995-15.678 15.607v11.43h16.216v-8.699c0-3.042 1.714-5.71 3.66-5.71h32.69c1.948 0 3.65 2.668 3.65 5.71v8.699h15.686v-11.43c.001-8.613-8.517-15.607-15.685-15.607z"/><path pid="2" d="M200 396.86C91.45 396.86 3.139 308.549 3.139 200 3.139 91.45 91.45 3.139 200 3.139S396.861 91.45 396.861 200c0 108.549-88.311 196.86-196.861 196.86zm0-343.721c-80.979 0-146.861 65.882-146.861 146.861S119.021 346.86 200 346.86 346.861 280.979 346.861 200 280.979 53.139 200 53.139z"/></g>',
    },
});
