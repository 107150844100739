<template>
    <div class="footer">
        <div class="container">
            <div class="content">
                <router-link to="/">
                    <span>
                        <span style="display: none">home</span>
                        <svgicon :original="true" height="41px" icon="logo" aria-label="go home" />
                    </span>
                </router-link>

                <ul class="nav">
                    <li>
                        <a
                            :href="`https://www.desjardins.com/ca/security/index.jsp${queryParams}`" 
                            class="consentRedirect"
                        >
                            Security
                        </a>
                    </li>

                    <li>
                        <a
                            :href="`https://www.desjardins.com/ca/privacy/index.jsp${queryParams}`"
                            class="consentRedirect"
                        >
                            Privacy
                        </a>
                    </li>

                    <li>
                        <a
                            :href="`https://www.desjardins.com/ca/terms-use-legal-notes/index.jsp${queryParams}`"
                            class="consentRedirect"
                        >
                            Terms of Use and legal notes
                        </a>
                    </li>

                    <li>
                        <a
                            :href="`https://www.desjardins.com/ca/about-us/social-responsibility-cooperation/cooperative-movement/accessibility/index.jsp${queryParams}`"
                            class="consentRedirect"
                        >
                            Accessibility
                        </a>
                    </li>

                    <li :class="{'hide-after' : !meta.cookie_management_id}">
                        <a
                            :href="`https://www.desjardins.com/ca/site-map/index.jsp${queryParams}`"
                            class="consentRedirect"
                        >
                            Site map
                        </a>
                    </li>

                    <li>
                        <a v-if="meta.cookie_management_id" href="#" role="button" class="ot-sdk-show-settings">Cookie Settings</a>
                    </li>
                </ul>

                <div class="copyright">
                    <p v-if="sellsMutualFunds">
                        Mutual Funds are not insurance products and are distributed through
                        representatives of Desjardins Financial Security Investments Inc.
                        <br />
                        <br />
                        Commissions, trailing commissions, management fees and expenses all may be
                        associated with mutual fund investments. Please read the prospectus before
                        investing. Mutual funds are not guaranteed, their values change frequently
                        and past performance may not be repeated.
                    </p>
                    <p>
                        Desjardins Insurance refers to Certas Home and Auto Insurance Company,
                        underwriter of automobile and property insurance or Desjardins Financial
                        Security Life Assurance Company, underwriter of life insurance and living
                        benefits products.
                    </p>

                    <p>
                        Desjardins, Desjardins Insurance and related trademarks are trademarks of
                        the Fédération des caisses Desjardins du Québec, used under licence.
                    </p>
                    <p>&copy; All rights reserved.</p>
                    <p>
                        Certas Home and Auto Insurance Company
                        <br />
                        Desjardins Financial Security Life Assurance Company
                        <br />
                        Desjardins Financial Security Investments Inc.
                    </p>
                </div>
            </div>
            <div v-if="showAppLinks" class="app-links">
                <p class="download-now">Download the Desjardins Insurance Home-Auto app today</p>
                <div class="links">
                    <a
                        id="desjardins-mobile-app-store-footer"
                        target="_blank"
                        aria-describedby="new-window-warning"
                        rel="noopener noreferrer"
                        href="https://apps.apple.com/ca/app/ajustodesjardinsassurances/id958825069?ct=lf457801&l=en_ca"
                    >
                        <img class="lazyload" :data-src="appstoreimg" alt="apple app store" />
                    </a>
                    <a
                        id="desjardins-mobile-play-store-footer"
                        aria-describedby="new-window-warning"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://play.google.com/store/apps/details?id=ca.dgag.ajusto&hl=en_CA&referrer=utm_source%3Ddesjardinsgeneralinsurance.com%26utm_medium%3Dreferral%26utm_campaign%3Dlf457801"
                    >
                        <img class="lazyload" :data-src="googleplayimg" alt="Google play store" />
                    </a>
                </div>
                <p>Available on Apple Watch</p>
            </div>
        </div>
    </div>
</template>

<script>
import googleplayimg from '@/assets/img/play-store.png';
import appstoreimg from '@/assets/img/app-store.png';
import { mapState } from 'vuex';

export default {
    name: 'PageFooter',
    props: {
        showAppLinks: Boolean,
    },
    data() {
        return {
            googleplayimg,
            appstoreimg,
        };
    },
    computed: {
        ...mapState(['meta']),
        queryParams() {
            return this.$store.getters.desjardinsQueryParams;
        },
        sellsMutualFunds() {
            return this.$store.getters.agentProducts.includes('Mutual Funds');
        },
    },
};
</script>

<style lang="scss" scoped>
.footer {
    padding: 70px 0 50px;
    background: $black;
    color: $gray;

    a {
        display: inline-block;
        margin-bottom: 10px;
    }

    .content {
        padding: 0 20px;
    }

    .copyright {
        font-size: 14px;
    }

    .app-links {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .download-now {
            font-size: 16px;
            text-align: center;
            margin-bottom: 5px;

            @include media('<lg') {
                font-size: 15px;
            }
        }

        .links {
            display: flex;
            justify-content: center;
            align-items: center;

            a + a {
                margin-left: 5px;
            }
            img {
                margin: 0;
                width: 150px;
            }
        }
    }

    .nav {
        margin-bottom: 14px;
        font-size: 14px;
        list-style-type: none;

        li {
            position: relative;
            display: inline-block;
            vertical-align: middle;
            padding-right: 14px;
            margin-right: 14px;

            &:after {
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                width: 1px;
                height: 20px;
                background: $green;
                margin-top: -16px;
            }
        }

        .hide-after {
            &:after {
                display: none;
            }
        }

        li:last-child {
            padding-right: 0;
            margin-right: 0;

            &:after {
                display: none;
            }
        }

        a {
            text-decoration: none;
            transition: color 0.3s;
        }

        a:hover {
            color: $green;
        }
    }

    @include media('<lg') {
        & {
            padding: 30px 0;
        }
    }

    @include media('<md') {
        .content {
            padding: 0 5px;
        }

        .nav {
            padding: 0 30px;
            margin-bottom: 32px;
            font-size: 15px;

            li {
                display: block;
                padding-right: 0;
                margin-right: 0;
                width: 100%;

                &:after {
                    display: none;
                }
            }

            li + li {
                margin-top: 6px;
            }
        }
    }
}
</style>
