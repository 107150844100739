/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    twitter: {
        width: 16,
        height: 16,
        viewBox: '0 0 26.71 26.71',
        data:
            '<path pid="0" d="M26.71 13.35A13.36 13.36 0 1 1 13.35 0a13.36 13.36 0 0 1 13.36 13.35z" _fill="#55acee"/><path pid="1" d="M21.53 9.6a6.52 6.52 0 0 1-1.83.5 3.23 3.23 0 0 0 1.41-1.77 6.58 6.58 0 0 1-2 .78 3.2 3.2 0 0 0-5.53 2.18 2.92 2.92 0 0 0 .09.73 9 9 0 0 1-6.61-3.34 3.15 3.15 0 0 0-.43 1.61A3.2 3.2 0 0 0 8 13a3.2 3.2 0 0 1-1.44-.4 3.2 3.2 0 0 0 2.56 3.13 3.34 3.34 0 0 1-.84.11 3 3 0 0 1-.6-.06 3.2 3.2 0 0 0 3 2.22 6.43 6.43 0 0 1-4 1.36 6.23 6.23 0 0 1-.76 0 9.08 9.08 0 0 0 14-7.65v-.41a6.24 6.24 0 0 0 1.61-1.7z" _fill="#f1f2f2"/>',
    },
});
