<template>
    <div v-if="shortText" class="banner-notice">
        <!-- prettier-ignore -->
        <div class="title-link" @click="expanded = !expanded">
            <a v-if="fullText" href="#" role="button" :aria-expanded="expanded" class="closer">
                {{ expanded ? 'Close' : 'Open' }}
            </a>
            {{ shortText }}
        </div>
        <section v-if="expanded && fullText">
            <div class="message" v-html="fullText"></div>
        </section>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    data() {
        return { expanded: false };
    },
    computed: {
        ...mapGetters(['office']),
        shortText() {
            return this.office.provinceSettings?.bannerShortText;
        },
        fullText() {
            const fullText = this.office.provinceSettings?.bannerFullText;
            if (fullText) {
                return fullText
                    .split(/\r?\n/) // split by newline
                    .filter((line) => line) //remove blank lines
                    .map((line) => `<p>${line}</p>`) //wrap lines in p tags
                    .join('');
            }
            return '';
        },
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss">
.banner-notice {
    background-color: #e6e7e8;
    letter-spacing: 0;
    font-family: 'Source Sans Pro', Arial;
    font-weight: 400;
    .title-link {
        text-align: center;
        font-size: 16px;
        padding: 1rem 3rem 1rem 3rem;
        @include media('<md') {
            font-size: 14px;
            padding: 1rem 2rem 1rem 0.4rem;
        }
        line-height: 22px;
        font-weight: bold;
        &:hover {
            cursor: pointer;
        }
        .warning {
            font-size: 40px;
            color: #eb7302;
        }
        .closer {
            font-size: 12px;
            float: right;
        }
    }
    section {
        padding: 1rem;
        .message {
            padding: 1rem;
            line-height: 20px;
            font-size: 14px;
            background-color: #fff;
            border-radius: 8px;
            p {
                margin-bottom: 10px;
            }
            ul {
                margin: 1rem 0 1rem 2.5rem;
            }
            a {
                color: $green;
            }
        }
    }
}
</style>
