<template>
    <div class="map-box">
        <MapImage class="map-wrapper" position="sidebar-md" />

        <div class="content">
            <p class="address">
                {{ office.physicalAddress.street1 }}
                <span v-if="office.physicalAddress.street2">
                    {{ office.physicalAddress.street2 }}
                    <br />
                </span>
                {{ office.physicalAddress.municipality }},
                {{ office.physicalAddress.provinceCode }}
                <br />
                {{ office.physicalAddress.postalCode }}
            </p>

            <p class="landmark">
                {{ office.physicalAddress.landmark[office.physicalAddress.landmark.selected] }}
            </p>

            <a
                :href="office.maps.url"
                class="btn-light"
                target="_blank"
                aria-describedby="new-window-warning"
                rel="noopener noreferrer"
                aria-label="Visit location in Google Maps"
            >
                    <svgicon
                        :original="true"
                        icon="pin"
                        height="2.4rem"
                        width="2.4rem"
                        class="svg-max-width"
                    />
                    <strong>Map &amp; Directions</strong>
            </a>
        </div>
    </div>
</template>

<script>
import MapImage from '@/components/SectionPanels/MapImage.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'MapBox',
    components: { MapImage },
    computed: { ...mapGetters(['office']) },
};
</script>

<style lang="scss" scoped>
.btn-light {
    display: flex;
    justify-content: center;
}

.map-box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 12px;

    .map-wrapper {
        width: 67%;
        margin-right: 12px;
    }

    .address {
        margin-bottom: 10px;
        font-size: 2.1rem;
    }

    .content {
        flex-shrink: 0;
        flex-grow: 1;
        width: 30%;
        font-size: 14px;
        line-height: 1.4;
        color: #231f20;

        p:last-of-type {
            margin-bottom: 0;
        }

        a {
            max-width: 100%;
            margin-top: 20px;
            display: flex;

            svg {
                margin-right: 10px;
                margin-top: 2px;
            }
        }
    }

    @include media('<xl') {
        .content {
            a {
                font-size: 18px;
            }
        }
    }

    @include media('<lg') {
        .map-wrapper {
            width: 100%;
            margin-right: 0px;
        }
    }

    @include media('<md') {
        & {
            display: block;
            padding: 0;
        }

        h4 {
            max-width: 200px;
        }

        .content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            margin-top: 8px;
            margin-left: 0;
        }
    }
}
</style>
