/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    list: {
        width: 16,
        height: 16,
        viewBox: '0 0 14.64 19.52',
        data:
            '<path pid="0" d="M14.64 4a.49.49 0 0 0-.14-.32L11 .13a.44.44 0 0 0-.32-.13H1a1 1 0 0 0-1 1v17.6a1 1 0 0 0 1 1h12.67a1 1 0 0 0 1-1V4zm-3.5-2.39l1.94 1.89h-1.89zm2.58 17H1V1h9.26v2.45a1 1 0 0 0 1 1h2.53z" _fill="currentColor"/><path pid="1" d="M11.36 6.25H3.27a.46.46 0 0 0 0 .92h8.09a.46.46 0 0 0 0-.92zm0 2.75H3.27a.46.46 0 0 0 0 .91h8.09a.46.46 0 1 0 0-.91zm0 2.77H3.27a.46.46 0 0 0 0 .91h8.09a.46.46 0 1 0 0-.91zm-4.04 2.76H3.27a.46.46 0 0 0 0 .91h4.05a.46.46 0 1 0 0-.91z" _fill="currentColor"/>',
    },
});
