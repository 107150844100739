<template>
    <ValidationObserver v-slot="{ handleSubmit, errors, reset }" class="contact-us" tag="div">
        <form
            ref="contactMe"
            class="form-contact"
            novalidate
            @submit.prevent="handleSubmit(submitContactForm)"
            @reset.prevent="reset"
        >
            <div v-if="phone" class="quickphone">
                <span>
                    Write us or give us a call at
                    <a id="phone-link-contact_me" :href="`tel:${phone.stripped}`">
                        {{ phone.pretty }}
                    </a>
                </span>
            </div>
            <div class="row">
                <div class="col-lg-4">
                    <ValidationProvider
                        v-slot="{ errors, ariaInput, classes }"
                        class="field-wrapper"
                        tag="div"
                        rules="required"
                        vid="name"
                        name="Full Name"
                    >
                        <input
                            id="contact-form-name"
                            ref="name"
                            v-model="contactForm.name"
                            :class="classes"
                            required
                            type="text"
                            value=""
                            placeholder=" "
                            v-bind="ariaInput"
                        />
                        <label :error="errors.length > 0" for="contact-form-name">
                            Your Full Name
                        </label>
                    </ValidationProvider>

                    <ValidationProvider
                        v-slot="{ ariaInput, classes }"
                        tag="div"
                        :rules="`${contactForm.pref === 'phone' ? 'required|' : ''}phone`"
                        name="Phone Number"
                        mode="eager"
                        vid="phone"
                        class="field-wrapper"
                    >
                        <input
                            v-bind="ariaInput"
                            id="contact-form-phone"
                            ref="phone"
                            v-model="contactForm.phone"
                            :class="classes"
                            type="text"
                            value=""
                            placeholder=" "
                        />
                        <label for="contact-form-phone">Your Phone Number</label>
                    </ValidationProvider>

                    <ValidationProvider
                        v-slot="{ ariaInput, classes }"
                        tag="div"
                        :rules="`${contactForm.pref === 'email' ? 'required|' : ''}email`"
                        vid="email"
                        name="Email Address"
                        mode="eager"
                        class="field-wrapper"
                    >
                        <input
                            v-bind="ariaInput"
                            id="contact-form-email"
                            ref="email"
                            v-model="contactForm.email"
                            class="pa11y-ignore"
                            :class="classes"
                            type="text"
                            value=""
                            placeholder=" "
                        />
                        <label :error="errors.length > 0" for="contact-form-email">
                            Your Email Address
                        </label>
                    </ValidationProvider>

                    <fieldset class="method-of-contact">
                        <legend class="form-label smaller" for="list-radios">
                            Preferred method of contact
                        </legend>
                        <ul class="list-radios">
                            <li>
                                <div class="radio">
                                    <input
                                        id="contact-form-pref-email"
                                        v-model="contactForm.pref"
                                        aria-label="1 of 2, I prefer email"
                                        type="radio"
                                        value="email"
                                        name="contactPreference"
                                    />
                                    <label
                                        class="form-label pa11y-ignore"
                                        for="contact-form-pref-email"
                                    >
                                        Email
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div class="radio">
                                    <input
                                        id="contact-form-pref-phone"
                                        v-model="contactForm.pref"
                                        aria-label="2 of 2, I prefer phone"
                                        type="radio"
                                        value="phone"
                                        name="contactPreference"
                                    />
                                    <label class="form-label" for="contact-form-pref-phone">
                                        Phone
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </fieldset>
                </div>

                <div class="col-lg-5">
                    <ValidationProvider
                        v-slot="{ ariaInput, errors, classes }"
                        rules="required"
                        tag="div"
                        name="Your Message"
                        vid="message"
                        class="field-wrapper"
                        mode="eager"
                    >
                        <textarea
                            v-bind="ariaInput"
                            id="contact-form-message"
                            ref="message"
                            v-model="contactForm.message"
                            :class="classes"
                            class="textarea pa11y-ignore"
                            placeholder=" "
                            required
                        />
                        <label :error="errors.length > 0" for="contact-form-message">
                            Your Message
                        </label>
                    </ValidationProvider>
                    <p class="content-warning">
                        When sending a note, please do not send sensitive personal details, such as
                        credit card numbers or health information.
                    </p>
                </div>

                <div class="col-lg-3">
                    <fieldset>
                        <legend class="form-label-writing">I'd like more info about</legend>

                        <ul class="list-checkboxes">
                            <ValidationProvider
                                v-slot="{ ariaInput, classes }"
                                tag="li"
                                vid="more-info"
                                name="More Info selection"
                                mode="eager"
                            >
                                <div
                                    v-for="({ id, label }, k) in more_info"
                                    :key="k"
                                    class="checkbox"
                                >
                                    <input
                                        v-bind="ariaInput"
                                        :id="`contact-form-${id}`"
                                        v-model="contactForm.more"
                                        :aria-label="`${k + 1} of ${
                                            more_info.length
                                        }, I'd like more info about ${label}`"
                                        :class="classes"
                                        :value="label"
                                        type="checkbox"
                                    />
                                    <label
                                        :for="`contact-form-${id}`"
                                        class="form-label"
                                        v-html="label"
                                    />
                                </div>
                            </ValidationProvider>
                        </ul>
                    </fieldset>

                    <div class="contactsend">
                        <button :disabled="sending" type="submit" class="btn-light">
                            <strong>{{ sending ? 'Sending...' : 'Send Email' }}</strong>
                        </button>
                    </div>
                    <p class="send-warning">
                        By clicking Send Email, you agree that the information you provide in the
                        online form may be collected, used, and disclosed by Desjardins Insurance
                        and Desjardins Agents in order to respond to your requests.
                    </p>
                </div>
            </div>
        </form>
        <div v-if="send_response" :class="{ error: response_error }" class="form-response">
            <h4 :role="response_error ? 'alert' : 'status'" v-html="send_response" />
        </div>

        <div
            v-show="Object.entries(errors).filter(([vid, [message]]) => message).length > 0"
            class="form-error"
            role="alert"
        >
            <p class="title">Please correct the following:</p>
            <!--this gets the key (vid for vee validate) which is needed for the aria references-->
            <!--err[0] is the vid-->
            <!--err[1] is an array of messages (only one by default)-->
            <template v-for="([vid, [message]], index) in Object.entries(errors)">
                <!--
                ValidationObserver.errors is an object containing reference to each field errors, each field is keyed by its vid prop.
                The field is an empty array if no errors exist for it.
                 -->
                <p
                    v-if="message"
                    :id="`vee_${vid}`"
                    :key="`v-${index}`"
                    class="error"
                    v-html="message"
                />
            </template>
        </div>
    </ValidationObserver>
</template>

<script>
import { map } from 'lodash';
import { mapGetters } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import ContactHelpers from '@/mixins/contact';
export default {
    label: 'Contact Us',
    defaultVisible: true,
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    mixins: [ContactHelpers],
    change: {
        type: 'ContactMe',
        hasEditor: false,
        hasHelp: true,
    },

    computed: {
        ...mapGetters(['office', 'phone', 'agentProducts']),

        more_info() {
            const whatCanBeShown = [
                'Auto Insurance',
                'Business Insurance',
                'Home & Property Insurance',
                'Life Insurance',
                'Living Benefits',
                'Other',
            ];
            if (this.agentProducts.includes('Mutual Funds')) {
                // Put "Mutual Funds" INTO the whatCanBeShown array if the agent is allowed to sell them.
                whatCanBeShown.splice(-1, 0, 'Mutual Funds');
            }
            const productIdsAndLabels = map(whatCanBeShown, (value) => {
                return { id: value.replace(/[^0-9a-zA-Z]/g, ''), label: value };
            });
            return productIdsAndLabels;
        },
    },
};
</script>

<style lang="scss" scoped>
.quickphone {
    display: flex;
    justify-content: space-between;
    margin-top: -20px;
    margin-bottom: 25px;
    font-size: 16px;
    font-style: italic;
    color: $black;

    a {
        color: $green;

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
}

form {
    margin-top: 20px;

    label {
        font-size: 14px;
        line-height: 1;
    }

    .field-wrapper {
        position: relative;

        label {
            position: absolute;
            top: 25px;
            left: 10px;
            width: 100%;
            font-size: 17px;
            color: lighten($dark, 30%);
            transition: top 0.1s ease, left 0.1s ease;
        }
    }

    input,
    textarea {
        @mixin shoved-label {
            top: -11px;
            left: 0;
            color: $black;
        }

        &:focus + label {
            @include shoved-label;
        }

        &:not(:-ms-input-placeholder) + label {
            @include shoved-label;
        }

        &:not(:placeholder-shown) + label {
            @include shoved-label;
        }

        /* MS Edge (pre chrome) doesnt support ANYTHING */
        &.dirty + label {
            @include shoved-label;
        }
    }

    input {
        padding: 10px;
        width: 100%;
        border: 1px solid #888;
        border-radius: 6px;
        font-size: 17px;
        margin: 15px 0px 25px 0px;

        @include media('<lg') {
            font-size: 14px;
        }
    }
    input,
    textarea {
        &.validated.invalid {
            &,
            &[type='checkbox'] + label {
                border: 1px solid red;
            }
        }
    }

    fieldset {
        border: none;
        margin-left: 0;
        margin-right: 0;
        padding: 0;

        legend {
            font-size: 14px;
        }
    }

    .form-label {
        font-size: 17px;
        margin-bottom: 7px;
        display: inline-block;

        &.smaller {
            font-size: 14px;
        }
    }

    .content-warning {
        padding-bottom: 6px;
        font-style: italic;
        font-size: 14px;
    }
    .send-warning {
        padding-top: 12px;
        font-style: italic;
        font-size: 14px;
    }

    .method-of-contact {
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .field {
        border: 1px solid $green;
        box-shadow: none;
        font-size: 19px;
        padding: 10px 13px;
        width: 100%;

        @include media('<=md') {
            padding: 9px 7px;
            font-size: 16px;
        }
    }

    .select,
    .textarea,
    .field {
        -webkit-appearance: none;
    }
    .list-radios {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        padding-top: 8px;

        li {
            margin-right: 50px;
        }

        li + li {
            margin-right: 0px;
        }
    }

    .textarea {
        resize: none;
        border: 1px solid #888;
        border-radius: 6px;
        padding: 10px;
        margin: 15px 0px;
        box-shadow: none;
        width: 100%;
        height: 250px;
        font-size: 17px;

        @include media('<lg') {
            font-size: 14px;
        }

        @include media('<=md') {
            height: 175px;
        }
    }

    .radio {
        display: flex;
        align-items: center;

        .form-label {
            text-transform: none;
            font-size: 16px;
            position: relative;
            padding-left: 30px;
            padding-top: 3px;

            &:before {
                content: '';
                width: 23px;
                height: 23px;
                border: 2px solid $green;
                display: inline-block;
                border-radius: 50%;
                position: absolute;
                top: 0;
                left: 0;
            }

            &:hover:before {
                border: 2px solid #888;
            }
        }

        position: relative;
        input {
            appearance: none;
            position: absolute;
            padding: 0;
            margin: 0;
            z-index: -1;
            height: 0;
            overflow: hidden;
            width: 0;
            top: 0;
            left: 0;
        }

        input:checked + .form-label:before {
            background: $green;
            box-shadow: inset 0 0 0 2px #fff;
        }
    }

    /* Checkbox styles */
    .list-checkboxes {
        list-style: none;
        padding-top: 13px;

        li + li {
            margin-top: 5px;
        }

        .checkbox {
            display: flex;
            align-items: center;

            .form-label {
                text-transform: none;
                font-size: 15px;
                position: relative;
                padding-left: 33px;
                padding-top: 3px;

                &:before {
                    content: '';
                    color: #fff;
                    width: 22px;
                    height: 22px;
                    border: 1px solid #888;
                    display: inline-block;
                    border-radius: 3px;
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                &:hover:before {
                    border: 1px solid #888;
                }
            }

            input:checked + .form-label:before {
                background: $green;
                content: '✔';
                padding-top: 2px;
                text-align: center;
            }

            input.error + .form-label:before {
                border: 1px solid #900;
            }

            input {
                //hide the input so it doesn't affect the layout/focus ring
                position: absolute;
                height: 0;
                width: 0;
                overflow: hidden;
                z-index: -1;

                //retain a visible focus style even thought the input is hidden
                &:focus + label {
                    outline: #51cbee solid 3px !important;
                }
            }
        }
    }

    .contactsend {
        @include media('<=md') {
            padding-top: 30px;
        }
    }

    .stretch {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.form-response {
    padding: 10px 0;
    margin-top: 20px;

    h4 {
        text-align: center;
    }

    &.error {
        border: 1px solid $danger;
        background-color: $danger-background;
        color: $danger;

        h4 {
            text-align: center;
            color: $danger;
        }
    }
}

.form-error {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: $danger-background;
    color: $red;
    border: 1px solid $danger;

    .title {
        font-size: 18px;
        margin-bottom: 5px;
        color: $danger;
    }

    .error {
        color: $danger;
        margin-left: 20px;
        display: list-item;
        list-style: disc outside none;
    }
}
</style>
