/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    clock: {
        width: 16,
        height: 16,
        viewBox: '0 0 19.36 19.36',
        data:
            '<path pid="0" d="M9.68 19.36a9.68 9.68 0 1 1 9.68-9.68 9.69 9.69 0 0 1-9.68 9.68zM9.68 1a8.66 8.66 0 1 0 8.66 8.66A8.68 8.68 0 0 0 9.68 1z" _fill="currentColor"/><path pid="1" d="M9.64 3.3v6.48m0 0l2.86 2.83" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25"/>',
    },
});
