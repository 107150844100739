<template>
    <div id="contact-us" class="tabs">
        <div class="head hidden-xs">
            <ul role="tablist" class="nav" aria-label="Sections" aria-orientation="vertical">
                <li
                    v-for="(tabItem, index) in visibleTabItems"
                    :id="`tab-${tabItem.component}`"
                    :key="index"
                    :aria-selected="`${tabItem.hash === activeTab}`"
                    :aria-controls="`panel-${tabItem.component}`"
                    :tabindex="`${tabItem.hash === activeTab ? 0 : -1}`"
                    role="tab"
                    @focus="() => navIfNeeded(tabItem.hash)"
                    @click="() => navIfNeeded(tabItem.hash)"
                    @keyup.enter="() => navIfNeeded(tabItem.hash)"
                    @keyup.space="() => navIfNeeded(tabItem.hash)"
                    @keydown.left="goBack"
                    @keydown.right="goNext"
                >
                    <div class="button" :class="{ active: activeTab === tabItem.hash }">
                        <svgicon
                            :icon="tabItem.icon.name"
                            :width="tabItem.icon.width"
                            :height="tabItem.icon.height"
                            :original="true"
                            class="svg-max-width"
                        />
                        <strong>
                            {{ tabItem.title }}
                            <ChangeBtn
                                v-if="tabItem.edit || tabItem.help"
                                :has-editor="!tabItem.help"
                                :type="tabItem.edit ? tabItem.edit : tabItem.help"
                            />
                        </strong>
                    </div>
                </li>
            </ul>
        </div>
        <section class="content">
            <component
                :is="tabItem.component"
                v-for="(tabItem, index) in visibleTabItems"
                :id="`panel-${tabItem.component}`"
                :key="index"
                :class="{ visible: activeTab === tabItem.hash }"
                :aria-labelledby="`tab-${tabItem.component}`"
                :tabindex="0"
                class="panel"
                role="tabpanel"
            />
        </section>
    </div>
</template>

<script>
import Contact from '@/components/SectionTabs/Contact.vue';
import Hiring from '@/components/SectionTabs/Hiring.vue';
import Mission from '@/components/SectionTabs/Mission.vue';
import Team from '@/components/SectionTabs/Team.vue';
import { filter, first, get as _get, isFunction, last, includes, map } from 'lodash';
import { mapGetters } from 'vuex';

export default {
    name: 'Tabs',
    components: {
        Contact,
        Hiring,
        Mission,
        Team,
    },
    data() {
        return {
            tabClicked: false,
            activeTab: '#contact-us',
            tabItems: [
                {
                    component: 'Contact',
                    title: 'Contact Us',
                    hash: '#contact-us',
                    target: 'tab-1',
                    icon: {
                        name: 'envelope',
                        width: '3.4rem',
                        height: '3.4rem',
                    },
                    help: 'ContactMe',
                },
                {
                    component: 'Mission',
                    title: 'Our Mission',
                    hash: '#our-mission',
                    target: 'tab-2',
                    icon: {
                        name: 'flag',
                        width: '3.6rem',
                        height: '3.1rem',
                    },
                    edit: 'Mission',
                },
                {
                    component: 'Team',
                    title: 'Our Team',
                    hash: '#our-team',
                    isActive: true,
                    target: 'tab-3',
                    icon: {
                        name: 'figures',
                        width: '4.5rem',
                        height: '3.5rem',
                    },
                    edit: 'Team',
                },
                {
                    component: 'Hiring',
                    title: 'Now Hiring',
                    hash: '#hiring',
                    target: 'tab-4',
                    icon: {
                        name: 'figures-magnifier',
                        width: '3.8rem',
                        height: '3.5rem',
                    },
                    help: 'Jobs',
                },
            ],
        };
    },
    computed: {
        ...mapGetters(['ui', 'editor', 'team', 'office']),
        visibleTabItems() {
            return filter(this.tabItems, this.tabVisible);
        },
        tabHashes() {
            return map(this.tabItems, (tabItem) => tabItem.hash);
        },
    },
    watch: {
        $route: function () {
            const { hash } = this.$route;
            if (includes(this.tabHashes, hash)) {
                this.activeTab = hash;
            }
        },
    },
    methods: {
        navIfNeeded(newHash) {
            const currentHash = this.$route.hash;
            if (newHash !== currentHash) {
                this.$router.replace({
                    path: this.$route.path,
                    query: this.$route.query,
                    hash: newHash,
                });
            }
            this.tabClicked = true;
        },
        goBack(evt) {
            evt.preventDefault();
            const prevSibling = evt.target.previousSibling;

            if (prevSibling && isFunction(prevSibling.focus)) {
                prevSibling.focus();
            } else {
                last(evt.target.parentElement.children).focus();
            }
        },
        goNext(evt) {
            evt.preventDefault();
            const nextSibling = evt.target.nextSibling;

            if (nextSibling && isFunction(nextSibling.focus)) {
                nextSibling.focus();
            } else {
                first(evt.target.parentElement.children).focus();
            }
        },
        tabVisible(tabItem) {
            const editorName = _get(tabItem, 'edit', '');
            const jobs = this.office.jobs;
            const teamMembers = _get(this.team, 'members', null);

            if (tabItem.title === 'Now Hiring' && !this.editor.allowed) {
                if (!jobs || jobs.length === 0) {
                    return false;
                }
            }
            if (tabItem.title === 'Our Mission' && !this.editor.allowed) {
                if (this.office.mission === null) {
                    return false;
                }
            }
            if (tabItem.title === 'Our Team' && !this.editor.allowed) {
                if (!teamMembers || teamMembers.filter((t) => t.visible).length === 0) {
                    return false;
                }
            }

            return (
                this.editor.allowed ||
                _get(this.ui, `tabs.${editorName.toLowerCase()}.visible`, true)
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.tabs {
    @include media('>md') {
        border-radius: 12px;
    }

    .head {
        flex-shrink: 0;
        @include media('>=md') {
            width: 200px;
        }
        @include media('>=lg') {
            width: 225px;
        }
    }

    .nav {
        display: flex;
        list-style-type: none;

        .button {
            position: relative;
            display: flex;
            align-items: center;
            margin-right: 15px;
            height: 58px;
            padding: 5px 15px;
            text-align: left;
            font-weight: 800;
            font-size: 18px;
            cursor: pointer;
            border-radius: 8px 8px 0 0;
            background: $gray;

            &:hover {
                background: $green;
                color: #fff;
            }

            @include media('>md') {
                transition: all 0.3s;
            }

            @include media('>lg') {
                padding: 5px 24px;
            }

            strong {
                align-items: center;
                display: flex;
                flex-wrap: wrap;
                white-space: nowrap;
            }
        }

        span {
            position: relative;
            display: inline-block;
            min-width: 45px;
            margin-right: 6px;
            text-align: center;
        }

        .active,
        button {
            background: #fff;
            color: $green;
            border-radius: 8px 8px 0 0;
            border-top: 4px solid green;
            box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.18);
            z-index: 0;
            &:hover {
                background: #fff;
                color: $green;
            }
        }

        .translate-right {
            transform: translateX(16px);
        }
    }

    .content {
        width: 100%;
        background: #fff;
        border-radius: 0 10px 10px 10px;

        @include media('>md') {
            box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.18);
            transition: all 0.1s ease;
            padding: 20px;
            position: relative;
            z-index: 1;
        }
    }

    svg {
        margin-right: 10px;
    }

    .panel {
        display: none;
        &.visible {
            display: block;
        }

        @include media('<md') {
            display: block !important;
        }
    }
}
</style>
