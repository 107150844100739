<template>
    <div class="mobile-links">
        <p v-if="showAdjusto" class="download-now">
            Get the Desjardins Insurance app. You can file a claim, manage your insurance and get
            unique prevention tools like Radar and Ajusto.
        </p>
        <p v-else class="download-now">
            Download the Desjardins Insurance App today. You can file a claim, update your policy
            and take advantage of our unique savings and prevention programs.
        </p>
        <div class="links">
            <a
                :id="`${inSectionPanel ? 'panel-' : ''}desjardins-mobile-app-store`"
                target="_blank"
                aria-describedby="new-window-warning"
                rel="noopener noreferrer"
                href="https://apps.apple.com/ca/app/ajustodesjardinsassurances/id958825069?ct=lf457801&l=en_ca"
            >
                <img class="lazyload" :data-src="appstoreimg" alt="apple app store" />
            </a>
            <a
                :id="`${inSectionPanel ? 'panel-' : ''}desjardins-mobile-play-store`"
                target="_blank"
                aria-describedby="new-window-warning"
                rel="noopener noreferrer"
                href="https://play.google.com/store/apps/details?id=ca.dgag.ajusto&hl=en_CA&referrer=utm_source%3Ddesjardinsgeneralinsurance.com%26utm_medium%3Dreferral%26utm_campaign%3Dlf457801"
            >
                <img class="lazyload" :data-src="googleplayimg" alt="Google play store" />
            </a>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import googleplayimg from '@/assets/img/play-store.png';
import appstoreimg from '@/assets/img/app-store.png';

export default {
    props: {
        inSectionPanel: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            googleplayimg,
            appstoreimg,
        };
    },
    computed: {
        ...mapGetters(['office']),
        showAdjusto() {
            return this.office.provinceSettings?.showAdjusto;
        },
    },
};
</script>

<style lang="scss" scoped>
.mobile-links {
    display: flex;
    flex-direction: column;

    .download-now {
        margin: 0;
    }

    .links {
        display: flex;
        justify-content: left;
        align-items: left;
        margin: 5px 0px;

        a + a {
            margin-left: 15px;
        }

        img {
            margin: 0;
            width: 150px;
        }
    }
}
</style>
