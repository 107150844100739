/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    hex: {
        width: 16,
        height: 16,
        viewBox: '0 0 36 42',
        data:
            '<path pid="0" d="M30.791 13.064v14.993l-12.984 7.497-12.985-7.497V13.064l12.985-7.497 12.984 7.497zM0 10.28v20.561l17.807 10.28 17.805-10.28V10.28L17.807 0 0 10.28z" _fill="#2c8e54" fill-rule="nonzero"/>',
    },
});
