<template>
    <section class="quote">
        <div class="content">
            <h2 class="heading">Get a Quote</h2>
            <div class="form">
                <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(submitQuoteRequest)">
                        <div class="form__inner">
                            <div class="row small-gutter fields">
                                <div class="col-12 col-md-5 small-offset">
                                    <div class="form__row">
                                        <label for="field-lob" class="form__label">
                                            Insurance Type
                                        </label>
                                        <div class="form__controls">
                                            <ValidationProvider
                                                v-slot="{ errors, ariaMsg, ariaInput }"
                                                rules="quoteType"
                                                mode="eager"
                                                tag="div"
                                                class="select"
                                            >
                                                <span class="error" v-bind="ariaMsg">
                                                    {{ errors[0] }}
                                                </span>
                                                <select
                                                    id="field-lob"
                                                    v-model="quoteForm.lob"
                                                    name="field-lob"
                                                    v-bind="ariaInput"
                                                    :class="{ hasError: errors.length > 0 }"
                                                >
                                                    <option
                                                        v-for="(
                                                            option, index
                                                        ) in office.quoteOptions"
                                                        :key="index"
                                                        :value="option[1]"
                                                    >
                                                        {{ option[0] }}
                                                    </option>
                                                </select>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-7 col-md-4 small-offset">
                                    <div class="form__row">
                                        <label class="form__label" for="field-postalcode">
                                            Postal Code
                                        </label>

                                        <ValidationProvider
                                            v-slot="{ errors, ariaMsg, ariaInput }"
                                            rules="required|validPostalCode"
                                            mode="eager"
                                            name="Postal Code"
                                            tag="div"
                                            class="form__controls"
                                        >
                                            <span class="error" v-bind="ariaMsg">
                                                {{ errors[0] }}
                                            </span>
                                            <input
                                                v-bind="ariaInput"
                                                id="field-postalcode"
                                                v-model="quoteForm.postal"
                                                aria-label="postal code"
                                                class="field pa11y-ignore"
                                                :class="{ hasError: errors.length > 0 }"
                                                name="field-postalcode"
                                                type="text"
                                                value=""
                                            />
                                        </ValidationProvider>
                                    </div>
                                </div>

                                <div class="get-quote-button col-5 col-md-3 small-offset flex">
                                    <button aria-label="start quote" class="btn-gaq" type="submit">
                                        Get Quote
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </div>
    </section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';

import { mapGetters } from 'vuex';
import QuoteHelpers from '@/mixins/quote';

export default {
    name: 'Quote',
    components: { ValidationProvider, ValidationObserver },
    mixins: [QuoteHelpers],
    computed: {
        ...mapGetters(['office']),
    },
};
</script>

<style lang="scss" scoped>
input,
select,
button {
    border-radius: 6px;
}
.quote {
    padding: 22px;
    background: $green;
    color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);

    @include media('<lg') {
        padding: 22px 12px 12px 22px;
    }

    .get-quote-button {
        max-width: 100%;
    }

    .content {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .heading {
        font-size: 2.4rem;
        margin-bottom: 0;
        order: -2;
        color: #fff;
    }
    .quote-error {
        float: right;
        color: #fff;
    }

    .form {
        padding-top: 12px;
        width: 100%;
        .default {
            color: $gray;
        }
        .fields {
            align-items: flex-end;
            display: flex;
        }
        .field {
            &::placeholder {
                color: $dark-gray;
            }
        }
    }

    .list-items {
        order: -1;
        padding: 2px 4px;
    }

    @include media('<lg') {
        & {
            padding: 12px;
        }

        .form {
            padding: 0;
        }

        .list-items {
            order: initial;
            padding: 0;
        }
    }

    @include media('<md') {
        & {
            padding: 15px;
        }

        .form {
            padding: 13px 0;
        }

        .col-12 {
            margin-bottom: 15px;
        }
        box-shadow: none;
        border-radius: 0;
    }
    .hasError {
        border: 1px solid red;
    }
}
</style>
