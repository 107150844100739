/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    flag: {
        width: 16,
        height: 16,
        viewBox: '0 0 35.74 30.03',
        data:
            '<path pid="0" d="M22.53.89a.89.89 0 0 0-.89-.89h-9.43a.89.89 0 0 0-.89.89v5.66a.89.89 0 0 0 .89.89h8.54V10l-8.54 8.5L10 16.3a.89.89 0 0 0-1.36.12L.16 28.63A.89.89 0 0 0 .89 30h34a.89.89 0 0 0 .69-1.45L22.53 10zM13.1 5.66V1.78h7.65v3.88zM2.59 28.25l6.91-9.94 2.08 2.08a.86.86 0 0 0 .63.26.89.89 0 0 0 .63-.26l8.68-8.69 11.61 16.55z" _fill="currentColor"/>',
    },
});
