const BaseBtn = () => import(/* webpackChunkName: "Btn" */ './BaseBtn.vue');
const EditBtn = () => import(/* webpackChunkName: "EditBtn" */ './EditBtn.vue');
const ChangeBtn = () => import(/* webpackChunkName: "ChangeBtn" */ './ChangeBtn.vue');
const EditSwitch = () => import(/* webpackChunkName: "EditSwitch" */ './EditSwitch.vue');

const plugin = {
    install: function (Vue) {
        Vue.component('BaseBtn', BaseBtn);
        Vue.component('EditBtn', EditBtn);
        Vue.component('ChangeBtn', ChangeBtn);
        Vue.component('EditSwitch', EditSwitch);

        Vue.mixin({
            computed: {
                editingAllowed() {
                    return this.$store?.getters?.editor?.allowed;
                },
            },
        });
    },
};

export default plugin;
