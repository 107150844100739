<template>
    <div>
        <PageHeader :show-log-on-btn="false" />
        <div class="quote-page">
            <img
                v-for="product in products"
                v-show="activeTab === product.name.toLowerCase()"
                :key="product.name"
                :data-src="product.img"
                :alt="`banner image for ${product.name}`"
                class="lazyload banner"
            />

            <div class="blocks">
                <div class="block quote">
                    <ul class="tab-headings" role="tablist">
                        <li
                            v-for="product in products"
                            :key="product.name"
                            :class="{ active: activeTab === product.name.toLowerCase() }"
                            class="tab-heading"
                            role="tab"
                            tabindex="0"
                            @click="setActiveTab(product.name.toLowerCase())"
                            @keyup.enter="setActiveTab(product.name.toLowerCase())"
                            @keyup.space="setActiveTab(product.name.toLowerCase())"
                        >
                            <svgicon
                                :color="
                                    activeTab === product.name.toLowerCase() ? '#00884e' : '#fff'
                                "
                                :icon="product.icon"
                                height="30"
                            />
                            <h3>{{ product.name }}</h3>
                        </li>
                    </ul>

                    <ValidationObserver v-slot="{ handleSubmit }" tag="div">
                        <form @submit.prevent="handleSubmit(submitQuoteRequest)">
                            <ValidationProvider
                                v-slot="{ ariaMsg, errors, ariaInput }"
                                name="Postal Code"
                                rules="required|validPostalCode"
                                tag="span"
                                mode="eager"
                                class="tab-body"
                            >
                                <h2
                                    v-for="product in products"
                                    v-show="activeTab === product.name.toLowerCase()"
                                    :key="product.name"
                                >
                                    Start a{{ product.name === 'Auto' ? `n ` : `` }}
                                    {{ product.name }} Quote:
                                </h2>
                                <span v-if="errors.length > 0" class="quote-error" v-bind="ariaMsg">
                                    {{ errors[0] }}
                                </span>
                                <label class="label" for="field-postalcode">Postal Code</label>
                                <input
                                    id="field-postalcode"
                                    v-model="quoteForm.postal"
                                    v-bind="ariaInput"
                                    class="field pa11y-ignore"
                                    :class="{ hasError: errors.length > 0 }"
                                    name="field-postalcode"
                                    type="text"
                                    value
                                />

                                <button class="btn btn-green" type="submit">Start Quote</button>
                            </ValidationProvider>
                        </form>
                    </ValidationObserver>
                </div>
                <div class="block profile">
                    <div class="agent-info">
                        <img
                            class="lazyload"
                            :alt="agent.names.PreferredName"
                            :data-src="agent.image.profile"
                        />
                        <div class="contact">
                            <a :href="`tel:${phone.stripped}`" class="phone">
                                <svgicon color="#00884e" height="30" icon="phone" />
                                {{ phone.pretty }}
                            </a>
                            <h1>{{ agent.names.PreferredName }}</h1>
                            <p>
                                Desjardins Insurance Agent,
                                <span>
                                    {{ office.physicalAddress.street1 }}
                                    <br />
                                    <span v-if="office.physicalAddress.street2">
                                        {{ office.physicalAddress.street2 }}
                                        <br />
                                    </span>
                                    {{ office.physicalAddress.municipality }},
                                    {{ office.physicalAddress.provinceCode }}
                                    {{ office.physicalAddress.postalCode }}
                                </span>
                                <a :href="`https://${meta.domain}/`">
                                    <svgicon :original="true" height="25" icon="click" />
                                    {{ meta.domain }}
                                </a>
                            </p>
                        </div>
                    </div>
                    <ValidationObserver v-slot="{ errors, handleSubmit, reset }" slim>
                        <form
                            ref="contactMe"
                            class="contact-form"
                            @submit.prevent="handleSubmit(sendMessage)"
                            @reset.prevent="reset"
                        >
                            <h4>Send a message to {{ agent.names.PreferredName.split(' ')[0] }}</h4>
                            <ValidationProvider
                                v-slot="{ ariaInput, errors, ariaMsg }"
                                name="Your Name"
                                rules="required"
                                mode="eager"
                                class="inputWrapper"
                            >
                                <input
                                    v-model="contactForm.name"
                                    v-bind="ariaInput"
                                    aria-label="Your name"
                                    placeholder="Your Name"
                                    type="text"
                                    :class="{ hasError: errors.length > 0 }"
                                />
                                <span v-bind="ariaMsg">{{ errors[0] }}</span>
                            </ValidationProvider>
                            <ValidationProvider
                                v-slot="{ ariaInput, errors, ariaMsg }"
                                name="Contact Info"
                                rules="required|contact"
                                mode="eager"
                                class="inputWrapper"
                            >
                                <input
                                    v-model="contactForm.contact"
                                    v-bind="ariaInput"
                                    aria-label="Your email or phone"
                                    placeholder="Your Email or Phone"
                                    type="text"
                                    :class="{ hasError: errors.length > 0 }"
                                    class="pa11y-ignore"
                                />
                                <span v-bind="ariaMsg">{{ errors[0] }}</span>
                            </ValidationProvider>
                            <ValidationProvider
                                v-slot="{ ariaInput, errors, ariaMsg }"
                                name="Your Message"
                                rules="required"
                                mode="eager"
                                class="inputWrapper textarea"
                            >
                                <textarea
                                    v-model="contactForm.message"
                                    v-bind="ariaInput"
                                    aria-label="message"
                                    placeholder="Your Message"
                                    :class="{ hasError: errors.length > 0 }"
                                    class="pa11y-ignore"
                                />
                                <span v-bind="ariaMsg">{{ errors[0] }}</span>
                            </ValidationProvider>
                            <button
                                :disabled="
                                    sending || (submit_attempted && !validated) || errors.length > 0
                                "
                                class="btn-green"
                                type="submit"
                            >
                                Send Message
                            </button>
                            <div v-if="send_response" aria-live="polite" class="form-response">
                                <h3 v-html="send_response" />
                            </div>
                            <!--                            <div class="form-error" v-if="errors.length > 0">-->
                            <!--                                <h4 aria-live="polite">Please correct the following:</h4>-->
                            <!--                                <ul>-->
                            <!--                                    <li-->
                            <!--                                        v-for="(err, index) in errors"-->
                            <!--                                        v-html="err[1]"-->
                            <!--                                        :id="`error${err[0]}`"-->
                            <!--                                        :key="index"-->
                            <!--                                        aria-live="polite"-->
                            <!--                                        class="error"-->
                            <!--                                    />-->
                            <!--                                </ul>-->
                            <!--                            </div>-->
                        </form>
                    </ValidationObserver>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import _ from 'lodash';
import PageHeader from '@/components/Layout/PageHeader.vue';
import axios from 'axios';
import QuoteHelpers from '@/mixins/quote';
import Contact from '@/mixins/contact';
import auto from '@/assets/img/auto.jpg';
import home from '@/assets/img/home.jpg';
import tenants from '@/assets/img/tenants.jpg';
import condo from '@/assets/img/condo.jpg';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
    components: { PageHeader, ValidationObserver, ValidationProvider },
    mixins: [QuoteHelpers, Contact],
    data: () => ({
        activeTab: 'Auto',
        errors: [],
        sending: false,
        send_response: null,
        products: [
            { code: 'au_qq', icon: 'car', img: auto, name: 'Auto' },
            { code: 'po', icon: 'house', img: home, name: 'Home' },
            { code: 'co', icon: 'condo', img: condo, name: 'Condo' },
            { code: 'lo', icon: 'tenant', img: tenants, name: 'Tenants' },
        ],
    }),
    computed: {
        currentImg() {
            return `@/assets/img/car.jpg`;
        },
    },
    mounted() {
        this.setActiveTab(this.$route.params.product);
    },
    methods: {
        reset() {
            this.contactForm = {
                name: null,
                contact: null,
                message: null,
            };
            this.$refs.contactMe?.reset();
        },
        responseMessage(msg, reset) {
            this.send_response = msg;
            if (msg) {
                setTimeout(() => {
                    this.send_response = null;
                }, 6000);
            }

            if (reset) {
                this.sending = false;
                this.submit_attempted = false;
                this.reset();
            }
        },
        sendMessage() {
            this.submit_attempted = true;

            const data = { ..._.omit(this.contactForm, 'contact') };
            this.sending = true;
            this.send_response = 'Sending... please wait...';

            this.$keen('contactform', data);

            axios({
                method: 'post',
                url: '/contact',
                data,
            })
                .then((response) => {
                    if (response.data.success) {
                        this.responseMessage(
                            'Thank you! Your message was sent successfully.',
                            true,
                        );
                    } else {
                        this.responseMessage(
                            "We're sorry, there was an error sending your message.  Please try again at a later time.",
                        );
                    }
                })
                .catch((err) => {
                    console.error(err);
                    this.responseMessage(
                        "We're sorry, there was an error sending your message.  Please try again at a later time.",
                    );
                });
        },
        setActiveTab(tab) {
            if (this.products.filter((product) => product.name.toLowerCase() === tab).length < 1) {
                tab = 'auto';
            }
            this.activeTab = tab;
            this.quoteForm.lob = this.products.find((p) => p.name.toLowerCase() === tab).code;
            this.$router.push({
                name: 'quote',
                params: { product: tab },
                query: this.$route.query,
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.quote-page {
    padding: 0 40px 40px 40px;
    margin: auto;
    max-width: 1200px;
    text-align: left;

    @include media('<md') {
        padding: 0;
    }

    h1 {
        font-size: 3rem;
    }
    img {
        width: 100%;

        &.banner {
            /*object-fit: cover;*/
        }
    }
    input,
    textarea {
        color: $green;
        border: 1px solid $green;
        font-size: 18px;
        margin-bottom: 5px;
        padding: 10px;
        border-radius: 6px;

        &::placeholder {
            color: $green;
        }
    }
    .blocks {
        display: flex;
        justify-content: space-between;
        margin-top: 40px;

        .block {
            // border: 1px solid red;
            width: 48%;
            @include media('<md') {
                margin: 5px 0;
                width: 100%;
                border-radius: 0;
            }
            border-radius: 0 0 12px 12px;
        }

        @include media('<md') {
            flex-direction: column;
            margin: 10px 0;
        }
    }

    .quote {
        background-color: $gray;

        .tab-headings {
            background-color: #fff;
            display: flex;
            justify-content: space-between;
            list-style: none;

            .tab-heading {
                background-color: $green;
                color: #fff;
                cursor: pointer;
                padding: 15px 0;
                text-align: center;
                width: 24%;
                border-radius: 12px 12px 0 0;
                transition: all 0.3s;

                h3 {
                    font-weight: normal;
                }

                &:hover {
                    background-color: $dark-green;
                }

                &.active {
                    background-color: $gray;
                    color: $green;
                }
            }
        }

        .tab-body {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 50px;

            h2 {
                font-size: 2.1rem;
            }

            input {
                margin-bottom: 25px;
                width: 80%;
                &.hasError {
                    border: 1px solid red;
                }
            }
            .label {
                display: block;
                text-align: center;
                font-size: 18px;
                line-height: 1;
                margin-bottom: 5px;
            }
        }
    }

    .profile {
        .agent-info {
            align-items: flex-start;
            display: flex;
            @include media('<md') {
                align-items: center;
                flex-direction: column;
            }
            img {
                margin-right: 20px;
                width: 200px;
                border-radius: 12px;
                @include media('<md') {
                    margin: 10px 0 10px 0;
                }
            }
            .contact {
                a {
                    color: $green;
                    font-size: 22px;
                    text-decoration: none;
                    &.phone {
                        font-size: 1.9rem;
                    }
                }
                h1 {
                    color: $black;
                    font-size: 2.5rem;
                    font-weight: bold;
                    margin: 0;
                    margin-top: 12px;
                }
                p {
                    font-size: 18px;

                    span {
                        display: block;
                        margin-bottom: 20px;
                    }
                }
            }
        }
        .contact-form {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            @include media('<md') {
                padding: 10px;
            }
            .hasError {
                border: 1px solid #900;
            }
            button {
                margin: 5px auto;
            }
            h4 {
                width: 100%;
                color: $green;
                font-size: 24px;
                font-weight: bold;
                margin: 20px 0 5px 0;
            }
            .inputWrapper {
                width: 48%;
                &.textarea {
                    width: 100%;
                }
                input {
                    width: 100%;
                    border-radius: 6px;
                }
                &:first-of-type {
                    margin-right: 4%;
                }
            }
            textarea {
                width: 100%;
                border-radius: 6px;
            }
        }
    }
}
</style>
