<template>
    <div v-if="editingAllowed" class="change-btn-group">
        <button
            v-if="hasEditor"
            :class="{ 'square-right': hasHelp }"
            class="edit-btn"
            @click="showEditor(type, context, false)"
        >
            <svgicon :icon="editIcon" color="white" height="13" width="11" />
            {{ editText }}
        </button>
        <button
            v-if="hasHelp"
            :class="{ 'square-left': hasEditor }"
            class="help-btn"
            @click="showEditor(type, context, true)"
        >
            {{ helpText }}
            <svgicon :icon="helpIcon" color="white" height="13" width="13" />
        </button>
    </div>
</template>

<script>
export default {
    props: {
        type: { type: String, default: null },
        editIcon: { type: String, default: 'pencil' },
        editText: { type: String, default: 'Edit' },
        helpIcon: { type: String, default: 'help' },
        helpText: { type: String, default: 'Help' },
        hasHelp: { type: Boolean, default: true },
        hasEditor: { type: Boolean, default: false },
        context: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
    computed: {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.change-btn-group {
    z-index: 10;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 3px 8px 0px rgba(0, 0, 0, 0.25);
    display: inline-flex;
    button {
        cursor: pointer; /* Pointer/hand icon */
        font-size: 12px;
        white-space: nowrap;
        font-weight: normal;
        color: #fff;
        padding: 2px 8px;
        text-decoration: none;
        align-items: center;
        border: none;
        text-align: center;

        &:not(:last-child) {
            // border-right: none; /* Prevent double borders */
        }

        i.c1-icon {
            margin-right: 0;
            @include media('<=sm') {
                display: none;
            }
        }

        @include media('<=md') {
            font-size: 10px;
        }
    }

    .edit-btn {
        background-color: $yellow;

        &.square-right {
            /* // border-radius: 4px 0 0 4px; */
            /* // border-right  : 1px solid darken( $orange, 5% ); */
        }

        &:hover {
            color: #fff;
            background-color: $light-yellow;
        }
    }

    .help-btn {
        background-color: $blue;
        color: #fff;

        &.square-left {
            /* // border-radius: 0 4px 4px 0; */
            /* // border-left: 1px solid lighten($blue, 10%); */
        }

        &:hover {
            /* // color:#fff; */
            background-color: lighten($blue, 10%);
        }
    }
}
</style>
