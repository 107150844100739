import Vue from 'vue';
import Vuex from 'vuex';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import _ from 'lodash';

Vue.use(Vuex);

export function createStore(state) {
    // console.log('creating a store!');

    let defaults = {
        ui: {
            review: {
                visible: true,
            },
        },
    };

    return new Vuex.Store({
        state: _.extend(
            {
                ...defaults,
                apiHost: process.env.VUE_APP_API_HOST,
                baseUrl: process.env.BASE_URL,
                editor: {},
                publicPath: process.env.BASE_URL,
            },
            state,
        ),
        getters,
        mutations,
        actions,
    });
}
