<template>
    <div class="section-tabs">
        <div class="container">
            <Tabs />
        </div>
    </div>
</template>

<script>
import Tabs from '@/components/SectionTabs/Tabs.vue';

export default {
    name: 'SectionTabs',
    components: {
        Tabs,
    },
};
</script>

<style lang="scss" scoped>
.container {
    width: 100%;

    .mobile-panel {
        margin-top: 1px;
    }
}
.section-tabs {
    padding: 10px 0 32px;
    background: $light-gray;

    @include media('<md') {
        & {
            padding: 1px 0 20px;
            background: white;
        }
    }
}
</style>
