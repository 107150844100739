/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    envelope: {
        width: 16,
        height: 16,
        viewBox: '0 0 20.79 20.96',
        data:
            '<path pid="0" d="M20.77 8.61a.55.55 0 0 0-.16-.38c-.3-.27-7.1-6.57-8.25-7.46a2.9 2.9 0 0 0-4 0C7.24 1.84.24 8.05.17 8.11A.52.52 0 0 0 0 8.5v11.39A1.12 1.12 0 0 0 1.23 21h18.42a1.19 1.19 0 0 0 1.14-1.23c0-.72-.02-11.16-.02-11.16zm-6.24 2.65H6.37a.52.52 0 1 0 0 1H8l-1.24 1-2.39-2V8.08h12.18v3l-2.6 2-1.16-.88h1.74a.52.52 0 0 0 0-1zM1 9.86l5 4.05-5 3.78zm18.7.11v7.8c-1.15-.91-3.26-2.6-5-3.93zM9.09 1.57a1.87 1.87 0 0 1 2.64 0c1 .76 6.62 6 7.9 7.15l-2 1.6V8a.94.94 0 0 0-1-.89H4.27a1 1 0 0 0-.92 1v2.36L1.16 8.62c1.33-1.18 6.91-6.13 7.93-7.05zM19.6 19.93H1.04V19c1.16-.89 7-5.37 8-6.19a2.19 2.19 0 0 1 2.7 0c1 .68 6.89 5.39 8 6.27v.63c.01.1.01.2-.14.22z" _fill="currentColor"/><path pid="1" d="M14.53 9.15H6.37a.52.52 0 1 0 0 1h8.16a.52.52 0 0 0 0-1z" _fill="currentColor"/>',
    },
});
