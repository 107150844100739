<template>
    <div class="section-about">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-4">
                    <div class="snapshot">
                        <Profile />
                    </div>
                    <div v-if="showAdjusto" class="ajusto-promo hidden-xs">
                        <a href="https://www.desjardins.com/on/en/insurance/auto/ajusto.html" class="consentRedirect">
                            <img src="@/assets/svg/ajusto-promo.svg" alt="Ajusto Promo" />
                        </a>
                    </div>
                </div>

                <div class="col-12 col-md-8">
                    <div class="content">
                        <div class="group">
                            <Quote />
                        </div>
                        <section class="group hidden-xs">
                            <h2 class="sr-only">Additional Office Info</h2>
                            <div v-if="agent.about || editingAllowed" class="group">
                                <h3>
                                    About Me
                                    <ChangeBtn :has-editor="true" type="AboutMe" />
                                </h3>
                                <p>
                                    <MoreText :text="agent.about" :threshold="350" />
                                </p>
                            </div>

                            <div class="group">
                                <h3>
                                    Products and Services Offered
                                    <ChangeBtn type="Products" />
                                </h3>
                                <p class="products" v-html="products"></p>
                            </div>

                            <div class="group">
                                <h3 v-if="showAdjusto">Mobile App and Ajusto</h3>
                                <h3 v-else>Mobile App</h3>
                                <MobileLinks />
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Profile from '@/components/SectionAbout/AgentProfile/Profile.vue';
import Quote from '@/components/SectionAbout/Quote.vue';
import MobileLinks from '@/components/SectionAbout/MobileLinks.vue';
import MoreText from '@/components/Shared/MoreText.vue';

export default {
    name: 'SectionAbout',
    components: {
        MoreText,
        Profile,
        Quote,
        MobileLinks,
    },
    computed: {
        ...mapGetters(['agent', 'office']),
        showAdjusto() {
            return this.office.provinceSettings?.showAdjusto;
        },
        products() {
            return this.agent.products.join(',&nbsp;&nbsp;');
        },
    },
};
</script>

<style lang="scss" scoped>
.section-about {
    padding: 28px 0 35px;

    .snapshot {
        margin-top: -178px;
    }

    .ajusto-promo {
        margin-top: 15px;
        margin-bottom: 15px;
        border-radius: 16px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
        border: 1px solid $gray;
        overflow: hidden;
    }

    h3 {
        margin-bottom: 0;
        font-size: 1.5rem;
        color: $green;
    }

    .group {
        font-size: 15px;
        line-height: 1.39;
        color: #000;
    }

    .group + .group {
        margin-top: 25px;
    }

    @include media('<md') {
        padding: 0;

        .snapshot {
            margin-top: 0;
        }

        .quote {
            margin: 0 -15px;
        }
    }
}
</style>
