import _ from "lodash";

const IMAGE_BUCKET = "https://s3.ca-central-1.amazonaws.com/cdn.mydd.io";

const parseValue = (value) => {
  if (value === "True") return true;
  if (value === "False") {
    return false;
  }
  if (_.isFinite(_.toNumber(value))) return _.toNumber(value);
  return value;
};

export default {
  agent: (state, getters) => {
    const aid = getters.blended.agent.aid;
    const agt = _.extend(
      {},
      {
        image: {
          profile: associateImage(aid),
        },
      },
      getters.blended.agent,
    );
    _.set(
      agt,
      "names.PreferredName",
      state.config.agent.names.NickName || state.config.agent.names.LegalName,
    );
    return agt;
  },
  articles: (state) => {
    return state.config.articles;
  },
  apiUrl: (state, getters) => {
    return `${getters.apiHost}/backend/api/frontend`;
  },
  apiHost: (state) => {
    return state.apiHost;
  },
  agentProducts: (state) => state.config.agent.products || [],
  blended: (state, getters) => {
    const previewPending = _.has(state, "route.query.previewPending");
    const overrides = _.filter(getters.overrides, (co) => {
      return (
        co.status === "approved" || (previewPending && co.status === "pending")
      );
    });
    let blended = _.cloneDeep(state.config);
    if (overrides) {
      _.each(overrides, (override) => {
        let key = override.key;
        if (override.subkey) {
          key += `.${override.subkey}`;
        }
        _.set(blended, key, parseValue(override.value));
      });
    }

    return blended;
  },
  desjardinsQueryParams: () => {
	const utm_id = "d-e-0-30380";
    return `?utm_id=${utm_id}&campagne=${utm_id}&utm_campaign=${utm_id}`;
  },
  editor: (state) => {
    return state.editor || {};
  },
  meta(state) {
    let branch = _.get(state, "route.query.branch", state.meta.branch);
    return _.extend(state.meta, {
      branch,
    });
  },
  office: (state, getters) => {
    let office = _.cloneDeep(getters.blended.office);

    office.phones.preferred = preferredPhoneKey(getters.blended.office.phones);
    office.phones.position = _.get(
      getters.blended.office.phones,
      "position",
      "below-profile",
    );
    office.phones.additionalNumbersPreference = _.get(
      getters.blended.office.phones,
      "additionalNumbersPreference",
      "onlyPrimary",
    );
    office.phones.phoneVisibility = _.get(office.phones, "phoneVisibility");
    office.physicalAddress.landmark.selected = _.get(
      office,
      "physicalAddress.landmark.selected",
      "long",
    );
    return _.extend({}, office, {
      maps: state.maps,
    });
  },

  overrides: (state) => {
    return state.config.overrides[state.meta.branch];
  },
  pendingOverrides: (state) => {
    return _.filter(state.config.overrides[state.meta.branch], {
      status: "pending",
    });
  },
  phone: (state, getters) => {
    const phones = getters.office.phones;
    const phone = _.get(phones, phones.preferred, { number: "" });
    return {
      pretty: phone.number,
      stripped: phone.number.split(new RegExp("[-() ]", "g")).join(""),
    };
  },
  route: (state) => {
    return state.route;
  },
  social: (state, getters) => {
    // let overrides = _.get(getters.overrides, 'office.social', {}); /
    let blended = getters.office.social;
    let feeds = ["Facebook", "Twitter", "LinkedIn", "Yelp"];

    // remove feeds that have content overrides, but are no longer in the config bundle
    feeds.forEach((feed) => {
      if (!_.get(blended, `${feed}.url`, false)) {
        delete blended[feed];
      }
    });

    const facebook_feed_active = blended?.Facebook?.activeFeed;
    const twitter_feed_active = blended?.Twitter?.activeFeed;
    const has_facebook = blended?.Facebook ?? false;
    const has_twitter = blended?.Twitter ?? false;

    let activeSocialFeed = "None";
    if (facebook_feed_active || (has_facebook && !twitter_feed_active)) {
      activeSocialFeed = "Facebook";
    } else if (twitter_feed_active || (has_twitter && !facebook_feed_active)) {
      activeSocialFeed = "Twitter";
    }

    let badges = Object.entries(blended)
      .map((entries) => entries[1])
      .filter((b) => feeds.includes(b?.socialMediaType ?? null))
      .filter((b) => b?.visible ?? true);

    return Object.assign(blended, {
      activeSocialFeed,
      badges,
      feeds,
    });
  },
  team: (state, getters) => {
    // we need to remove team member objects where they are no longer active, but have
    // content overrides so they appear in the blended context. No content override
    // includes the aid in the object, so if its there we know its at least in anthology
    const team = getters.blended.team;
    const activeMembers = _.pickBy(
      team.members,
      (member) => !_.isNil(member.aid),
    );

    team.members = _.map(activeMembers, (member) => {
      return _.extend(
        {},
        {
          image: {
            profile: teamMemberAssociateImage(member.aid),
          },
          initials: member.name
            ? member.name.split(" ")[0][0] + member.name.split(" ")[1][0]
            : "",
        },
        member,
      );
    });

    return team;
  },
  imageBucket: () => {
    return IMAGE_BUCKET;
  },
  c1Url: () => {
    // TODO: use http://0.0.0.0:8000 for dev
    return "https://quotestatus.mirus.io";
  },
  ui: (state, getters) => {
    return _.merge(state.ui, getters.blended.ui);
  },
};

const associateImage = (aid) => {
  const path = `amit_images/agencies/${aid}/AgentFormalColorPhoto.jpg`;
  return `${IMAGE_BUCKET}/${path}`;
};

const teamMemberAssociateImage = (aid) => {
  const path = `amit_images/team_members/${aid}/ATMColorPhoto.jpg`;
  return `${IMAGE_BUCKET}/${path}`;
};

const preferredPhoneKey = (phones) => {
  if (phones.preferred) {
    return phones.preferred;
  }

  const approvedPhoneKeys = [
    "phone",
    "cellular",
    "additionalPhone1",
    "additionalphone1",
    "phone1",
    "phone2",
    "phone3",
    "phone4",
  ];

  for (let key of approvedPhoneKeys) {
    if (_.has(phones, key) && phones[key]) {
      return key;
    }
  }
  return null;
};
