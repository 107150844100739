/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'mobile-alt': {
        width: 16,
        height: 16,
        viewBox: '0 0 320 512',
        data:
            '<path pid="0" d="M272 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h224c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zM160 480c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm112-108c0 6.6-5.4 12-12 12H60c-6.6 0-12-5.4-12-12V60c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12v312z"/>',
    },
});
