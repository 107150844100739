/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    blog_bg_general_tips: {
        width: 400,
        height: 399.999,
        viewBox: '0 0 400 399.999',
        data:
            '<g _fill="#03884F"><path pid="0" d="M216.555 256.006h-32.469c-4.528 0-8.217 3.297-8.217 7.32 0 4.049 3.688 7.324 8.217 7.324h32.469c4.535 0 8.217-3.275 8.217-7.324-.001-4.023-3.682-7.32-8.217-7.32zM210.926 274.438h-21.204c-4.504 0-8.212 2.648-8.212 5.902 0 3.269 3.708 5.934 8.212 5.934h21.204c4.514 0 8.234-2.665 8.234-5.934 0-3.254-3.721-5.902-8.234-5.902zM200.334 149.368c-24.465 0-44.324 19.305-44.324 43.012 0 13.097 9.718 25.038 9.819 25.166 4.871 5.888 8.718 16.45 8.718 24.085v3.723c0 3.65 3.158 6.641 7.063 6.641h36.502c3.906 0 7.078-2.99 7.078-6.641v-3.039c0-7.618 3.931-18.164 8.904-24 .139-.127 10.564-12.48 10.564-25.935 0-23.708-19.887-43.012-44.324-43.012zM195.684 113.725h8.867v26.476h-8.867zM255.447 136.377l-6.27-6.238-18.808 18.719 6.268 6.239zM253.433 181.021h26.599v8.827h-26.599zM169.696 148.858l-18.809-18.719-6.27 6.238 18.809 18.72zM120.031 181.021h26.602v8.827h-26.602z"/><path pid="1" d="M200 396.86C91.45 396.86 3.139 308.549 3.139 200 3.139 91.45 91.45 3.139 200 3.139S396.861 91.45 396.861 200c0 108.549-88.311 196.86-196.861 196.86zm0-343.721c-80.979 0-146.861 65.882-146.861 146.861S119.021 346.86 200 346.86 346.861 280.979 346.861 200 280.979 53.139 200 53.139z"/></g>',
    },
});
