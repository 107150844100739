const pa11y_data = {
    auth: {},
    config: {
        config_updated_at: 1689016873,
        isdemo: true,
        agent: {
            names: {
                NickName: 'Jean Dupont',
                LegalName: 'Jean Dupont',
            },
            aid: 100000000000,
            code: 100001,
            about:
                'Charleston University Bachelor of Philosophy\nCertified Financial Planner\nChartered Financial Consultant\nGolden Triangle Qualifier\nAuto insurance in Montreal and Chamby\nHome insurance in Montreal and Chamby\nBusiness insurance in Montreal and Chamby\nLife insurance in Montreal and Chamby',
            products: [
                'Auto Insurance',
                'Business Insurance',
                'Home & Property Insurance',
                'Life Insurance',
                'Living Benefits',
                'Mutual Funds',
                'Vehicle Loans',
            ],
            email: 'jeandupont@mirusresearch.com',
            languages: [
                {
                    languageCode: 'fr',
                    proficiencyLevel: 'Advanced',
                },
                {
                    languageCode: 'en',
                    proficiencyLevel: 'Advanced',
                },
            ],
        },
        office: {
            name: null,
            about:
                'Charleston University Bachelor of Philosophy\nCertified Financial Planner\nChartered Financial Consultant\nGolden Triangle Qualifier\nAuto insurance in Montreal and Chamby\nHome insurance in Montreal and Chamby\nBusiness insurance in Montreal and Chamby\nLife insurance in Montreal and Chamby',
            phones: {
                phone: {
                    number: '613-555-7777',
                    onDefaultMicrosite: true,
                    preferred: true,
                },
                cellular: {
                    number: '613-555-7778',
                    onDefaultMicrosite: false,
                    preferred: false,
                },
                fax: {
                    number: '613-555-7779',
                    onDefaultMicrosite: true,
                    preferred: false,
                },
            },
            hours: ['Monday- Friday  10 to 7', '24/7 Phone coverage'],
            mission:
                'Our Mission is to help people manage the risks of everyday life,recover from the unexpected and realize their dreams',
            social: {
                Facebook: {
                    socialMediaType: 'Facebook',
                    url: 'https://www.facebook.com/AstronautChrisHadfield',
                },
            },
            postalAddress: {
                street1: '900 René-Lévesque Blvd W',
                street2: null,
                country: 'Canada',
                countryCode: 'CA',
                province: 'Quebec',
                provinceCode: 'QC',
                municipality: 'Montréal',
                postalCode: 'H3B',
            },
            physicalAddress: {
                landmark: {
                    long: "Across from Tim Horton's downtown.",
                    short: null,
                },
                latitude: 45.4960361,
                longitude: -73.5730842,
                timezone: 'America/Toronto',
                street1: '900 René-Lévesque Blvd W',
                street2: null,
                municipality: 'Montréal',
                province: 'Quebec',
                provinceCode: 'QC',
                country: 'Canada',
                countryCode: 'CA',
                postalCode: 'H3B',
                placeId: 'ChIJVy6RC3AyVUAR6xkM-5Os068',
            },
            quoteOptions: [
                ['Auto - Quick Quote', 'au_qq'],
                ['Auto', 'au'],
                ['Homeowners', 'po'],
                ['Condo Owners', 'co'],
                ['Tenants', 'lo'],
            ],
            jobs: [],
            provinceSettings: null,
        },
        team: {
            members: {
                200000000000: {
                    names: {
                        NickName: 'Jane Dove',
                        LegalName: 'Jane Dove',
                    },
                    hasPhoto: false,
                    name: 'Jane Dove',
                    aid: 200000000000,
                    description: null,
                    position: 'Office Representative',
                    visible: false,
                    displayOrder: 0,
                    languages: [
                        {
                            languageCode: 'en',
                            proficiencyLevel: 'Advanced',
                        },
                    ],
                },
                300000000000: {
                    names: {
                        NickName: 'Jay Billings',
                        LegalName: 'Jason Billings',
                    },
                    hasPhoto: false,
                    name: 'Jason Billings',
                    aid: 300000000000,
                    description: null,
                    position: 'Office Associate',
                    visible: false,
                    displayOrder: 0,
                    languages: [
                        {
                            languageCode: 'en',
                            proficiencyLevel: 'Advanced',
                        },
                    ],
                },
                400000000000: {
                    names: {
                        NickName: 'Pebbles McClary',
                        LegalName: 'Pebbles McClary',
                    },
                    hasPhoto: false,
                    name: 'Pebbles McClary',
                    aid: 400000000000,
                    description: null,
                    position: 'Financial Services Representative',
                    visible: false,
                    displayOrder: 0,
                    languages: [
                        {
                            languageCode: 'en',
                            proficiencyLevel: 'Advanced',
                        },
                    ],
                },
                500000000000: {
                    names: {
                        NickName: 'Steve Philips',
                        LegalName: 'Steve Philips',
                    },
                    hasPhoto: false,
                    name: 'Steve Philips',
                    aid: 500000000000,
                    description: null,
                    position: 'Insurance Account Representative',
                    visible: false,
                    displayOrder: 0,
                    languages: [
                        {
                            languageCode: 'en',
                            proficiencyLevel: 'Advanced',
                        },
                    ],
                },
            },
        },
        overrides: {
            master: [
                {
                    branch: 'master',
                    status: 'approved',
                    key: 'office.phones.position',
                    subkey: null,
                    value: 'below-profile',
                    created_by: 415,
                    created_at: '2021-03-25T00:05:02.486310+00:00',
                },
                {
                    branch: 'master',
                    status: 'approved',
                    key: 'ui.tabs.team',
                    subkey: null,
                    value: {
                        visible: true,
                    },
                    created_by: 21,
                    created_at: '2020-11-17T19:31:53.743368+00:00',
                },
                {
                    branch: 'master',
                    status: 'approved',
                    key: 'agent.about',
                    subkey: null,
                    value: '<ul><li>test</li><li>test2<br></li></ul>',
                    created_by: 21,
                    created_at: '2019-11-11T18:25:50.508910+00:00',
                },
                {
                    branch: 'master',
                    status: 'approved',
                    key: 'office.phones.preferred',
                    subkey: null,
                    value: 'cellular',
                    created_by: 415,
                    created_at: '2019-08-06T15:22:29.184160+00:00',
                },
                {
                    branch: 'master',
                    status: 'approved',
                    key: 'office.phones.phoneVisibility',
                    subkey: null,
                    value: {
                        phone: {
                            visible: true,
                        },
                        cellular: {
                            visible: false,
                        },
                        fax: {
                            visible: false,
                        },
                    },
                    created_by: 415,
                    created_at: '2019-08-06T15:01:33.123993+00:00',
                },
                {
                    branch: 'master',
                    status: 'approved',
                    key: 'office.phones.additionalNumbersPreference',
                    subkey: null,
                    value: 'custom',
                    created_by: 415,
                    created_at: '2019-08-06T15:01:33.116063+00:00',
                },
                {
                    branch: 'master',
                    status: 'approved',
                    key: 'office.structuredHours',
                    subkey: null,
                    value: [
                        {
                            name: 'Monday',
                            openForBusiness: true,
                            times: [
                                {
                                    start: {
                                        hours: 7,
                                        minutes: 0,
                                    },
                                    end: {
                                        hours: 17,
                                        minutes: 0,
                                    },
                                },
                            ],
                        },
                        {
                            name: 'Tuesday',
                            openForBusiness: true,
                            times: [
                                {
                                    start: {
                                        hours: 9,
                                        minutes: 0,
                                    },
                                    end: {
                                        hours: 17,
                                        minutes: 0,
                                    },
                                },
                            ],
                        },
                        {
                            name: 'Wednesday',
                            openForBusiness: true,
                            times: [
                                {
                                    start: {
                                        hours: 9,
                                        minutes: 0,
                                    },
                                    end: {
                                        hours: 17,
                                        minutes: 0,
                                    },
                                },
                            ],
                        },
                        {
                            name: 'Thursday',
                            openForBusiness: true,
                            times: [
                                {
                                    start: {
                                        hours: 9,
                                        minutes: 0,
                                    },
                                    end: {
                                        hours: 17,
                                        minutes: 0,
                                    },
                                },
                            ],
                        },
                        {
                            name: 'Friday',
                            openForBusiness: true,
                            times: [
                                {
                                    start: {
                                        hours: 9,
                                        minutes: 0,
                                    },
                                    end: {
                                        hours: 17,
                                        minutes: 0,
                                    },
                                },
                            ],
                        },
                        {
                            name: 'Saturday',
                            openForBusiness: false,
                            times: [
                                {
                                    start: {
                                        hours: 9,
                                        minutes: 0,
                                    },
                                    end: {
                                        hours: 17,
                                        minutes: 0,
                                    },
                                },
                            ],
                        },
                        {
                            name: 'Sunday',
                            openForBusiness: false,
                            times: [
                                {
                                    start: {
                                        hours: 9,
                                        minutes: 0,
                                    },
                                    end: {
                                        hours: 17,
                                        minutes: 0,
                                    },
                                },
                            ],
                        },
                        {
                            name: 'After Hours by Appointment',
                            byAppointment: false,
                        },
                    ],
                    created_by: 10,
                    created_at: '2019-04-09T19:53:51.859849+00:00',
                },
                {
                    branch: 'master',
                    status: 'approved',
                    key: 'office.banner_bg_url',
                    subkey: null,
                    value:
                        'https://s3.ca-central-1.amazonaws.com/cdn.mydd.io/c1/maps/r/roundbacon.com/roundbacon.com-header-9c5ae1decc16f4e23e1e226a3d95f009.png',
                    created_by: 21,
                    created_at: '2019-01-02T19:15:20.893510+00:00',
                },
                {
                    branch: 'master',
                    status: 'approved',
                    key: 'team.members',
                    subkey: '400000000000.description',
                    value: 'test',
                    created_by: 21,
                    created_at: '2018-11-28T19:58:50.343308+00:00',
                },
                {
                    branch: 'master',
                    status: 'approved',
                    key: 'team.members',
                    subkey: '300000000000.description',
                    value: 'testing',
                    created_by: 21,
                    created_at: '2018-11-26T15:45:07.399464+00:00',
                },
                {
                    branch: 'master',
                    status: 'approved',
                    key: 'team.members',
                    subkey: '300000000000.visible',
                    value: 'True',
                    created_by: 21,
                    created_at: '2018-11-26T15:44:54.435135+00:00',
                },
                {
                    branch: 'master',
                    status: 'approved',
                    key: 'team.members',
                    subkey: '500000000000.visible',
                    value: 'True',
                    created_by: 21,
                    created_at: '2018-11-26T15:44:54.433649+00:00',
                },
                {
                    branch: 'master',
                    status: 'approved',
                    key: 'team.members',
                    subkey: '200000000000.visible',
                    value: 'True',
                    created_by: 21,
                    created_at: '2018-11-26T15:44:54.412216+00:00',
                },
                {
                    branch: 'master',
                    status: 'approved',
                    key: 'team.members',
                    subkey: '400000000000.visible',
                    value: 'True',
                    created_by: 21,
                    created_at: '2018-11-26T15:44:54.391962+00:00',
                },
                {
                    branch: 'master',
                    status: 'approved',
                    key: 'team.description',
                    subkey: null,
                    value: "this is our team. they're pretty neat<br>",
                    created_by: 21,
                    created_at: '2018-11-26T13:42:28.111690+00:00',
                },
                {
                    branch: 'master',
                    status: 'approved',
                    key: 'team.members',
                    subkey: '200000000000.description',
                    value: 'this is a test',
                    created_by: 21,
                    created_at: '2018-11-26T13:42:04.716377+00:00',
                },
                {
                    branch: 'master',
                    status: 'approved',
                    key: 'office.social.Facebook.visible',
                    subkey: null,
                    value: 'True',
                    created_by: 21,
                    created_at: '2018-11-20T20:16:09.675356+00:00',
                },
            ],
            '192-review-us-cta': [
                {
                    branch: '192-review-us-cta',
                    status: 'approved',
                    key: 'ui.review.visible',
                    subkey: null,
                    value: 'True',
                    created_by: 616,
                    created_at: '2020-10-22T16:00:24.475596+00:00',
                },
                {
                    branch: '192-review-us-cta',
                    status: 'approved',
                    key: 'team.members',
                    subkey: '400000000000.visible',
                    value: 'False',
                    created_by: 616,
                    created_at: '2020-10-22T16:00:02.278622+00:00',
                },
                {
                    branch: '192-review-us-cta',
                    status: 'approved',
                    key: 'ui.tabs.team',
                    subkey: null,
                    value: {
                        visible: true,
                    },
                    created_by: 616,
                    created_at: '2020-10-22T16:00:02.143304+00:00',
                },
                {
                    branch: '192-review-us-cta',
                    status: 'approved',
                    key: 'team.members',
                    subkey: '200000000000.visible',
                    value: 'True',
                    created_by: 616,
                    created_at: '2020-10-22T16:00:02.134781+00:00',
                },
                {
                    branch: '192-review-us-cta',
                    status: 'approved',
                    key: 'team.members',
                    subkey: '300000000000.visible',
                    value: 'True',
                    created_by: 616,
                    created_at: '2020-10-22T16:00:01.980600+00:00',
                },
                {
                    branch: '192-review-us-cta',
                    status: 'approved',
                    key: 'team.members',
                    subkey: '500000000000.visible',
                    value: 'False',
                    created_by: 616,
                    created_at: '2020-10-22T16:00:01.971843+00:00',
                },
            ],
            pdf: [
                {
                    branch: 'pdf',
                    status: 'approved',
                    key: 'team.members',
                    subkey: '400000000000.visible',
                    value: 'False',
                    created_by: 21,
                    created_at: '2020-01-24T21:45:29.582923+00:00',
                },
                {
                    branch: 'pdf',
                    status: 'approved',
                    key: 'team.members',
                    subkey: '200000000000.visible',
                    value: 'True',
                    created_by: 21,
                    created_at: '2020-01-24T21:45:29.452099+00:00',
                },
                {
                    branch: 'pdf',
                    status: 'approved',
                    key: 'team.members',
                    subkey: '500000000000.visible',
                    value: 'False',
                    created_by: 21,
                    created_at: '2020-01-24T21:45:29.278274+00:00',
                },
                {
                    branch: 'pdf',
                    status: 'approved',
                    key: 'ui.tabs.team',
                    subkey: null,
                    value: {
                        visible: true,
                    },
                    created_by: 21,
                    created_at: '2020-01-24T21:45:29.232120+00:00',
                },
                {
                    branch: 'pdf',
                    status: 'approved',
                    key: 'team.members',
                    subkey: '300000000000.visible',
                    value: 'False',
                    created_by: 21,
                    created_at: '2020-01-24T21:45:29.202563+00:00',
                },
            ],
            aurora: [
                {
                    branch: 'aurora',
                    status: 'approved',
                    key: 'team.members',
                    subkey: '400000000000.visible',
                    value: 'False',
                    created_by: 21,
                    created_at: '2020-01-15T14:26:00.883455+00:00',
                },
                {
                    branch: 'aurora',
                    status: 'approved',
                    key: 'team.members',
                    subkey: '300000000000.visible',
                    value: 'False',
                    created_by: 21,
                    created_at: '2020-01-15T14:26:00.543762+00:00',
                },
                {
                    branch: 'aurora',
                    status: 'approved',
                    key: 'ui.tabs.team',
                    subkey: null,
                    value: {
                        visible: true,
                    },
                    created_by: 21,
                    created_at: '2020-01-15T14:26:00.443763+00:00',
                },
                {
                    branch: 'aurora',
                    status: 'approved',
                    key: 'team.members',
                    subkey: '200000000000.visible',
                    value: 'True',
                    created_by: 21,
                    created_at: '2020-01-15T14:26:00.338196+00:00',
                },
                {
                    branch: 'aurora',
                    status: 'approved',
                    key: 'team.members',
                    subkey: '500000000000.visible',
                    value: 'False',
                    created_by: 21,
                    created_at: '2020-01-15T14:26:00.314198+00:00',
                },
            ],
            'timezone-map': [
                {
                    branch: 'timezone-map',
                    status: 'approved',
                    key: 'office.structuredHours',
                    subkey: null,
                    value: [
                        {
                            name: 'Monday',
                            openForBusiness: true,
                            times: [
                                {
                                    start: {
                                        hours: 9,
                                        minutes: 0,
                                    },
                                    end: {
                                        hours: 17,
                                        minutes: 0,
                                    },
                                },
                            ],
                        },
                        {
                            name: 'Tuesday',
                            openForBusiness: true,
                            times: [
                                {
                                    start: {
                                        hours: 9,
                                        minutes: 0,
                                    },
                                    end: {
                                        hours: 17,
                                        minutes: 0,
                                    },
                                },
                            ],
                        },
                        {
                            name: 'Wednesday',
                            openForBusiness: true,
                            times: [
                                {
                                    start: {
                                        hours: 9,
                                        minutes: 0,
                                    },
                                    end: {
                                        hours: 17,
                                        minutes: 0,
                                    },
                                },
                            ],
                        },
                        {
                            name: 'Thursday',
                            openForBusiness: true,
                            times: [
                                {
                                    start: {
                                        hours: 9,
                                        minutes: 0,
                                    },
                                    end: {
                                        hours: 17,
                                        minutes: 0,
                                    },
                                },
                            ],
                        },
                        {
                            name: 'Friday',
                            openForBusiness: true,
                            times: [
                                {
                                    start: {
                                        hours: 9,
                                        minutes: 0,
                                    },
                                    end: {
                                        hours: 17,
                                        minutes: 0,
                                    },
                                },
                            ],
                        },
                        {
                            name: 'Saturday',
                            openForBusiness: true,
                            times: [
                                {
                                    start: {
                                        hours: 9,
                                        minutes: 0,
                                    },
                                    end: {
                                        hours: 17,
                                        minutes: 0,
                                    },
                                },
                            ],
                        },
                        {
                            name: 'Sunday',
                            openForBusiness: true,
                            times: [
                                {
                                    start: {
                                        hours: 9,
                                        minutes: 0,
                                    },
                                    end: {
                                        hours: 17,
                                        minutes: 0,
                                    },
                                },
                            ],
                        },
                        {
                            name: 'After Hours by Appointment',
                            byAppointment: false,
                        },
                    ],
                    created_by: 9,
                    created_at: '2019-11-06T22:01:00.068401+00:00',
                },
            ],
            'additional-custom-phone-fix': [
                {
                    branch: 'additional-custom-phone-fix',
                    status: 'approved',
                    key: 'office.phones.phoneVisibility',
                    subkey: null,
                    value: {
                        phone: {
                            visible: true,
                        },
                        cellular: {
                            visible: false,
                        },
                        fax: {
                            visible: false,
                        },
                    },
                    created_by: 21,
                    created_at: '2019-05-01T20:25:16.141603+00:00',
                },
                {
                    branch: 'additional-custom-phone-fix',
                    status: 'approved',
                    key: 'office.phones.additionalNumbersPreference',
                    subkey: null,
                    value: 'custom',
                    created_by: 21,
                    created_at: '2019-05-01T20:25:16.137394+00:00',
                },
            ],
            'phone-number-locations': [
                {
                    branch: 'phone-number-locations',
                    status: 'approved',
                    key: 'office.structuredHours',
                    subkey: null,
                    value: [
                        {
                            name: 'Monday',
                            openForBusiness: true,
                            times: [
                                {
                                    start: {
                                        hours: 9,
                                        minutes: 0,
                                    },
                                    end: {
                                        hours: 17,
                                        minutes: 0,
                                    },
                                },
                            ],
                        },
                        {
                            name: 'Tuesday',
                            openForBusiness: true,
                            times: [
                                {
                                    start: {
                                        hours: 9,
                                        minutes: 0,
                                    },
                                    end: {
                                        hours: 17,
                                        minutes: 0,
                                    },
                                },
                            ],
                        },
                        {
                            name: 'Wednesday',
                            openForBusiness: true,
                            times: [
                                {
                                    start: {
                                        hours: 9,
                                        minutes: 0,
                                    },
                                    end: {
                                        hours: 17,
                                        minutes: 0,
                                    },
                                },
                            ],
                        },
                        {
                            name: 'Thursday',
                            openForBusiness: true,
                            times: [
                                {
                                    start: {
                                        hours: 9,
                                        minutes: 0,
                                    },
                                    end: {
                                        hours: 18,
                                        minutes: 0,
                                    },
                                },
                            ],
                        },
                        {
                            name: 'Friday',
                            openForBusiness: false,
                            times: [
                                {
                                    start: {
                                        hours: 9,
                                        minutes: 0,
                                    },
                                    end: {
                                        hours: 17,
                                        minutes: 0,
                                    },
                                },
                            ],
                        },
                        {
                            name: 'Saturday',
                            openForBusiness: false,
                            times: [
                                {
                                    start: {
                                        hours: 9,
                                        minutes: 0,
                                    },
                                    end: {
                                        hours: 17,
                                        minutes: 0,
                                    },
                                },
                            ],
                        },
                        {
                            name: 'Sunday',
                            openForBusiness: false,
                            times: [
                                {
                                    start: {
                                        hours: 9,
                                        minutes: 0,
                                    },
                                    end: {
                                        hours: 17,
                                        minutes: 0,
                                    },
                                },
                            ],
                        },
                        {
                            name: 'After Hours by Appointment',
                            byAppointment: false,
                        },
                    ],
                    created_by: 21,
                    created_at: '2019-02-28T19:29:05.048564+00:00',
                },
                {
                    branch: 'phone-number-locations',
                    status: 'approved',
                    key: 'office.phones.additionalNumbersPreference',
                    subkey: null,
                    value: 'custom',
                    created_by: 21,
                    created_at: '2019-02-28T19:24:13.463374+00:00',
                },
                {
                    branch: 'phone-number-locations',
                    status: 'approved',
                    key: 'office.phones.phoneVisibility',
                    subkey: null,
                    value: {
                        phone: {
                            visible: true,
                        },
                        cellular: {
                            visible: true,
                        },
                        fax: {
                            visible: false,
                        },
                    },
                    created_by: 21,
                    created_at: '2019-02-28T19:24:13.434955+00:00',
                },
            ],
            'officehours-refactor': [
                {
                    branch: 'officehours-refactor',
                    status: 'approved',
                    key: 'office.structuredHours',
                    subkey: null,
                    value: [
                        {
                            name: 'Monday',
                            openForBusiness: true,
                            times: [
                                {
                                    start: {
                                        hours: 9,
                                        minutes: 0,
                                    },
                                    end: {
                                        hours: 17,
                                        minutes: 0,
                                    },
                                },
                            ],
                        },
                        {
                            name: 'Tuesday',
                            openForBusiness: true,
                            times: [
                                {
                                    start: {
                                        hours: 9,
                                        minutes: 0,
                                    },
                                    end: {
                                        hours: 17,
                                        minutes: 0,
                                    },
                                },
                            ],
                        },
                        {
                            name: 'Wednesday',
                            openForBusiness: true,
                            times: [
                                {
                                    start: {
                                        hours: 9,
                                        minutes: 0,
                                    },
                                    end: {
                                        hours: 17,
                                        minutes: 0,
                                    },
                                },
                            ],
                        },
                        {
                            name: 'Thursday',
                            openForBusiness: true,
                            times: [
                                {
                                    start: {
                                        hours: 9,
                                        minutes: 0,
                                    },
                                    end: {
                                        hours: 17,
                                        minutes: 0,
                                    },
                                },
                            ],
                        },
                        {
                            name: 'Friday',
                            openForBusiness: true,
                            times: [
                                {
                                    start: {
                                        hours: 9,
                                        minutes: 0,
                                    },
                                    end: {
                                        hours: 13,
                                        minutes: 0,
                                    },
                                },
                            ],
                        },
                        {
                            name: 'Saturday',
                            openForBusiness: false,
                            times: [
                                {
                                    start: {
                                        hours: 9,
                                        minutes: 0,
                                    },
                                    end: {
                                        hours: 17,
                                        minutes: 0,
                                    },
                                },
                            ],
                        },
                        {
                            name: 'Sunday',
                            openForBusiness: false,
                            times: [
                                {
                                    start: {
                                        hours: 9,
                                        minutes: 0,
                                    },
                                    end: {
                                        hours: 17,
                                        minutes: 0,
                                    },
                                },
                            ],
                        },
                        {
                            name: 'After Hours by Appointment',
                            byAppointment: false,
                        },
                    ],
                    created_by: 21,
                    created_at: '2019-01-25T19:58:16.242122+00:00',
                },
            ],
            'team-prefname': [
                {
                    branch: 'team-prefname',
                    status: 'approved',
                    key: 'ui.tabs.team',
                    subkey: null,
                    value: {
                        visible: true,
                    },
                    created_by: 21,
                    created_at: '2018-11-26T20:44:01.486256+00:00',
                },
                {
                    branch: 'team-prefname',
                    status: 'approved',
                    key: 'team.members',
                    subkey: '300000000000.visible',
                    value: 'True',
                    created_by: 21,
                    created_at: '2018-11-26T20:44:01.476548+00:00',
                },
                {
                    branch: 'team-prefname',
                    status: 'approved',
                    key: 'team.members',
                    subkey: '500000000000.visible',
                    value: 'True',
                    created_by: 21,
                    created_at: '2018-11-26T20:44:01.473625+00:00',
                },
                {
                    branch: 'team-prefname',
                    status: 'approved',
                    key: 'team.members',
                    subkey: '200000000000.visible',
                    value: 'True',
                    created_by: 21,
                    created_at: '2018-11-26T20:44:01.468683+00:00',
                },
                {
                    branch: 'team-prefname',
                    status: 'approved',
                    key: 'team.members',
                    subkey: '400000000000.visible',
                    value: 'True',
                    created_by: 21,
                    created_at: '2018-11-26T20:44:01.465502+00:00',
                },
            ],
            'social-co-not-url': [
                {
                    branch: 'social-co-not-url',
                    status: 'approved',
                    key: 'office.social.Facebook.activeFeed',
                    subkey: null,
                    value: 'True',
                    created_by: 21,
                    created_at: '2018-11-20T19:48:41.684769+00:00',
                },
                {
                    branch: 'social-co-not-url',
                    status: 'approved',
                    key: 'office.social.Facebook.visible',
                    subkey: null,
                    value: 'True',
                    created_by: 21,
                    created_at: '2018-11-20T19:48:33.470072+00:00',
                },
                {
                    branch: 'social-co-not-url',
                    status: 'approved',
                    key: 'office.structuredHours',
                    subkey: null,
                    value: [
                        {
                            name: 'Monday',
                            openForBusiness: true,
                            times: [
                                {
                                    start: '2018-11-19T15:00:00.000Z',
                                    end: '2018-11-19T23:00:00.000Z',
                                },
                            ],
                        },
                        {
                            name: 'Tuesday',
                            openForBusiness: true,
                            times: [
                                {
                                    start: '2018-11-19T15:00:00.000Z',
                                    end: '2018-11-19T23:00:00.000Z',
                                },
                            ],
                        },
                        {
                            name: 'Wednesday',
                            openForBusiness: false,
                            times: [
                                {
                                    start: '2018-11-19T15:00:00.000Z',
                                    end: '2018-11-19T23:00:00.000Z',
                                },
                            ],
                        },
                        {
                            name: 'Thursday',
                            openForBusiness: false,
                            times: [
                                {
                                    start: '2018-11-19T15:00:00.000Z',
                                    end: '2018-11-19T23:00:00.000Z',
                                },
                            ],
                        },
                        {
                            name: 'Friday',
                            openForBusiness: false,
                            times: [
                                {
                                    start: '2018-11-19T15:00:00.000Z',
                                    end: '2018-11-19T23:00:00.000Z',
                                },
                            ],
                        },
                        {
                            name: 'Saturday',
                            openForBusiness: false,
                            times: [
                                {
                                    start: '2018-11-19T15:00:00.000Z',
                                    end: '2018-11-19T23:00:00.000Z',
                                },
                            ],
                        },
                        {
                            name: 'Sunday',
                            openForBusiness: false,
                            times: [
                                {
                                    start: '2018-11-19T15:00:00.000Z',
                                    end: '2018-11-19T23:00:00.000Z',
                                },
                            ],
                        },
                        {
                            name: 'After Hours by Appointment',
                            byAppointment: false,
                        },
                    ],
                    created_by: 21,
                    created_at: '2018-11-20T19:39:00.719790+00:00',
                },
            ],
            articles: [
                {
                    branch: 'articles',
                    status: 'approved',
                    key: 'agent.about',
                    subkey: null,
                    value:
                        '<div>hello<br></div><div><ul><li>Test</li><li>wo</li><li>lol</li><li>ok</li></ul></div><div><div><div></div></div></div>',
                    created_by: 19,
                    created_at: '2018-10-15T14:42:16.476949+00:00',
                },
            ],
            'peachy-fixes': [
                {
                    branch: 'peachy-fixes',
                    status: 'approved',
                    key: 'agent.about',
                    subkey: null,
                    value: '',
                    created_by: 20,
                    created_at: '2018-10-14T00:41:52.376493+00:00',
                },
            ],
            social: [
                {
                    branch: 'social',
                    status: 'approved',
                    key: 'agent.about',
                    subkey: null,
                    value: '<div>hello</div>',
                    created_by: 19,
                    created_at: '2018-10-08T20:03:44.137864+00:00',
                },
            ],
        },
        active: true,
        articles: [
            {
                title: 'Solutions to ease eco-anxiety',
                url: 'https://www.desjardins.com/on/en/tips/solutions-ease-eco-anxiety.html',
                summary:
                    'Feelings of hopelessness and helplessness about the future now have a name: eco-anxiety.',
                image:
                    'https://www.desjardins.com/on/en/tips/solutions-ease-eco-anxiety.thumb.630.1200.png',
                category: 'investments',
            },
            {
                title: '4 tips to save for your financial goals',
                url: 'https://www.desjardins.com/on/en/tips/tips-save-financial-goals.html',
                summary:
                    'Here are 4 tips to help you get motivated to save, stick with it, and get the most out of it.',
                image:
                    'https://www.desjardins.com/on/en/tips/tips-save-financial-goals.thumb.630.1200.png',
                category: 'accounts',
            },
            {
                title: 'Shareholder engagement: the foundation of Desjardins Funds',
                url:
                    'https://www.desjardins.com/on/en/tips/shareholder-engagement-foundation-desjardins-funds.html',
                summary:
                    'Shareholder activism is one of the key component of Desjardins Funds’ responsible investment strategies.',
                image:
                    'https://www.desjardins.com/on/en/tips/shareholder-engagement-foundation-desjardins-funds.thumb.630.1200.png',
                category: 'cooperative',
            },
        ],
    },
    maps: {
        url:
            'https://www.google.com/maps/search/?api=1&query=Jean%20Dupont%2045.4960361%2C-73.5730842&query_place_id=ChIJVy6RC3AyVUAR6xkM-5Os068&z=22',
        image: {
            'sidebar-sm':
                'https://s3.ca-central-1.amazonaws.com/cdn.mydd.io/c1/maps/r/roundbacon.com/roundbacon.com-sidebar-sm-f53122e4fa8991602681a61d0f9863de.png',
            'sidebar-md':
                'https://s3.ca-central-1.amazonaws.com/cdn.mydd.io/c1/maps/r/roundbacon.com/roundbacon.com-sidebar-md-d3c07e09e74129f65b858726c789c212.png',
            header:
                'https://s3.ca-central-1.amazonaws.com/cdn.mydd.io/c1/maps/r/roundbacon.com/roundbacon.com-header-2a3063b306da4991551521c9ad88a652.png',
        },
    },
    meta: {
        page_title: 'Jean Dupont, Desjardins Insurance Agent in Montréal, QC',
        description:
            'Jean Dupont, Desjardins Insurance Agent in Montréal, QC | Auto, Home, Life Insurance & more',
        cookie_management_id: '',
        baseuri: 'https://www.roundbacon.com',
        branch: 'master',
        version: 'd7077ed',
        domain: 'roundbacon.com',
        hostname: 'c1-backend-867f58cb4f-8fkp2',
        peachy:
            'eyJkb21haW5fbmFtZSI6InJvdW5kYmFjb24uY29tIiwicmVxdWVzdF91dWlkIjoiMjY2ZDI3YmItNDNjOC00YzExLWIwMTUtOWNiOTZhOGZiODU0IiwicmVxdWVzdF90aW1lc3RhbXAiOiIyMDIzLTA3LTExVDE2OjA0OjMyLjk2MVoiLCJrbm93bl9pcCI6bnVsbCwiaXBfYWRkcmVzcyI6IjE0MC4xNDEuMjI5LjExNCIsInVwc3RyZWFtX2hvc3QiOiJjMS1iYWNrZW5kLTg2N2Y1OGNiNGYtOGZrcDIiLCJiYWNrZW5kIjoiYzEtYmFja2VuZCIsImJhY2tlbmRfcGF0aCI6IiIsInByb2R1Y3RfdHlwZSI6ImMxIiwiY29uZmlnX2Fzc29jaWF0ZV9pZCI6MTAwMDAxLCJjb25maWdfc3RhdGUiOiJRQyJ9',
        robots: 'NOINDEX, NOFOLLOW',
    },
    url: '/?domain=roundbacon.com',
};

export default process.env.VUE_APP_PA11Y === 'true' ? pa11y_data : {};
