/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    phone: {
        width: 16,
        height: 16,
        viewBox: '0 0 16.8 16.76',
        data:
            '<path pid="0" d="M16.42 13.26l-2.59-2.6a1.37 1.37 0 0 0-1.91 0L10.62 12l-.26-.15a12.71 12.71 0 0 1-3.15-2.26 12.71 12.71 0 0 1-2.27-3.15l-.15-.25.88-.88.43-.43A1.36 1.36 0 0 0 6.14 3L3.54.38a1.34 1.34 0 0 0-1.9 0l-.74.77a4.22 4.22 0 0 0-.6 1.06 4.38 4.38 0 0 0-.25 1.1c-.34 2.84 1 5.43 4.48 9 4.87 4.87 8.8 4.5 9 4.48a4.24 4.24 0 0 0 1.09-.27 4.34 4.34 0 0 0 1.05-.6l.74-.72a1.35 1.35 0 0 0 0-1.91z" _fill="currentColor"/>',
    },
});
