/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'google-review': {
        width: 16,
        height: 16,
        viewBox: '0 0 355 355',
        data:
            '<g fill-rule="nonzero"><path pid="0" d="M24.3 100.2c5.9-13.8 14.4-25.9 24.2-37.1C87.9 18.5 137.7-1.4 196.8 2.9c36.6 2.7 68.9 16.9 97.1 40.3 2.3 1.9 3.1 3.1.5 5.6-15 14.7-29.8 29.5-44.6 44.5-2.6 2.6-4.1 3.4-7.6.7-53.8-40.6-134-17.1-157.4 46-.6 1.6-1.2 3.1-1.9 4.6-19.5-14.7-39.1-29.5-58.6-44.4z" fill="#e94436"/><path pid="1" d="M83 210.1c7.7 22.3 20.9 40.7 40.8 53.4 36.6 23.3 74.6 23.2 113 4.2 1.2-.6 2.3-1.2 3.5-1.9 18.1 14.8 36.7 29 55.4 43.1-20 19.6-44.1 32.2-71.1 38.5-63.3 14.7-119-.4-166.3-44.9-14.4-13.5-26-29.4-34-47.6 19.5-15 39.1-29.9 58.7-44.8z" fill="#35a853"/><path pid="2" d="M295.7 308.8c-18.7-14.1-37.2-28.3-55.4-43.1 16.5-11.5 28.3-26.5 33.7-46.2 1.7-6.2 1.8-6.2-4.5-6.2-27.5 0-55-.1-82.4.1-4.8 0-5.7-1.4-5.6-5.8.2-19.2.2-38.3 0-57.5 0-3.6.8-4.8 4.6-4.8 51.5.1 102.9.1 154.4 0 3.2 0 4.6.7 5.3 4.2 5.9 30.1 2.9 59.6-5.9 88.7-7.7 25.7-21.2 48-40.1 67-1.3 1.4-2.8 2.4-4.1 3.6z" fill="#4285f3"/><path pid="3" d="M83 210.1c-19.6 14.9-39.1 29.8-58.7 44.7-8.2-15.7-13.2-32.5-15.9-50-5.5-35.5-.5-69.5 14.3-102.1.4-.9 1-1.7 1.6-2.5C43.9 115 63.4 129.9 83 144.7c-6.6 21.8-6.8 43.6 0 65.4z" fill="#fabb06"/></g>',
    },
});
