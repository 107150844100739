/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    blog_bg_home: {
        width: 400,
        height: 399.999,
        viewBox: '0 0 400 399.999',
        data:
            '<g _fill="#03884F"><path pid="0" d="M200.916 115.788l-30.456 18.681v-5.196a2.745 2.745 0 0 0-2.737-2.746h-25.094a2.743 2.743 0 0 0-2.742 2.746v23.958l-30.43 18.674 11.366 17.622 13.13-7.387v71.682c0 4.916 4.073 11.967 9.02 11.967h40.979v-42.41h36.198v42.41h39.89c4.938 0 7.214-7.051 7.214-11.967V182.14l12.627 7.387 12.177-17.622-91.142-56.117z"/><path pid="1" d="M200 396.86C91.45 396.86 3.139 308.549 3.139 200 3.139 91.45 91.45 3.139 200 3.139S396.861 91.45 396.861 200c0 108.549-88.311 196.86-196.861 196.86zm0-343.721c-80.979 0-146.861 65.882-146.861 146.861S119.021 346.86 200 346.86 346.861 280.979 346.861 200 280.979 53.139 200 53.139z"/></g>',
    },
});
