/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    instagram: {
        width: 16,
        height: 16,
        viewBox: '0 0 26.71 26.71',
        data:
            '<path pid="0" d="M26.71 13.35A13.36 13.36 0 1 1 13.35 0a13.36 13.36 0 0 1 13.36 13.35z" _fill="#ed3939"/><path pid="1" d="M17 5.13H9.67a4.55 4.55 0 0 0-4.54 4.54V17a4.54 4.54 0 0 0 4.54 4.53H17A4.53 4.53 0 0 0 21.57 17V9.67A4.54 4.54 0 0 0 17 5.13zM20.12 17A3.09 3.09 0 0 1 17 20.12H9.67A3.09 3.09 0 0 1 6.59 17V9.67a3.08 3.08 0 0 1 3.08-3.08H17a3.09 3.09 0 0 1 3.08 3.08z" _fill="#fff"/><path pid="2" d="M13.35 9.12a4.24 4.24 0 1 0 4.24 4.23 4.24 4.24 0 0 0-4.24-4.23zm0 7a2.78 2.78 0 1 1 2.78-2.78 2.77 2.77 0 0 1-2.78 2.78zm4.42-8.24a1.07 1.07 0 1 0 0 2.14 1.06 1.06 0 0 0 .75-.31 1.06 1.06 0 0 0 0-1.52 1 1 0 0 0-.75-.31z" _fill="#fff"/>',
    },
});
