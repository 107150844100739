import _ from 'lodash';
import Vue from 'vue';
export default {
    BACKEND_OVERRIDE: (state, payload) => {
        Vue.set(state, 'apiHost', payload);
    },
    LOGIN: (state, payload) => {
        Vue.set(state.editor, 'allowed', true);
        Vue.set(state.editor, 'user', payload);
    },
    LOGOUT: (state) => {
        Vue.set(state.editor, 'allowed', false);
        if (typeof window !== 'undefined') {
            window.location.href =
                window.location.origin + window.location.search.replace('editing', '');
        }
    },
    INIT_OVERRIDES: (state, payload) => {
        Vue.set(state.config, 'overrides', payload);
    },
    UPDATE_EDITOR(state, payload) {
        state.editor = _.extend({}, state.editor || {}, payload);
    },
    UPDATE_UI: (state, payload) => {
        Vue.set(state, 'ui', _.merge({}, state.ui || {}, payload));
    },
};
