<template>
    <div class="home">
        <Editor v-if="editingAllowed"></Editor>
        <PageHeader />

        <BannerNotice />
        <Banner />
        <Bar class="hidden-xs" />
        <SectionAbout />
        <SectionPanels />
        <SectionTabs />
    </div>
</template>

<script>
import PageHeader from '@/components/Layout/PageHeader.vue';
import Banner from '@/components/Layout/Banner.vue';
import BannerNotice from '@/components/Layout/BannerNotice.vue';
import Bar from '@/components/Layout/Bar.vue';
import SectionAbout from '@/components/SectionAbout.vue';
import SectionPanels from '@/components/SectionPanels.vue';
import SectionTabs from '@/components/SectionTabs.vue';
const Editor = () => import(/* webpackChunkName: "editor" */ '@/components/Editor/EditorBase.vue');

export default {
    name: 'Home',
    components: {
        PageHeader,
        Banner,
        BannerNotice,
        Bar,
        SectionAbout,
        SectionPanels,
        SectionTabs,
        Editor,
    },
};
</script>
