<template>
    <div class="tm">
        <div class="image">
            <img
                v-if="showPhoto"
                :alt="`photo of ${item.name}`"
                :data-src="resizedImage"
                class="lazyload"
                @error="imageError = true"
            />
            <span v-else class="initials">
                <span>{{ item.initials }}</span>
            </span>
        </div>
        <div class="content">
            <ChangeBtn :has-editor="true" type="TeamMember" :context="item" :has-help="false" />

            <h3 class="name">{{ name }}</h3>
            <p class="subtitle">{{ item.position }}</p>

            <MoreText :text="item.description" :threshold="150" />
        </div>
    </div>
</template>

<script>
import 'lazysizes';
import { get as _get, has as _has } from 'lodash';

import MoreText from '@/components/Shared/MoreText.vue';
import { getResized } from '@/utils/ephemera.js';

export default {
    name: 'TeamMember',
    components: { MoreText },
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            imageError: false,
        };
    },
    computed: {
        name() {
            return (
                _get(this.item, 'names.NickName') ||
                this.item.name ||
                _get(this.item, 'names.LegalName')
            );
        },
        showPhoto() {
            const photo_exists = _has(this.item, 'image.profile');
            return photo_exists && !this.imageError;
        },
        resizedImage() {
            return getResized(this.item.image.profile, 200);
        },
    },
};
</script>

<style lang="scss" scoped>
.tm {
    display: flex;

    .image {
        flex-shrink: 0;
        margin-right: 12px;

        img {
            width: 100px;
            border-radius: 8px;
        }

        .initials {
            align-items: center;
            border: 5px solid $green;
            border-radius: 50%;
            display: flex;
            height: 70px;
            justify-content: center;
            width: 70px;

            span {
                align-items: center;
                color: $green;
                display: flex;
                font-size: 2.6rem;
                height: 40px;
                justify-content: center;
                width: 40px;
            }
        }
    }

    .content {
        width: 100%;
    }

    .name {
        font-size: 1.5rem;
        margin-bottom: 0;
        color: $black;
    }

    .subtitle {
        margin-bottom: 5px;
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 1.14;
        color: $black;
    }

    @include media('<lg') {
        .subtitle {
            font-size: 1.2rem;
        }

        .content {
            font-size: 1.2rem;
        }
    }

    @include media('<md') {
        .name {
            font-size: 1.8rem;
        }

        .subtitle {
            margin-bottom: 3px;
            font-size: 1.4rem;
        }
    }
}
</style>
