<template>
    <Tab>
        <Panel :rounded-corners="false">
            <h2 slot="head" class="visible-xs-block">
                <span>
                    <svgicon icon="flag" width="25" height="21" :original="true" />
                </span>
                <strong>Our Mission</strong>
            </h2>

            <div slot="content">
                <div class="mission-wrapper">
                    <p class="mission" v-html="office.mission"></p>
                </div>
            </div>
        </Panel>
    </Tab>
</template>
<script>
import Tab from '@/components/SectionTabs/Tab.vue';
import Panel from '@/components/Shared/Panel.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'Mission',
    components: { Tab, Panel },
    computed: { ...mapGetters(['office']) },
};
</script>
<style lang="scss">
.mission {
    font-size: 15px;
}
@include media(">md") {
    .mission {
        font-size: 20px;
    }
}
</style>
