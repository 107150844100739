import 'regenerator-runtime/runtime';
import Vue from 'vue';
import './utils/plugins.js';
import Keen from '@/utils/Keen';

import { sync } from 'vuex-router-sync';
import 'normalize.css';

import App from './App.vue';
import { createRouter } from './router';
import { createStore } from './store/index.js';
import '@/assets/vendor/bootstrap-grid.min.css';
import './assets/svg/sprite-svg';
import * as filters from '@/utils/filters.js';
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

// register global utility filters.
Object.keys(filters).forEach((key) => {
    Vue.filter(key, filters[key]);
});

extend('required', {
    ...required,
    message: (fieldName) =>
        fieldName === 'More Info selection'
            ? `You must select at least one option in the ${fieldName}`
            : `${fieldName} is required.`,
});

export function createApp(initialState) {
    const store = createStore(initialState);
    const router = createRouter(store);
    // sync so that route state is available as part of the store
    sync(store, router);

    // make sure we can pass some store data via keen events
    Vue.use(Keen, { store });
    Vue.config.productionTip = false;

    const app = new Vue({
        router,
        store,
        render: (h) => h(App),
    });

    return { app, router, store };
}

// generic mixin functions for interacting with the editor that all components can make use of
Vue.mixin({
    methods: {
        stopBodyScroll(stop) {
            const body = window.document.getElementsByTagName('body')[0];
            body.classList[stop ? 'add' : 'remove']('noScroll');
        },
        toggleHelp(force, editor) {
            const showHelp =
                typeof force === 'undefined' ? !this.$store.getters.editor.showHelp : force;
            const editing = editor || this.$store.getters.editor.editing;
            this.$store.commit('UPDATE_EDITOR', { showHelp: showHelp, editing });
        },
        showEditor(editing, context, showHelp = true) {
            this.stopBodyScroll(true);
            this.$store.commit('UPDATE_EDITOR', { editing, context, showHelp });
        },
        hideEditor() {
            this.$store.commit('UPDATE_EDITOR', { editing: null, context: null, showHelp: null });
            this.stopBodyScroll(false);
        },
        disableEditorControls(disable) {
            this.$store.commit('UPDATE_EDITOR', { controls_disabled: disable });
        },
    },
});
