/* eslint-env browser */

import Keen from 'keen-tracking';
import { readMetaTags } from './filters';
import _ from 'lodash';

const extender = function () {
    const meta = _.get(this.store, 'state.meta', readMetaTags());
    return {
        __passthrough: meta.peachy || null,
        codebase: {
            version: meta.version || null,
            branch: meta.branch || null,
        },
    };
};

const plugin = {
    install: function (Vue, { store }) {
        if (typeof window !== 'undefined') {
            const host = process.env.VUE_APP_LOCAL_PEACHY_HOST || `peachy.prod.mirus.io/record`;
            // `${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}/peachy`;
            const client = new Keen({
                host,
                projectId: '5b7df454c9e77c0001287ed9',
                writeKey:
                    'D9982DE0E0B29AC3E087014013C22EEA773248ED3656448F275C15C33E02A92C10B7FB14150F3A417461405FBECBAA219F9211FAC727A413F2BD05BC123D6FC05E9C782EB14A01A27BA4997BA6F8F2C6E121053818FAF4FA7C2FD2133B04B027',
                protocol: process.env.VUE_APP_LOCAL_PEACHY_HOST ? 'http' : `https`,
                requestType: 'xhr', // makes testing a lot easier
            });

            Vue.prototype.$keen = function (...args) {
                return client.recordEvent(...args);
            };

            client.extendEvents(extender.bind({ store }));

            client.initAutoTracking({
                ignoreDisabledFormFields: false,
                ignoreFormFieldTypes: ['password'],
                recordClicks: true,
                recordFormSubmits: true,
                recordPageViews: true,
                recordScrollState: true,
                disableCookies: true,
            });
        }
    },
};

export default plugin;
