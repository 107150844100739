/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    help: {
        width: 24,
        height: 24,
        viewBox: '0 0 24 24',
        data:
            '<path pid="0" d="M12.001 2.085a9.914 9.914 0 0 0-9.916 9.916c0 5.476 4.438 9.914 9.916 9.914a9.913 9.913 0 0 0 9.914-9.914c0-5.478-4.438-9.916-9.914-9.916zm.001 18a8.083 8.083 0 1 1 0-16.167 8.083 8.083 0 0 1 0 16.167z"/><path pid="1" d="M11.766 6.688c-2.5 0-3.219 2.188-3.219 2.188l1.411.854s.298-.791.901-1.229c.516-.375 1.625-.625 2.219.125.701.885-.17 1.587-1.078 2.719C11.047 12.531 11 15 11 15h1.969s.135-2.318 1.041-3.381c.603-.707 1.443-1.338 1.443-2.494s-1.187-2.437-3.687-2.437zM11 16h2v2h-2z"/>',
    },
});
