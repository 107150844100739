<template>
    <Tab>
        <Panel :rounded-corners="false">
            <h2 slot="head" class="visible-xs-block">
                <span>
                    <svgicon icon="envelope" width="23" height="22" :original="true" />
                </span>

                <strong>Contact Us</strong>
            </h2>

            <div slot="content">
                <ContactMe />
            </div>
        </Panel>
    </Tab>
</template>
<script>
import Tab from '@/components/SectionTabs/Tab.vue';
import Panel from '@/components/Shared/Panel.vue';
import ContactMe from '@/components/SectionTabs/ContactMe.vue';

export default {
    name: 'Contact',
    components: { Tab, Panel, ContactMe },
};
</script>
