<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import { querystring } from '@/lib/utils';
import { extend } from 'vee-validate';

export default {
    data() {
        return {
            quoteForm: {
                lob: 'au_qq',
                postal: null,
            },
        };
    },
    computed: {
        ...mapGetters(['office', 'agent', 'meta', 'phone', 'c1Url']),
    },
    mounted() {
        extend('validPostalCode', {
            validate: (pc) => {
                if (!pc) {
                    return false;
                }
                const ca_re = /^([ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ])[ -]?(\d[ABCEGHJKLMNPRSTVWXYZ]\d)$/;
                return RegExp(ca_re.source).test(pc.toUpperCase());
            },
            message: `A valid Postal Code is required. For example: G6V 6P3.`,
        });
        extend('quoteType', {
            validate: (option) => {
                return this.office.quoteOptions.map(([, code]) => code).includes(option);
            },
            message: `Insurance Type is required.`,
        });
    },
    methods: {
        getQuoteBaseURL(lineOfBusiness) {
            if (lineOfBusiness === 'au_qq') {
                return 'https://clients.desjardinsagents.com/vehicle-quote/init/welcome';
            }
            return 'https://services.desjardinsagents.com/Init';
        },
        submitQuoteRequest() {
            let { lob, postal } = this.quoteForm;
            postal = postal.toUpperCase();
            const identifier = this.agent.code;
            const domain = this.meta.domain;
            const c1Url = this.c1Url;

            const qsvals = {
                lng: 'en', // language
                mca: 'e', //
                cs: lob, // line of business
                cp: postal, // postalCode
                agent: identifier, // agent id
            };

            let quoteRedirected = false; // prevent double pops

            const quoteRedirect = _.bind(function () {
                if (quoteRedirected) {
                    return;
                }
                const baseURL = this.getQuoteBaseURL(lob);
                let destUrl = `${baseURL}${this.$store.getters.desjardinsQueryParams}&${querystring.encode(qsvals)}`;

                if (typeof cookieDesjardins_createExternalConsent !== 'undefined') {
                    // eslint-disable-next-line
                    destUrl += `&${cookieDesjardins_createExternalConsent()}`;
                }

                // some iOS versions and other mobile browsers don't like the popup
                try {
                    let win = window.open(destUrl, '_blank');
                    win.focus();
                } catch (error) {
                    window.location.href = destUrl;
                }
                quoteRedirected = true;
            }, this); // we need to access the store

            // if we can't record the quotestart in time, go with what you know
            const fallbackRedirect = setTimeout(quoteRedirect, 1500);

            this.$keen('quotestart', {
                lob,
                zipcode: postal,
                cmpid: this.$store.state.route.query.cmpid,
            }).then(({ event_id }) => {
                // console.log('result', event_id);
                clearTimeout(fallbackRedirect);
                // update qsVals
                qsvals.returnURL = `${c1Url}/quoteresult/${event_id}/{ReturnStatus}/?domain=${domain}`;
                quoteRedirect();
                // console.log('quotestart returned, canceling fallbackPopper');
            });
        },
    },
};
</script>
