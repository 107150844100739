<template>
    <div class="panel" :class="{ expand: expanded, 'rounded-corners': roundedCorners}">
        <div class="head" @click="expanded = !expanded">
            <slot name="head"></slot>
        </div>

        <div class="panel-content">
            <div class="inner">
                <slot name="content"></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Panel',
    props: {
        roundedCorners: {
            type: Boolean,
            default: true,
        }
    },
    data: () => {
        return { expanded: false };
    },
};
</script>

<style lang="scss" scoped>
.rounded-corners {
    border-radius: 12px;
}

.panel {
    background: #fff;
    margin-bottom: 20px;
    overflow: hidden;
    
    &:last-child {
        margin-bottom: 0;
    }

    .subtitle {
        margin-bottom: 17px;
    }

    h2 {
        font-size: 2rem;
        color: $green;
        padding: 10px 12px 0;
    }

    .group + .group {
        margin-top: 5px;
    }

    aside {
        flex-shrink: 0;
        margin-left: 70px;
        margin-right: 10px;
    }

    @include media('<md') {
        & {
            margin: 0 -15px;
            border-radius: 0;
        }

        & + .panel {
            margin-top: 1px;
        }

        .head {
            position: relative;
            padding: 9px 18px;
            background: $light-gray;
            cursor: pointer;

            &:after {
                content: '';
                position: absolute;
                top: 50%;
                right: 15px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 5.5px 9px 5.5px 0;
                border-color: transparent $black transparent transparent;
                margin-top: -5px;
                transition: transform 0.3s;
                transform: rotate(-90deg);
            }

            span {
                display: inline-block;
                min-width: 38px;
                font-size: 0;
                line-height: 1;
            }
        }

        h2 {
            display: flex;
            align-items: center;
            padding: 0;
            border-bottom: 0;
            font-size: 18px;
            color: $black;
        }

        .inner {
            padding: 15px;
            font-size: 15px;
        }

        .panel-content {
            overflow: hidden;
            max-height: 0;
            transition: max-height 0.5s ease-in-out;
        }

        .articles {
            margin: -15px;
        }

        &.expand {
            .head {
                &:after {
                    transform: rotate(90deg);
                }
            }

            .panel-content {
                max-height: 2000px;
            }
        }
    }

    @include media('<sm') {
        .content {
            h4 {
                font-size: 20px;
            }
        }

        aside {
            margin-left: 15px;
            margin-right: 0;
        }
    }
}
</style>
