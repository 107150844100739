<template>
    <header class="header">
        <div class="container">
            <div class="row align-items-center">
                <div class="col">
                    <a
                        :href="`https://www.desjardins.com/on/en/insurance/agents.html${$store.getters.desjardinsQueryParams}`"
                        target="_blank"
                        aria-describedby="new-window-warning"
                        rel="noopener noreferrer"
                        role="link"
                        aria-label="desjardins insurance logo"
                        title="Navigate to Desjardins Insurance"
                        class="logo consentRedirect"
                    >
                        <img
                            alt="Desjardins Insurance Agent"
                            class="lazyload"
                            :data-src="`${$store.state.publicPath}img/insurance-logo-small.png`"
                            width="200"
                        />
                    </a>
                </div>

                <div v-if="$store.getters.office.phones.position === 'header'" class="hidden-xs">
                    <a :href="'tel: ' + $store.getters.phone.stripped">
                        {{ $store.getters.phone.pretty }}
                    </a>
                    <ChangeBtn :has-editor="true" type="PhoneNumberPlacement" />
                </div>

                <div class="col">
                    <div class="content">
                        <NavUtilities :show-log-on-btn="showLogOnBtn" />
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import NavUtilities from '@/components/Layout/NavUtilities.vue';

export default {
    components: {
        NavUtilities,
    },
    props: { showLogOnBtn: { type: Boolean, default: true } },
};
</script>

<style lang="scss" scoped>
.header {
    border-bottom: 1px $black solid;
    padding: 2px 0 0 0;
    background: #fff;

    h1 {
        margin: 0;
    }

    .content {
        display: flex;
        justify-content: flex-end;
    }
}

.logo {
    display: inline-block;
    vertical-align: middle;
    font-size: 0;
    line-height: 1;
}
</style>
