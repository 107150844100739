import { isNil, get, toInteger } from 'lodash';
import { format, parse, parseISO } from 'date-fns';

function toObj(date) {
    if (typeof date === 'string') {
        return {
            hours: toInteger(format(parseISO(date), 'HH')),
            minutes: toInteger(format(parseISO(date), 'mm')),
        };
    }
    return {
        hours: toInteger(format(date, 'HH')),
        minutes: toInteger(format(date, 'mm')),
    };
}

function toDate(obj) {
    if (typeof obj === 'string') {
        return parseISO(obj);
    }
    return parse(`${obj.hours}:${obj.minutes}`, 'HH:mm', new Date());
}

// represent the actual times as simple object { hours: int, minutes: int }
function simplify(hours) {
    return hours.map((day) => {
        if (isNil(day.times)) {
            return day;
        }

        const times = get(day, 'times').map((time) => {
            return {
                start: toObj(time.start),
                end: toObj(time.end),
            };
        });
        return { ...day, times };
    });
}

// represent the actual times as Date objects
function complicate(hours) {
    return hours.map((day) => {
        if (isNil(day.times)) {
            return day;
        }

        const times = get(day, 'times', []).map((time) => ({
            start: toDate(time.start),
            end: toDate(time.end),
        }));

        return { ...day, times };
    });
}

function isComplicated(hours) {
    const start = get(hours, '[0].times[0].start');
    if (typeof start !== 'string') return false;
    const date = new Date(start);
    return date instanceof Date;
}

const TIMEZONE_MAP = {
    'America/Edmonton': 'Mountain',
    'America/Moncton': 'Atlantic',
    'America/New_York': 'Eastern',
    'America/Toronto': 'Eastern',
};

function mapTimezone(timezone) {
    return get(TIMEZONE_MAP, timezone, timezone);
}

export { simplify, complicate, isComplicated, mapTimezone };
