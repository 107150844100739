/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    alert: {
        width: 300,
        height: 300,
        viewBox: '0 0 96 96',
        data:
            '<path pid="0" d="M74.1 90H21.9c-4.3 0-8.2-2.2-10.4-6s-2.2-8.3 0-12l26.1-45c2.2-3.7 6.1-6 10.4-6s8.2 2.2 10.4 6l26.1 45c2.2 3.8 2.2 8.2 0 12s-6 6-10.4 6zM18.4 76c-1 1.8-.4 3.4 0 4s1.4 2 3.5 2h52.2c2.1 0 3.1-1.4 3.5-2s1-2.2 0-4L51.5 31c-1-1.8-2.8-2-3.5-2s-2.4.2-3.5 2L18.4 76z"/><path pid="1" d="M44 43h8v23h-8zM44 70h8v8h-8z"/>',
    },
});
