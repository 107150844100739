/**
 * @author Anatoly Mironov (mirontoli) Alec Troemel
 * http://sharepointkunskap.wordpress.com
 * http://www.bool.se
 *
 * http://stackoverflow.com/questions/3605495/generate-a-list-of-localized-language-names-with-links-to-google-translate/14800384#14800384
 * http://stackoverflow.com/questions/10997128/language-name-from-iso-639-1-code-in-javascript/14800499#14800499
 *
 * using Phil Teare's answer on stackoverflow
 * http://stackoverflow.com/questions/3217492/list-of-language-codes-in-yaml-or-json/4900304#4900304
 * Just for testing only. Incorporate in your own javascript namespace
 * Example: getLanguageName("cv-RU") --> Chuvash
 */

/**
 * now uses the official desjardin language mapping from their docs
 */
const isoLangs = {
    aa: {
        frenchName: 'Afar',
        name: 'Afar',
        nativeName: 'Afar',
    },
    ab: {
        frenchName: 'Abkhaze',
        name: 'Abkhaz',
        nativeName: 'Аҧсуа',
    },
    ae: {
        frenchName: 'Avestique',
        name: 'Avestan',
        nativeName: 'Avesta',
    },
    af: {
        frenchName: 'Afrikaans',
        name: 'Afrikaans',
        nativeName: 'Afrikaans',
    },
    ak: {
        frenchName: 'Akan',
        name: 'Akan',
        nativeName: 'Akan',
    },
    al: {
        frenchName: 'Langue des signes américaine',
        name: 'American Sign Language',
        nativeName: 'American Sign Language',
    },
    am: {
        frenchName: 'Amharique',
        name: 'Amharic',
        nativeName: 'አማርኛ',
    },
    an: {
        frenchName: 'Aragonais',
        name: 'Aragonese',
        nativeName: 'Aragonés',
    },
    ar: {
        frenchName: 'Arabe',
        name: 'Arabic',
        nativeName: 'عربى',
    },
    as: {
        frenchName: 'Assamais',
        name: 'Assamese',
        nativeName: 'অসমীয়া',
    },
    av: {
        frenchName: 'Avar',
        name: 'Avaric',
        nativeName: 'авар мацӀ ; магӀарул мацӀ',
    },
    ay: {
        frenchName: 'Aymara',
        name: 'Aymara',
        nativeName: 'Aymar aru',
    },
    az: {
        frenchName: 'Azéri',
        name: 'Azerbaijani',
        nativeName: 'Azərbaycan dili',
    },
    ba: {
        frenchName: 'Bachkir',
        name: 'Bashkir',
        nativeName: 'башҡорт теле',
    },
    be: {
        frenchName: 'Biélorusse',
        name: 'Belarusian',
        nativeName: 'Беларуская',
    },
    bg: {
        frenchName: 'Bulgare',
        name: 'Bulgarian',
        nativeName: 'български език',
    },
    bh: {
        frenchName: 'Bihari',
        name: 'Bihari',
        nativeName: 'भोजपुरी',
    },
    bi: {
        frenchName: 'Bichlamar',
        name: 'Bislama',
        nativeName: 'Bislama',
    },
    bm: {
        frenchName: 'Bambara',
        name: 'Bambara',
        nativeName: 'Bamanankan',
    },
    bn: {
        frenchName: 'Bengali',
        name: 'Bengali, Bangla',
        nativeName: 'বাংলা',
    },
    bo: {
        frenchName: 'Tibétain',
        name: 'Tibetan Standard, Tibetan, Central',
        nativeName: 'བོད་ཡིག',
    },
    br: {
        frenchName: 'Breton',
        name: 'Breton',
        nativeName: 'Brezhoneg',
    },
    bs: {
        frenchName: 'Bosnien',
        name: 'Bosnian',
        nativeName: 'Bosanski jezik',
    },
    ca: {
        frenchName: 'Catalan',
        name: 'Catalan',
        nativeName: 'Català',
    },
    ce: {
        frenchName: 'Tchétchène',
        name: 'Chechen',
        nativeName: 'нохчийн мотт',
    },
    ch: {
        frenchName: 'Chamorro',
        name: 'Chamorro',
        nativeName: 'Chamoru',
    },
    co: {
        frenchName: 'Corse',
        name: 'Corsican',
        nativeName: 'Corsu ; lingua corsa',
    },
    cr: {
        frenchName: 'Cri',
        name: 'Cree',
        nativeName: 'ᓀᐦᐃᔭᐍᐏᐣ',
    },
    cs: {
        frenchName: 'Tchèque',
        name: 'Czech',
        nativeName: 'Česky',
    },
    ct: {
        frenchName: 'Chinois (cantonais)',
        name: 'Chinese (Cantonese)',
        nativeName: '廣東話',
    },
    cv: {
        frenchName: 'Tchouvache',
        name: 'Chuvash',
        nativeName: 'чӑваш чӗлхи',
    },
    cy: {
        frenchName: 'Gallois',
        name: 'Welsh',
        nativeName: 'Cymraeg',
    },
    da: {
        frenchName: 'Danois',
        name: 'Danish',
        nativeName: 'Danske',
    },
    de: {
        frenchName: 'Allemand',
        name: 'German',
        nativeName: 'Deutsch',
    },
    dr: {
        frenchName: 'Dari',
        name: 'Dari',
        nativeName: 'دری',
    },
    dv: {
        frenchName: 'Maldivien',
        name: 'Divehi, Dhivehi, Maldivian',
        nativeName: '\u202bދިވެހި',
    },
    dz: {
        frenchName: 'Dzongkha',
        name: 'Dzongkha',
        nativeName: 'རྫོང་ཁ',
    },
    ee: {
        frenchName: 'Éwé',
        name: 'Ewe',
        nativeName: 'Ɛʋɛgbɛ',
    },
    eh: {
        frenchName: 'Amharique (Éthiopie)',
        name: 'Ethiopian (Amharic)',
        nativeName: 'አማርኛ',
    },
    el: {
        frenchName: 'Grec',
        name: 'Greek',
        nativeName: 'Ελληνικά',
    },
    en: {
        frenchName: 'Anglais',
        name: 'English',
        nativeName: 'English',
    },
    eo: {
        frenchName: 'Espéranto',
        name: 'Esperanto',
        nativeName: 'Esperanto',
    },
    es: {
        frenchName: 'Espagnol',
        name: 'Spanish',
        nativeName: 'Español',
    },
    et: {
        frenchName: 'Estonien',
        name: 'Estonian',
        nativeName: 'Eesti keel',
    },
    eu: {
        frenchName: 'Basque',
        name: 'Basque',
        nativeName: 'Euskara',
    },
    fa: {
        frenchName: 'Persan',
        name: 'Persian, Farsi',
        nativeName: 'فارسی',
    },
    fs: {
        frenchName: 'Persan',
        name: 'Persian, Farsi',
        nativeName: 'فارسی',
    },
    ff: {
        frenchName: 'Peul',
        name: 'Fula, Fulah, Pulaar, Pular',
        nativeName: 'Fulfulde',
    },
    fi: {
        frenchName: 'Finnois',
        name: 'Finnish',
        nativeName: 'Suomen kieli',
    },
    fj: {
        frenchName: 'Fidjien',
        name: 'Fijian',
        nativeName: 'Vosa Vakaviti',
    },
    fk: {
        frenchName: 'Chinois (fou-kien)',
        name: 'Chinese (Fukienese)',
        nativeName: '福建话',
    },
    fo: {
        frenchName: 'Féroïen, féringien',
        name: 'Faroese',
        nativeName: 'Føroyskt',
    },
    fr: {
        frenchName: 'Français',
        name: 'French',
        nativeName: 'Français',
    },
    fy: {
        frenchName: 'Frison occidental',
        name: 'Western Frisian',
        nativeName: 'Frysk',
    },
    fz: {
        frenchName: 'Chinois (Fuzhou) ',
        name: 'Chinese (Fuzhou or Fuzhounese)',
        nativeName: '福州话',
    },
    ga: {
        frenchName: 'Irlandais',
        name: 'Irish',
        nativeName: 'Gaeilge',
    },
    gd: {
        frenchName: 'Écossais',
        name: 'Scottish Gaelic, Gaelic',
        nativeName: 'Gàidhlig',
    },
    gl: {
        frenchName: 'Galicien',
        name: 'Galician',
        nativeName: 'Galego',
    },
    gn: {
        frenchName: 'Guarani',
        name: 'Guaraní',
        nativeName: "Avañe'ẽ",
    },
    gu: {
        frenchName: 'Gujarati',
        name: 'Gujarati',
        nativeName: 'ગુજરાતી',
    },
    gv: {
        frenchName: 'Mannois',
        name: 'Manx',
        nativeName: 'Ghaelg',
    },
    ha: {
        frenchName: 'Haoussa',
        name: 'Hausa',
        nativeName: '\u202bهَوُسَ',
    },
    he: {
        frenchName: 'Hébreu',
        name: 'Hebrew',
        nativeName: 'עִבְרִית',
    },
    hi: {
        frenchName: 'Hindi',
        name: 'Hindi',
        nativeName: 'हिन्दी',
    },
    hk: {
        frenchName: 'Chinois (hakka)',
        name: 'Chinese (Hakka)',
        nativeName: '客家语',
    },
    ho: {
        frenchName: 'Hiri motu',
        name: 'Hiri Motu',
        nativeName: 'Hiri Motu',
    },
    hr: {
        frenchName: 'Croate',
        name: 'Croatian',
        nativeName: 'Hrvatski',
    },
    ht: {
        frenchName: 'Créole (Haïti)',
        name: 'Haitian',
        nativeName: 'Kreyòl ayisyen',
    },
    hu: {
        frenchName: 'Hongrois',
        name: 'Hungarian',
        nativeName: 'Magyar Nyelv',
    },
    hy: {
        frenchName: 'Arménien',
        name: 'Armenian',
        nativeName: 'Հայերեն',
    },
    hz: {
        frenchName: 'Héréro',
        name: 'Herero',
        nativeName: 'Otjiherero',
    },
    ia: {
        frenchName: 'Interlingua',
        name: 'Interlingua',
        nativeName: 'Interlingua',
    },
    id: {
        frenchName: 'Indonésien',
        name: 'Indonesian',
        nativeName: 'Bahasa Indonesia',
    },
    ie: {
        frenchName: 'Interlingue, occidental',
        name: 'Interlingue, Occidental',
        nativeName: 'Interlingue',
    },
    ig: {
        frenchName: 'Igbo',
        name: 'Igbo',
        nativeName: 'Igbo',
    },
    ii: {
        frenchName: 'Yi',
        name: 'Nuosu',
        nativeName: 'ꆇꉙ',
    },
    ik: {
        frenchName: 'Inupiak',
        name: 'Inupiaq',
        nativeName: 'Iñupiaq ; Iñupiatun',
    },
    io: {
        frenchName: 'Ido',
        name: 'Ido',
        nativeName: 'Ido',
    },
    ir: {
        frenchName: 'Iranien',
        name: 'Iranian',
        nativeName: 'ایرانی',
    },
    is: {
        frenchName: 'Islandais',
        name: 'Icelandic',
        nativeName: 'Íslenska',
    },
    it: {
        frenchName: 'Italien',
        name: 'Italian',
        nativeName: 'Italiano',
    },
    iu: {
        frenchName: 'Inuktitut',
        name: 'Inuktitut',
        nativeName: 'ᐃᓄᒃᑎᑐᑦ',
    },
    ja: {
        frenchName: 'Japonais',
        name: 'Japanese',
        nativeName: '日本語',
    },
    jv: {
        frenchName: 'Javanais',
        name: 'Javanese',
        nativeName: 'Basa Jawa',
    },
    ka: {
        frenchName: 'Géorgien',
        name: 'Georgian',
        nativeName: 'ქართული',
    },
    kg: {
        frenchName: 'Kikongo',
        name: 'Kongo',
        nativeName: 'KiKongo',
    },
    ki: {
        frenchName: 'Kikuyu',
        name: 'Kikuyu, Gikuyu',
        nativeName: 'Gĩkũyũ',
    },
    kj: {
        frenchName: 'Kuanyama',
        name: 'Kwanyama, Kuanyama',
        nativeName: 'Kuanyama',
    },
    kk: {
        frenchName: 'Kazakh',
        name: 'Kazakh',
        nativeName: 'Қазақ тілі',
    },
    kl: {
        frenchName: 'Groenlandais',
        name: 'Kalaallisut, Greenlandic',
        nativeName: 'Kalaallisut; kalaallit oqaasii',
    },
    km: {
        frenchName: 'Khmer',
        name: 'Khmer',
        nativeName: 'ភាសាខ្មែរ',
    },
    kn: {
        frenchName: 'Kannada',
        name: 'Kannada',
        nativeName: 'ಕನ್ನಡ',
    },
    ko: {
        frenchName: 'Coréen',
        name: 'Korean',
        nativeName: '한국어',
    },
    kr: {
        frenchName: 'Kanouri, kanuri',
        name: 'Kanuri',
        nativeName: 'Kanuri',
    },
    ks: {
        frenchName: 'Cachemiri',
        name: 'Kashmiri',
        nativeName: 'कश्मीरी ; كشميري',
    },
    ku: {
        frenchName: 'Kurde',
        name: 'Kurdish',
        nativeName: 'Kurdî ; كوردی',
    },
    kv: {
        frenchName: 'Komi',
        name: 'Komi',
        nativeName: 'коми кыв',
    },
    kw: {
        frenchName: 'Cornique',
        name: 'Cornish',
        nativeName: 'Kernewek',
    },
    ky: {
        frenchName: 'Kirghiz',
        name: 'Kyrgyz',
        nativeName: 'кыргыз тили',
    },
    la: {
        frenchName: 'Latin',
        name: 'Latin',
        nativeName: 'Latine; lingua latina',
    },
    lb: {
        frenchName: 'Luxembourgeois',
        name: 'Luxembourgish, Letzeburgesch',
        nativeName: 'Lëtzebuergesch',
    },
    lg: {
        frenchName: 'Ganda',
        name: 'Ganda',
        nativeName: 'Luganda',
    },
    li: {
        frenchName: 'Limbourgeois',
        name: 'Limburgish, Limburgan, Limburger',
        nativeName: 'Limburgs',
    },
    ln: {
        frenchName: 'Lingala',
        name: 'Lingala',
        nativeName: 'Lingála',
    },
    lo: {
        frenchName: 'Lao',
        name: 'Lao',
        nativeName: 'ພາສາລາວ',
    },
    lt: {
        frenchName: 'Lituanien',
        name: 'Lithuanian',
        nativeName: 'Lietuvių kalba',
    },
    lu: {
        frenchName: 'Luba-katanga',
        name: 'Luba-Katanga',
        nativeName: 'kiluba',
    },
    lv: {
        frenchName: 'Letton',
        name: 'Latvian',
        nativeName: 'Latviešu valoda',
    },
    md: {
        frenchName: 'Chinois (mandarin)',
        name: 'Chinese (Mandarin)',
        nativeName: '普通话',
    },
    mg: {
        frenchName: 'Malgache',
        name: 'Malagasy',
        nativeName: 'Fiteny malagasy',
    },
    mh: {
        frenchName: 'Marshallais',
        name: 'Marshallese',
        nativeName: 'Kajin M̧ajeļ',
    },
    mi: {
        frenchName: 'Maori (Nouvelle-Zélande)',
        name: 'Maori',
        nativeName: 'Te reo Māori',
    },
    mk: {
        frenchName: 'Macédonien',
        name: 'Macedonian',
        nativeName: 'македонски јазик',
    },
    ml: {
        frenchName: 'Malayalam',
        name: 'Malayalam',
        nativeName: 'മലയാളം',
    },
    mn: {
        frenchName: 'Mongol',
        name: 'Mongolian',
        nativeName: 'Монгол',
    },
    mr: {
        frenchName: 'Marathe',
        name: 'Marathi',
        nativeName: 'मराठी',
    },
    ms: {
        frenchName: 'Malais',
        name: 'Malay',
        nativeName: 'Bahasa Malaysia',
    },
    mt: {
        frenchName: 'Maltais',
        name: 'Maltese',
        nativeName: 'Malti',
    },
    my: {
        frenchName: 'Birman',
        name: 'Burmese',
        nativeName: 'ဗမာစာ',
    },
    na: {
        frenchName: 'Nauruan',
        name: 'Nauruan',
        nativeName: 'Ekakairũ Naoero',
    },
    nb: {
        frenchName: 'Norvégien (bokmål)',
        name: 'Norwegian Bokmål',
        nativeName: 'Norsk bokmål',
    },
    nd: {
        frenchName: 'Sindebele',
        name: 'Northern Ndebele',
        nativeName: 'isiNdebele',
    },
    ne: {
        frenchName: 'Népali (népalais)',
        name: 'Nepali',
        nativeName: 'नेपाली',
    },
    ng: {
        frenchName: 'Ndonga',
        name: 'Ndonga',
        nativeName: 'Owambo',
    },
    nj: {
        frenchName: 'Chinois (Nanjing)',
        name: 'Chinese (Nanjing, Nankinese)',
        nativeName: '南京话',
    },
    nl: {
        frenchName: 'Néerlandais',
        name: 'Dutch',
        nativeName: 'Nederlands',
    },
    nn: {
        frenchName: 'Norvégien (nynorsk)',
        name: 'Norwegian Nynorsk',
        nativeName: 'Norsk nynorsk',
    },
    no: {
        frenchName: 'Norvégien',
        name: 'Norwegian',
        nativeName: 'Norsk',
    },
    nr: {
        frenchName: 'Ndébélé',
        name: 'Southern Ndebele',
        nativeName: 'Ndébélé',
    },
    nv: {
        frenchName: 'Navajo',
        name: 'Navajo, Navaho',
        nativeName: 'Diné bizaad; Dinékʼehǰí',
    },
    ny: {
        frenchName: 'Chichewa',
        name: 'Chichewa, Chewa, Nyanja',
        nativeName: 'ChiCheŵa; chinyanja',
    },
    oc: {
        frenchName: 'Occitan',
        name: 'Occitan',
        nativeName: 'Occitan',
    },
    oj: {
        frenchName: 'Ojibwé',
        name: 'Ojibwe, Ojibwa',
        nativeName: 'ᐊᓂᔑᓈᐯᒧᐎᓐ',
    },
    om: {
        frenchName: 'Oromo',
        name: 'Oromo',
        nativeName: 'Afaan Oromoo',
    },
    or: {
        frenchName: 'Oriya',
        name: 'Oriya',
        nativeName: 'ଓଡ଼ିଆ',
    },
    os: {
        frenchName: 'Ossète',
        name: 'Ossetian, Ossetic',
        nativeName: 'Ирон ӕвзаг',
    },
    pa: {
        frenchName: 'Pendjabi',
        name: 'Punjabi',
        nativeName: 'ਪੰਜਾਬੀ',
    },
    pi: {
        frenchName: 'Pali',
        name: 'Pali',
        nativeName: 'पािऴ',
    },
    pl: {
        frenchName: 'Polonais',
        name: 'Polish',
        nativeName: 'polski',
    },
    ps: {
        frenchName: 'Pachto, pachtou',
        name: 'Pashto, Pushto',
        nativeName: '\u202bپښتو',
    },
    pt: {
        frenchName: 'Portugais',
        name: 'Portuguese',
        nativeName: 'Português',
    },
    qu: {
        frenchName: 'Quechua',
        name: 'Quechua',
        nativeName: 'Runa Simi; Kichwa',
    },
    rm: {
        frenchName: 'Romanche',
        name: 'Romansh',
        nativeName: 'Rumantsch grischun',
    },
    rn: {
        frenchName: 'Kiroundi, kirundi',
        name: 'Kirundi',
        nativeName: 'kiRundi',
    },
    ro: {
        frenchName: 'Roumain',
        name: 'Romanian',
        nativeName: 'Română',
    },
    ru: {
        frenchName: 'Russe',
        name: 'Russian',
        nativeName: 'Pyccкий',
    },
    rw: {
        frenchName: 'Kinyarwanda',
        name: 'Kinyarwanda',
        nativeName: 'Kinyarwanda',
    },
    sa: {
        frenchName: 'Sanskrit',
        name: 'Sanskrit',
        nativeName: 'संस्कृतम्',
    },
    sc: {
        frenchName: 'Sarde',
        name: 'Sardinian',
        nativeName: 'sardu',
    },
    sd: {
        frenchName: 'Sindhi',
        name: 'Sindhi',
        nativeName: 'सिन्धी ; ‫سنڌي، سندھی',
    },
    se: {
        frenchName: 'Same du Nord',
        name: 'Northern Sami',
        nativeName: 'Davvisámegiella',
    },
    sg: {
        frenchName: 'Sango',
        name: 'Sango',
        nativeName: 'Yângâ tî sängö',
    },
    sh: {
        frenchName: 'Chinois (Shanghai)',
        name: 'Chinese (Shanghainese)',
        nativeName: '上海话',
    },
    si: {
        frenchName: 'Cinghalais',
        name: 'Sinhalese, Sinhala',
        nativeName: 'සිංහල',
    },
    sk: {
        frenchName: 'Slovaque',
        name: 'Slovak',
        nativeName: 'Slovenčina',
    },
    sl: {
        frenchName: 'Slovène',
        name: 'Slovene',
        nativeName: 'Slovenščina',
    },
    sm: {
        frenchName: 'Samoan',
        name: 'Samoan',
        nativeName: "Gagana fa'a Samoa",
    },
    sn: {
        frenchName: 'Shona',
        name: 'Shona',
        nativeName: 'chiShona',
    },
    so: {
        frenchName: 'Somali',
        name: 'Somali',
        nativeName: 'Soomaaliga; af Soomaali',
    },
    sq: {
        frenchName: 'Albanais',
        name: 'Albanian',
        nativeName: 'Shqip',
    },
    sr: {
        frenchName: 'Serbe',
        name: 'Serbian',
        nativeName: 'српски језик',
    },
    ss: {
        frenchName: 'Swati',
        name: 'Swati',
        nativeName: 'SiSwati',
    },
    st: {
        frenchName: 'Sotho du Sud',
        name: 'Southern Sotho',
        nativeName: 'seSotho',
    },
    su: {
        frenchName: 'Soundanais',
        name: 'Sundanese',
        nativeName: 'Basa Sunda',
    },
    sv: {
        frenchName: 'Suédois',
        name: 'Swedish',
        nativeName: 'Svenska',
    },
    sw: {
        frenchName: 'Swahili',
        name: 'Swahili',
        nativeName: 'Kiswahili',
    },
    sz: {
        frenchName: 'Chinois (Sichuan)',
        name: 'Chinese (Sichuanese)',
        nativeName: '四川话',
    },
    t1: {
        frenchName: 'Chinois (Taiwan)',
        name: 'Chinese (Taiwanese)',
        nativeName: '台湾闽南语',
    },
    ta: {
        frenchName: 'Tamoul',
        name: 'Tamil',
        nativeName: 'தமிழ்',
    },
    te: {
        frenchName: 'Télougou',
        name: 'Telugu',
        nativeName: 'తెలుగు',
    },
    tg: {
        frenchName: 'Tadjik',
        name: 'Tajik',
        nativeName: 'тоҷикӣ ; toğikī ; ‫تاجیکی',
    },
    th: {
        frenchName: 'Thaï',
        name: 'Thai',
        nativeName: 'ไทย',
    },
    ti: {
        frenchName: 'Tigrigna',
        name: 'Tigrinya',
        nativeName: 'ትግርኛ',
    },
    tk: {
        frenchName: 'Turkmène',
        name: 'Turkmen',
        nativeName: 'Türkmen; Түркмен',
    },
    tl: {
        frenchName: 'Tagalog, tagal, filipino',
        name: 'Tagalog',
        nativeName: 'Tagalog',
    },
    tn: {
        frenchName: 'Setswana',
        name: 'Tswana',
        nativeName: 'seTswana',
    },
    to: {
        frenchName: 'Tonguien',
        name: 'Tonga (Tonga Islands)',
        nativeName: 'faka Tonga',
    },
    tr: {
        frenchName: 'Turc',
        name: 'Turkish',
        nativeName: 'Türkçe',
    },
    ts: {
        frenchName: 'Tsonga',
        name: 'Tsonga',
        nativeName: 'xiTsonga',
    },
    tt: {
        frenchName: 'Tatar',
        name: 'Tatar',
        nativeName: 'татарча ; tatarça ; ‫تاتارچا',
    },
    tw: {
        frenchName: 'Twi',
        name: 'Twi',
        nativeName: 'Twi',
    },
    ty: {
        frenchName: 'Tahitien',
        name: 'Tahitian',
        nativeName: 'Reo Mā`ohi',
    },
    ug: {
        frenchName: 'Ouïghour, ouïgour',
        name: 'Uyghur',
        nativeName: 'Uyƣurqə ; ‫ئۇيغۇرچ',
    },
    uk: {
        frenchName: 'Ukrainien',
        name: 'Ukrainian',
        nativeName: 'украї́нська мо́ва',
    },
    ur: {
        frenchName: 'Ourdou',
        name: 'Urdu',
        nativeName: 'اردو',
    },
    uz: {
        frenchName: 'Ouzbek',
        name: 'Uzbek',
        nativeName: "O'zbek ; Ўзбек ; أۇزبېك",
    },
    ve: {
        frenchName: 'Venda',
        name: 'Venda',
        nativeName: 'tshiVenḓa',
    },
    vi: {
        frenchName: 'Vietnamien',
        name: 'Vietnamese',
        nativeName: 'Tiẽng Việt',
    },
    vo: {
        frenchName: 'Volapük',
        name: 'Volapuk',
        nativeName: 'Volapük',
    },
    wa: {
        frenchName: 'Wallon',
        name: 'Walloon',
        nativeName: 'Walon',
    },
    wo: {
        frenchName: 'Wolof',
        name: 'Wolof',
        nativeName: 'Wollof',
    },
    xh: {
        frenchName: 'Xhosa',
        name: 'Xhosa',
        nativeName: 'isiXhosa',
    },
    yi: {
        frenchName: 'Yiddish',
        name: 'Yiddish',
        nativeName: 'ייִדיש',
    },
    yo: {
        frenchName: 'Yoruba',
        name: 'Yoruba',
        nativeName: 'Yorùbá',
    },
    za: {
        frenchName: 'Zhuang',
        name: 'Zhuang, Chuang',
        nativeName: 'Saɯ cueŋƅ ; Saw cuengh; 壯語',
    },
    zh: {
        frenchName: 'Chinois',
        name: 'Chinese',
        nativeName: '中文',
    },
    zu: {
        frenchName: 'Zoulou',
        name: 'Zulu',
        nativeName: 'isiZulu',
    },
};

const getLanguageName = function(key) {
    key = key.slice(0, 2);
    const lang = isoLangs[key];
    return lang ? lang.name : undefined;
};

const getLanguageNativeName = function(key) {
    key = key.slice(0, 2);
    const lang = isoLangs[key];
    return lang ? lang.nativeName : undefined;
};

export { getLanguageName, getLanguageNativeName };
