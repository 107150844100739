/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    tenant: {
        width: 16,
        height: 16,
        viewBox: '0 0 73 68',
        data:
            '<path pid="0" d="M67.736 14.511h-7.819V7.37h7.82v7.141zm0 11.968h-7.819v-7.133h7.82v7.133zm0 11.979h-7.819v-7.144h7.82v7.144zm0 11.378h-7.819v-7.141h7.82v7.141zM55.277 14.511H47.46V7.37h7.818v7.141zm0 11.968H47.46v-7.133h7.818v7.133zm0 11.979H47.46v-7.144h7.818v7.144zm0 11.378H47.46v-7.141h7.818v7.141zM42.813 14.511h-7.808V7.37h7.808v7.141zm0 11.968h-7.808v-7.133h7.808v7.133zm0 11.979h-7.808v-7.144h7.808v7.144zm0 11.378h-7.808v-7.141h7.808v7.141zM71.928.002h-41.23a.998.998 0 0 0-.994.994v65.12c0 .552.448.995.995.995h16.277V57.12h7.816v9.992h17.136a.991.991 0 0 0 .995-.995V.996a.995.995 0 0 0-.995-.994M23.032 26.559h-7.819v-7.147h7.819v7.147zm0 11.976h-7.819v-7.144h7.819v7.144zm0 11.976h-7.819V43.37h7.819v7.141zM11.309 26.56H3.491v-7.147h7.818v7.147zm0 11.976H3.491v-7.144h7.818v7.144zm0 11.976H3.491V43.37h7.818v7.141zm14.942-37.339H1.005c-.546 0-1 .454-1 1v51.944a1 1 0 0 0 1 .995H9.6V57.12h7.813v9.992h8.838a.991.991 0 0 0 .994-.995V14.178a1.004 1.004 0 0 0-.994-1.006" _fill="#fff" fill-rule="nonzero"/>',
    },
});
