<template>
    <div class="social-feed">
        <FaceBook v-if="socialFeed === 'Facebook'" />
        <Twitter v-if="socialFeed === 'Twitter'" />
    </div>
</template>

<script>
import FaceBook from '@/components/SectionPanels/FaceBook.vue';
import Twitter from '@/components/SectionPanels/Twitter.vue';

export default {
    name: 'SocialFeed',
    components: { FaceBook, Twitter },
    computed: {
        socialFeed() {
            return this.$store.getters.social.activeSocialFeed;
        },
    },
};
</script>

<style lang="scss" scoped>
.social-feed {
    width: 100%;
    margin-top: 10px;
}
</style>
