/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'text-bubble': {
        width: 16,
        height: 16,
        viewBox: '0 0 17.87 14.77',
        data:
            '<path pid="0" d="M16.28 0H1.59A1.59 1.59 0 0 0 0 1.59v9.12a1.59 1.59 0 0 0 1.59 1.59h10.12L14 14.61a.46.46 0 0 0 .35.16.51.51 0 0 0 .51-.51v-2h1.41a1.59 1.59 0 0 0 1.59-1.59V1.59A1.59 1.59 0 0 0 16.28 0zM3.84 7.85a1.47 1.47 0 1 1 1.47-1.46 1.48 1.48 0 0 1-1.47 1.46zm5.16 0a1.47 1.47 0 1 1 1.47-1.46A1.48 1.48 0 0 1 9 7.85zm5.13 0a1.47 1.47 0 1 1 1.47-1.46 1.48 1.48 0 0 1-1.47 1.46z" _fill="currentColor"/>',
    },
});
